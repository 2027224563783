import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Slide from '@material-ui/core/Slide';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  info: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    maxWidth: 420,
    marginBottom: theme.spacing(2),
  },
}));

function InfoSnackbar({ action, message }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      key={message}
      open={open}
      onClose={handleClose}
      autoHideDuration={20000}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent
        className={classes.info}
        message={<span id="message-id">{message}</span>}
        action={action}
      />
    </Snackbar>
  );
}

InfoSnackbar.propTypes = {
  action: PropTypes.element.isRequired,
  message: PropTypes.string.isRequired,
};

InfoSnackbar.defaultProps = {};

export default React.memo(InfoSnackbar);
