const nj = require('numjs');
const { DAILY, ANNUALIZATION_FACTORS } = require('./periods');

const { up, down, group_by, axis_op } = require('./utils');

function _adjust_returns(returns, adjustement_factor) {
  if (adjustement_factor === 0) {
    return returns;
  }

  return axis_op(returns, (f) => f.sub(adjustement_factor));
}

function _adjust_factor_returns(returns, factor_returns) {
  const delta = factor_returns.length - returns.length;
  if (delta < 0) {
    throw new Error('Factor returns is too short');
  }
  if (delta > 0) {
    factor_returns = factor_returns.iloc([delta, factor_returns.length]);
  }

  return factor_returns;
}

export function aggregate_returns(returns, convert_to) {
  if (!returns) return returns;

  const cumulate_returns = (x) => cum_returns(x).iloc(x.length - 1);
  const grouping = (x) => x.year();

  return group_by(returns, grouping, cumulate_returns);
}

export function alpha(returns, factor_returns, opts = {}) {
  // TODO: Align returns and factor_returns here
  factor_returns = _adjust_factor_returns(returns, factor_returns);

  return alpha_aligned(returns, factor_returns, opts);
}

function alpha_aligned(returns, factor_returns, opts = {}) {
  let { period, annualization, _beta, risk_free } = validate_opts(opts);
  const col = returns.columns.get(0);
  const ann_factor = annualization_factor(period, annualization);

  if (_beta === null) {
    _beta = beta_aligned(returns, factor_returns, opts);
  }

  const adj_returns = _adjust_returns(returns, risk_free);
  const adj_factor_returns = _adjust_returns(factor_returns, risk_free);
  const afrb = adj_factor_returns.get(col).multiply(_beta);
  const alpha_series = axis_op(adj_returns, (f) => f.sub(afrb));

  return Math.pow(alpha_series.get(col).mean() + 1, ann_factor) - 1;
}

export function annual_return(returns, opts = {}) {
  const { period, annualization } = validate_opts(opts);
  const ann_factor = annualization_factor(period, annualization);
  const num_years = returns.length / ann_factor;

  // Pass array to endure index -1 looks up successfully.
  const ending_value = cum_returns_final(returns, 1);

  return Math.pow(ending_value, 1 / num_years) - 1;
}

export function annual_volatility(returns, opts = {}) {
  const { period, annualization, alpha } = validate_opts(opts);
  const ann_factor = annualization_factor(period, annualization);
  const a = nj.power(ann_factor, 1.0 / alpha);
  const b = returns.std().values;

  return a.get(0) * b.get(0);
}

function annualization_factor(period, annualization) {
  let factor;
  if (!annualization) {
    if (period in ANNUALIZATION_FACTORS) {
      factor = ANNUALIZATION_FACTORS[period];
    } else {
      throw new Error(`Period cannot be '${period}'. 
                      Can be '${Object.keys(ANNUALIZATION_FACTORS).join(
                        `', '`,
                      )}'`);
    }
  } else {
    factor = annualization;
  }
  return factor;
}

function beta_aligned(returns, factor_returns, opts = {}) {
  const col = returns.columns.get(0);

  const independent = factor_returns; // FIXME: convert to independent

  const im = independent.mean().values.get(0);
  let ind_residual = axis_op(independent, (f) => f.sub(im));
  const covariances = ind_residual.get(col).multiply(returns.get(col)).mean();
  ind_residual = axis_op(ind_residual, (f) =>
    f.multiply(ind_residual.get(col)),
  );

  let independent_variances = ind_residual.get(col).mean();
  if (independent_variances < 1.0e-30) {
    independent_variances = 0;
  }

  const out = covariances / independent_variances;

  return out;
}

function capture(returns, factor_returns, opts = {}) {
  return annual_return(returns, opts) / annual_return(factor_returns, opts);
}

export function cum_returns(returns, starting_value = 0) {
  if (returns && returns.length < 1) {
    return returns;
  }

  if (starting_value === 0) {
    return axis_op(returns, (f) => f.add(1).cummul(0).sub(1));
  }

  return axis_op(returns, (f) => f.add(1).cummul(0).mul(starting_value));
}

function cum_returns_final(returns, starting_value = 0) {
  if (returns.length === 0) {
    return NaN;
  }

  const col = returns.columns.get(0);
  let result = returns.get(col).add(1).cummul().tail(1).values.get(0);

  if (starting_value == 0) {
    result -= 1;
  } else {
    result *= starting_value;
  }

  return result;
}

export function down_capture(returns, factor_returns, opts = {}) {
  factor_returns = _adjust_factor_returns(returns, factor_returns);
  opts.function = capture;
  return down(returns, factor_returns, opts);
}

function downside_risk(returns, opts = {}) {
  const { period, annualization, risk_free } = validate_opts(opts);
  const required_return = risk_free;
  const asArray = (x) => x.get('price').values.toArray();

  const ann_factor = annualization_factor(period, annualization);
  const adjusted = _adjust_returns(returns, required_return);
  const downside_diff = nj.clip(asArray(adjusted), Number.NEGATIVE_INFINITY, 0);
  downside_diff.pow(2, false);
  const out = nj.sqrt(downside_diff.mean());

  return out.multiply(nj.sqrt(ann_factor)).get(0);
}

export function simple_returns(prices) {
  return prices.pct_change(1).iloc([1, prices.length]);
}

export function sharpe_ratio(returns, opts) {
  const { period, annualization, risk_free } = validate_opts(opts);
  const return_risk_adj = _adjust_returns(returns, risk_free);
  const ann_factor = annualization_factor(period, annualization);

  const a = return_risk_adj.mean().div(return_risk_adj.std());
  const b = nj.sqrt(ann_factor);
  const out = a.multiply(b.tolist());

  return out.values.get(0);
}

export function sortino_ratio(returns, opts = {}) {
  const { period, annualization, risk_free, _downside_risk } = validate_opts(
    opts,
  );
  const required_return = risk_free;
  const adj_returns = _adjust_returns(returns, required_return);
  const ann_factor = annualization_factor(period, annualization);

  const average_annual_return = adj_returns.mean().values.get(0) * ann_factor;

  const annualized_downside_risk =
    _downside_risk || downside_risk(returns, opts);

  const out = average_annual_return / annualized_downside_risk;

  return out;
}

function validate_opts(opts) {
  const o = Object.assign({}, opts);

  if (!('period' in o)) {
    o.period = DAILY;
  }
  if (!('risk_free' in o)) {
    o.risk_free = 0.0;
  }
  if (!('alpha' in o)) {
    o.alpha = 2.0;
  }
  if (!('_beta' in o)) {
    o._beta = null;
  }
  if (!('annualization' in o)) {
    o.annualization = null;
  }
  if (!('_downside_risk' in o)) {
    o._downside_risk = null;
  }

  return o;
}

export function up_capture(returns, factor_returns, opts = {}) {
  factor_returns = _adjust_factor_returns(returns, factor_returns);
  opts.function = capture;
  return up(returns, factor_returns, opts);
}
