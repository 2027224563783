export const BENCH_SYM = 'SPY';
export const CASH_SYM = 'BIL';
export const RISK_FREE = 0.00085;
export const SEC_LOOKUP_MAX_AGE = 3600; // 1 hour
export const INITIAL_AMOUNT = 10000;
export const FB_CREDS = {
  apiKey: 'AIzaSyB1IDJIxXvsC79kEKqQv5OA7BnOV5wN2kc',
  authDomain: 'advisorbot-96e74.firebaseapp.com',
  databaseURL: 'https://advisorbot-96e74.firebaseio.com',
  projectId: 'advisorbot-96e74',
  storageBucket: 'advisorbot-96e74.appspot.com',
  messagingSenderId: '72268293P7970',
  appId: '1:722682937970:web:8f0c3af39344f164b778eb',
  measurementId: 'G-568ZM270P4',
};
export const ALGOLIA_APP = '5GB5HHL3VF';
export const ALGOLIA_KEY = '10d1fe7e80aa2a5744df353f8610f7fa';
export const ALGOLIA_IDX = 'symbols';

export const PORTFOLIO_TYPES = {
  portfolio: 'portfolio',
  compared: 'compared',
  optimized: 'optimized',
  benchmark: 'benchmark',
};

export const PROFILE_TYPES = {
  c: 'Conservative',
  cm: 'Conservative-Moderate',
  m: 'Moderate',
  ma: 'Moderate-Aggressive',
  a: 'Aggressive',
};
