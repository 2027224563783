import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Glyph as CustomGlyph, GlyphTriangle } from '@visx/glyph';
import { useTheme } from '@material-ui/core/styles';
import { config } from 'react-spring/renderprops.cjs';
import { fade } from '@material-ui/core/styles/colorManipulator';
import EnhancedSpring from '../EnhancedSpring';

function AssetTickerWithTrail({
  animate,
  symbol,
  top,
  left,
  onMouseOver,
  onMouseOut,
  trail,
  debugPoint,
}) {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const positiveTrend = trail.top > 0 || trail.left < 0;
  // const size = hover ? 300 : 150;

  const handleMouseOver = (e) => {
    setHover(true);
    onMouseOver(e);
  };

  const handleMouseOut = (e) => {
    setHover(false);
    onMouseOut(e);
  };

  return (
    <svg style={{ pointerEvents: 'all' }}>
      <EnhancedSpring animate={animate} to={{ top: top, left: left }}>
        {(props) => (
          <CustomGlyph top={props.top} left={props.left}>
            <EnhancedSpring
              animate={animate}
              from={{
                opacity: 0,
                transform: 'translate3d(0,50px,0)',
              }}
              to={{
                opacity: 1,
                transform: 'translate3d(0,0,0)',
                size: hover ? 400 : 150,
              }}
              config={{ ...config.gentle }}
            >
              {(sp) => (
                <g style={sp}>
                  {(trail.top !== 0 || trail.left !== 0) && (
                    <g>
                      <GlyphTriangle
                        style={sp}
                        top={trail.top * 2}
                        left={trail.left * 2}
                        size={sp.size}
                        fill="none"
                        stroke={
                          positiveTrend
                            ? theme.palette.scope.trend.negative
                            : theme.palette.scope.trend.positive
                        }
                        strokeWidth={0.2}
                      />
                      <GlyphTriangle
                        strokeWidth={0.4}
                        style={sp}
                        top={trail.top * 1}
                        left={trail.left * 1}
                        size={sp.size}
                        fill="none"
                        stroke={
                          positiveTrend
                            ? theme.palette.scope.trend.positive
                            : theme.palette.scope.trend.negative
                        }
                      />
                    </g>
                  )}
                  <GlyphTriangle
                    size={sp.size}
                    fill="none"
                    stroke={theme.palette.text.primary}
                    strokeWidth={1}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  />
                </g>
              )}
            </EnhancedSpring>

            <EnhancedSpring
              animate={animate}
              from={{ opacity: 0, transform: 'translate3d(0,1rem,0)' }}
              to={{ opacity: hover ? 0 : 1, transform: 'translate3d(0,0,0)' }}
              config={{ ...config.gentle, delay: hover ? 0 : 100 }}
            >
              {(sp) => (
                <text
                  fontSize={12}
                  fontWeight={500}
                  textAnchor="middle"
                  dy="1.4rem"
                  style={sp}
                  cursor="default"
                  fill={theme.palette.text.primary}
                >
                  {symbol}
                </text>
              )}
            </EnhancedSpring>
          </CustomGlyph>
        )}
      </EnhancedSpring>
      )
      {debugPoint && (
        <GlyphTriangle
          top={debugPoint.top}
          left={debugPoint.left}
          size={150}
          fill="none"
          stroke={theme.palette.error.main}
          strokeDasharray={8}
          strokeWidth={0.5}
        />
      )}
    </svg>
  );
}

AssetTickerWithTrail.propTypes = {
  symbol: PropTypes.string,
  animate: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  trail: PropTypes.object,
  debugPoint: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

AssetTickerWithTrail.defaultProps = {
  animate: false,
  trail: { top: 0, left: 0 },
  debugPoint: false,
};

export default React.memo(AssetTickerWithTrail, (prevProps, nextProps) => {
  return (
    prevProps.symbol === nextProps.symbol &&
    prevProps.top === nextProps.top &&
    prevProps.left === nextProps.left &&
    prevProps.trail.top === nextProps.trail.top &&
    prevProps.trail.left === nextProps.trail.left
  );
});
