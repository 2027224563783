import { observable, computed, reaction } from 'mobx';
import moment from 'moment';
import Portfolio from './Portfolio';
import Security from './Security';
import { BENCH_SYM } from './constants';
import { today } from './helpers';

export default class SharedPortfolio extends Portfolio {
  @observable _benchmark = null;
  isSharedCopy = true;

  constructor(source, options) {
    super(source, options);

    const benchSym = String(BENCH_SYM).toLowerCase();
    this._benchmark = new Security(`securities/${benchSym}`, {
      cache: options.cache,
      constrainer: this,
    });

    this._options = {
      ...options,
      benchmark: this._benchmark,
      constrainer: this,
    };
  }

  ready() {
    return Promise.all([super.ready(), this.benchmark.ready()]);
  }

  previewReady() {
    return new Promise((resolve) => {
      const disposeReaction = reaction(
        () => this.previewUrl,
        (url) => {
          if (url !== 'request') {
            disposeReaction();
            resolve();
          }
        },
      );
    });
  }

  update(data) {
    // prevent any updates othen than preview url
    if (Object.keys(data).length > 1 || !data.previewUrl) return;
    super.update(data);
  }

  @computed get benchmark() {
    return this._benchmark;
  }

  @computed get since() {
    const { assets } = this;
    if (assets.length === 0) {
      return moment.unix(0);
    }

    return [...assets, this.benchmark].reduce(
      (acc, a) => (a.since.isAfter(acc) ? a.since : acc),
      moment.unix(0),
    );
  }

  @computed get till() {
    const { assets } = this;
    if (assets.length === 0) {
      return today();
    }

    return [...assets, this.benchmark].reduce(
      (acc, a) => (a.till.isBefore(acc) ? a.till : acc),
      moment(),
    );
  }

  @computed get previewUrl() {
    return this.hasData && this.data.previewUrl;
  }
}
