import React, { useState, useMemo, useRef, useEffect } from 'react';
import t from 'prop-types';
import { useSprings, animated, interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { isServer } from '../../utils/env';

/**
 * utils
 */
const clamp = (pos, low, high) => {
  const mid = pos <= high ? pos : high;
  return mid >= low ? mid : low;
};

const move = (arr, from, to) => {
  const copy = [...arr];
  const out = copy.splice(from, 1)[0];
  copy.splice(to, 0, out);
  return copy;
};

/**
 * SpringList
 */
const SpringList = ({
  className,
  row,
  onMove,
  onDragEnd,
  children,
  handleClass,
  modifyCursor,
}) => {
  const axisKey = useRef(row ? 0 : 1);

  const xy = useRef(row ? 'width' : 'height');
  const yx = useRef(row ? 'height' : 'width'); // another attr
  const [xySize, setXySize] = useState(0);
  const yxSize = useRef(0); // another val

  const animatedRef = useRef(null);
  const hasKey = useRef(children[0]?.key !== undefined);
  const getKey = useMemo(
    () => (item) => (hasKey.current ? item.key : item),
    [],
  );
  const keyOrder = useRef(children.map((item) => getKey(item)));

  /**
   * useSpring
   */
  const mapSpring = useMemo(() => {
    keyOrder.current = children.map((item) => getKey(item));

    return (keyList = keyOrder.current, down, activeKey, activePos) => (
      index,
    ) => {
      const key = getKey(children[index]);
      if (down && key === activeKey) {
        return {
          active: 'true',
          [xy.current]: activePos,
          [yx.current]: 0,
          zIndex: 1,
          scale: 1.05,
          immediate: (n) =>
            n === 'active' || n === xy.current || n === 'zIndex',
        };
      }

      return {
        active: '',
        [xy.current]: xySize * keyList.indexOf(key),
        [yx.current]: 0,
        scale: 1,
        zIndex: 0,
        immediate: !activeKey,
      };
    };
  }, [children, getKey, xySize]);

  const [springs, setSprings] = useSprings(children.length, mapSpring());

  /**
   * useGesture
   */
  const bind = useDrag((e) => {
    const {
      args: [activeKey],
      down,
      movement,
      event,
    } = e;

    if (!(event instanceof PointerEvent)) {
      return;
    }

    if (handleClass !== '') {
      // console.log('event', e.event);
      const handle =
        event.path &&
        event.path.find(
          (x) => x.classList && x.classList.contains(handleClass),
        );

      if (modifyCursor) {
        if (handle && !down) {
          handle.style.cursor = 'grab';
        } else if (handle && down) {
          handle.style.cursor = 'grabbing';
        }
      }

      if (!handle && event.path) {
        return;
      }
    }

    const offset = movement[axisKey.current];
    // if (offset === 0) return;

    const prev = keyOrder.current.indexOf(activeKey);
    const activePos = xySize * prev + offset;
    const next = clamp(Math.round(activePos / xySize), 0, children.length - 1);
    const newOrder = move(keyOrder.current, prev, next);

    setSprings(mapSpring(newOrder, down, activeKey, activePos));
    if (!down) {
      keyOrder.current = newOrder;
      onDragEnd(keyOrder.current);
    } else {
      if (typeof onMove === 'function') {
        onMove();
      }
    }
  });

  /**
   * useLayoutEffect
   */

  useEffect(() => {
    if (!animatedRef.current) return;

    if (row) {
      const { scrollWidth, scrollHeight } = animatedRef.current;

      if (xySize !== scrollWidth || yxSize.current !== scrollHeight) {
        setXySize(scrollWidth);
        yxSize.current = scrollHeight;
      } else {
        setSprings(mapSpring());
      }
    } else {
      const { scrollHeight } = animatedRef.current;
      const { scrollWidth } = animatedRef.current.parentNode.parentNode;

      if (xySize !== scrollHeight || yxSize.current !== scrollWidth) {
        setXySize(scrollHeight);
        yxSize.current = scrollWidth;
      } else {
        setSprings(mapSpring());
      }
    }
  }, [mapSpring, row, setSprings, xySize, children]);

  // On server, render simple, non-animated list to avoid sizing issues
  if (isServer()) {
    return <div className={className}>{children.map((c) => c)}</div>;
  }

  return (
    <div
      suppressHydrationWarning={true} // it's expected to have server-client markup mismatch
      className={className}
      style={{
        position: 'relative',
        [xy.current]: xySize * children.length,
        [yx.current]: yxSize.current,
      }}
    >
      {springs.map(({ active, width, height, zIndex, scale }, index) => {
        const item = children[index];
        const key = getKey(item);

        const keyProp = typeof key === 'string' ? key : key.key;

        const transform = interpolate(
          [width, height, scale],
          (x, y, s) =>
            `translate3d(${x}px, ${y}px, 0) scale3d(${s},${s},${s}) `,
        );

        return (
          <animated.div
            {...bind(key)}
            ref={animatedRef}
            key={keyProp}
            className="animated-item"
            data-active={active.interpolate((s) => s)}
            style={{
              [yx.current]: yxSize.current,
              position: 'absolute',
              zIndex,
              transform,
            }}
          >
            {item}
          </animated.div>
        );
      })}
    </div>
  );
};

SpringList.defaultProps = {
  className: '',
  row: false,
  onMove: undefined,
  onDragEnd: undefined,
  children: [],
  handleClass: '',
  modifyCursor: false,
};

SpringList.propTypes = {
  className: t.string,
  row: t.bool,
  onMove: t.func,
  onDragEnd: t.func,
  children: t.array,
  handleClass: t.string,
  modifyCursor: t.bool,
};

export default SpringList;
