import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  div: {
    width: '100%',
    height: '100%',
    background: 'conic-gradient(from 180deg, #0D285E, #4381FF)',
    backgroundPosition: '0px -4px',
  },
}));

function Logo({ width, height }) {
  const classes = useStyles();
  return (
    <svg width={width} height={height}>
      <clipPath id="clip">
        <path d="M28.6896382,6.66784971 L44.0556007,30.9298958 C44.6466029,31.8630572 44.3692281,33.0986359 43.4360667,33.6896382 C43.1159656,33.8923688 42.7448615,34 42.3659625,34 L11.6340375,34 C10.529468,34 9.63403745,33.1045695 9.63403745,32 C9.63403745,31.621101 9.74166862,31.2499969 9.9443993,30.9298958 L25.3103618,6.66784971 C25.9013641,5.73468833 27.1369428,5.45731351 28.0701042,6.04831572 C28.3198671,6.20649893 28.5314549,6.41808676 28.6896382,6.66784971 Z" />
      </clipPath>
      <foreignObject width={width} height={height} clipPath="url(#clip)">
        <div className={classes.div} />
      </foreignObject>
    </svg>
  );
}

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Logo.defaultProps = {
  width: 54,
  height: 48,
};

export default React.memo(Logo);
