/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { isEqual, debounce, throttle } from 'lodash';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';

import { Portfolio } from '../../store';
import { numRound } from '../../../common/utils/helpers';
import { SettingsIcon } from '../../components/AppIcons';
import PortfolioAssetRow from '../PortfolioAssetRow';
import PortfolioListCaptions from '../PortfolioListCaptions';
import PortfolioTotalRow from '../PortfolioTotalRow';
import SpringList from '../SpringList';
import SmallIconButton from '../SmallIconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
}));

function PortfolioMetricsTable({
  onChange,
  onRemove,
  onReset,
  onEqualize,
  onFix,
  onAssetDetailOpen,
  portfolio,
  expertMode,
  highlightAssets,
}) {
  const classes = useStyles();
  const [warnDialog, setWarnDialog] = useState(false);
  const [warnAsset, setWarnAsset] = useState(null);
  const cash = portfolio && portfolio.sortedAssetsDivided.cash;
  const assets = portfolio && portfolio.sortedAssetsDivided.list;

  const handleWarnDialogClose = () => {
    setWarnDialog(false);
  };

  const handleWarnDialogOpen = (asset) => {
    setWarnAsset(asset);
    setWarnDialog(true);
  };

  const handleAssetReorder = debounce((order) => {
    const oldOrder = assets.map((x) => x.symbol);

    if (isEqual(oldOrder, order)) {
      return;
    }

    const toTs = (d) => d.getTime() / 1000;
    const toDate = (t) => new Date(t * 1000);

    const oldest = assets[0];
    const latestTs = toTs(oldest.createdAt);

    for (let i = 0; i < order.length; i++) {
      const id = typeof order[i] === 'object' ? order[i].key : order[i];
      const a = assets.find((x) => x.id === id);
      if (!a) {
        continue;
      }
      a.setCreatedAt(toDate(latestTs - i));
    }
  }, 500);

  const handleAssetMove = throttle(handleAssetReorder.cancel, 200, {
    trailing: false,
  });

  if (!portfolio) {
    return <div></div>;
  }
  const { totalAllocation, allocationDelta } = portfolio;

  const renderDialog = () => {
    if (!warnAsset) return false;

    const currentDateRange = `${warnAsset.since.format(
      'MMMM YYYY',
    )} - ${warnAsset.till.format('MMMM YYYY')}`;
    return (
      <Dialog
        open={warnDialog}
        onClose={handleWarnDialogClose}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle>Historical Data for {warnAsset.symbol}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Alert severity="warning">{currentDateRange}</Alert>
          </Box>
          <DialogContentText>
            ALERT! This stock/fund does NOT have 5 years worth of data. As such,
            you can look at the available metrics but be warned that it would be
            dangerous to make decisions based on such limited data.
          </DialogContentText>

          <DialogContentText>
            <Typography variant="caption" color={'textSecondary'}>
              If, however, you are aware of the risk in doing so and still want
              to use this data, you can do so by going to Settings and turning
              on the "expert" mode. <br />
              WARNING: by doing this, all stocks/funds in all portfolios will be
              changed to this limited date range.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarnDialogClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderRow = (a) => {
    if (!a) return;
    const m = a.metrics;
    const constraining =
      !expertMode && portfolio.constrainingAssets.includes(a);

    if (!a.hasData || a.isLoading || a.hasError) {
      return (
        <PortfolioAssetRow
          asset={a}
          key={a.id}
          loading={!a.hasData || a.isLoading}
          error={a.hasError}
          onRemove={() => onRemove(a)}
        />
      );
    }
    if (!m) return;

    const assetDelta =
      allocationDelta + a.allocation < 0
        ? a.allocation
        : Math.abs(allocationDelta);
    return (
      <PortfolioAssetRow
        key={a.id}
        nomove={portfolio.isSharedCopy}
        asset={a}
        units={portfolio.existing ? 'amount' : 'percentage'}
        highlight={highlightAssets && highlightAssets.includes(a)}
        action={
          numRound(totalAllocation) !== numRound(100) &&
          !portfolio.existing &&
          !constraining &&
          onFix ? (
            totalAllocation > 100 ? (
              <Button
                color="secondary"
                disabled={assetDelta === 0}
                onClick={() => onFix(a)}
                tabIndex="-1"
              >
                -{Number(assetDelta).toFixed(0)}
              </Button>
            ) : (
              <Button color="secondary" onClick={() => onFix(a)} tabIndex="-1">
                +{Number(assetDelta).toFixed(0)}
              </Button>
            )
          ) : constraining ? (
            <Tooltip
              arrow
              placement="top"
              title="This asset has limited data, click to learn more."
            >
              <SmallIconButton
                onClick={() => handleWarnDialogOpen(a)}
                aria-label="view warning"
                color="inherit"
              >
                <WarningIcon color="secondary" fontSize="small" />
              </SmallIconButton>
            </Tooltip>
          ) : (
            !portfolio.existing &&
            !portfolio.isSharedCopy && (
              <Checkbox
                icon={<LockOpenIcon fontSize="small" />}
                checkedIcon={<LockIcon fontSize="small" />}
                checked={a.isLocked}
                onChange={(event) => a.setLock(event.target.checked)}
                tabIndex="-1"
              />
            )
          )
        }
        onChange={!constraining && onChange ? (val) => onChange(a, val) : null}
        onRemove={onRemove && (() => onRemove(a))}
        onAssetDetailOpen={onAssetDetailOpen}
      />
    );
  };

  return (
    <div className={classes.root}>
      <PortfolioListCaptions allocation />

      {cash && renderRow(cash)}
      {portfolio.isSharedCopy ? (
        assets.map((a) => renderRow(a))
      ) : (
        <SpringList
          onDragEnd={handleAssetReorder}
          onMove={handleAssetMove}
          handleClass="dnd-handle"
        >
          {assets.map((a) => renderRow(a))}
        </SpringList>
      )}

      <PortfolioTotalRow
        onReset={onReset}
        onEqualize={onEqualize}
        portfolio={portfolio}
      />
      {renderDialog()}
    </div>
  );
}

PortfolioMetricsTable.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onReset: PropTypes.func,
  onEqualize: PropTypes.func,
  onFix: PropTypes.func,
  onAssetDetailOpen: PropTypes.func,
  portfolio: PropTypes.instanceOf(Portfolio),
  expertMode: PropTypes.bool,
  highlightAssets: PropTypes.array,
};

PortfolioMetricsTable.defaultProps = {};

export default observer(PortfolioMetricsTable);
