import React from 'react';
import PropTypes from 'prop-types';

import { UpsideDownside } from '../Indicator';

import { PROFILE_TYPES } from '../../store/constants';

function TargetUD({ resultLabel }) {
  if (resultLabel === PROFILE_TYPES.c) {
    return <UpsideDownside upsideCapture={0.15} downsideCapture={0.1} ready />;
  }

  if (resultLabel === PROFILE_TYPES.cm) {
    return <UpsideDownside upsideCapture={0.6} downsideCapture={0.4} ready />;
  }

  if (resultLabel === PROFILE_TYPES.m) {
    return <UpsideDownside upsideCapture={0.8} downsideCapture={0.6} ready />;
  }

  if (resultLabel === PROFILE_TYPES.ma) {
    return <UpsideDownside upsideCapture={1} downsideCapture={0.8} ready />;
  }

  if (resultLabel === PROFILE_TYPES.a) {
    return <UpsideDownside upsideCapture={1.25} downsideCapture={1} ready />;
  }

  //TODO: Get rid of this
  return <UpsideDownside upsideCapture={1.25} downsideCapture={1} ready />;
}

TargetUD.propTypes = {
  resultLabel: PropTypes.string,
};

TargetUD.defaultProps = {};

export default React.memo(TargetUD);
