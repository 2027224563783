import React, { useState } from 'react';
import { observer } from 'mobx-react';

import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { useStore } from '../../store';

import AssetDetailsDialog from '../../components/AssetDetailsDialog';
import AssetCompareDialog from '../../components/AssetCompareDialog';
import GuidesDialog from '../../components/GuidesDialog';
import RiskProfileDialog from '../../components/RiskProfileDialog';
import SettingsDialog from '../../components/SettingsDialog';

import ChartAnnualReturns from '../../components/ChartAnnualReturns';
import ChartPortfolioGrowth from '../../components/ChartPortfolioGrowth';
import ChartScope from '../../components/ChartScope';
import DateRangeWarningDialog from '../../components/DateRangeWarningDialog';
import MergeDialog from '../../components/MergeDialog';
import RenamePortfolioDialog from '../../components/RenamePortfolioDialog';

import LayoutPortfolioConstruction from '../../components/LayoutPortfolioConstruction';
import OptimizationControls from '../../components/OptimizationControls';
import Placeholder from '../../components/Placeholder';
import PortfolioDateRange from '../../components/PortfolioDateRange';
import PortfolioStartScreen from '../../components/PortfolioStartScreen';
import RiskAssesment from '../../components/RiskAssesment';

import ShareDialog from '../../components/ShareDialog';
import Drawer from '../../components/Drawer';

import PortfolioCompare from '../../components/PortfolioCompare';
import PortfolioCard from '../../components/PortfolioCard';
import GoogleSignInButton from '../../components/GoogleSignInButton';
import InfoSnackbar from '../../components/InfoSnackbar';

const RENDER_CHARTS = true;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    background: theme.palette.background.default,
    minHeight: '100%',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
}));

function Portfolio() {
  const classes = useStyles();
  const store = useStore();
  const theme = useTheme();

  const { portfolio, profile, riskProfile, user } = store;

  const existing = portfolio && portfolio.existing;
  const currentDateRange = profile ? profile.dateRange : ' Not defined';
  const comparedPortfolio = profile && profile.comparedPortfolio;

  const [shareDialog, setShareDialog] = useState(false);
  const [guidesDialog, setGuidesDialog] = useState(false);

  const [drWarningDialog, setDrWarningDialog] = useState(false);
  const [viewAsset, setViewAsset] = useState(null);
  const [assetDialog, setAssetDialog] = useState(false);
  const [assetCompareDialog, setAssetCompareDialog] = useState(false);
  const [mergeDialog, setMergeDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [riskProfileDialog, setRiskProfileDialog] = useState(false);

  const [settingsDialog, setSettingsDialog] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (isMobile) {
    return (
      <Alert severity="info">
        <AlertTitle>The mobile version is coming soon</AlertTitle>
        Investblaze is available for desktop and tablet platforms at the moment.
        We are still working on mobile experience and plan to deliver it in the
        nearest future. For now, try it on the one of supported platforms.
      </Alert>
    );
  }

  const handleLogin = async () => {
    store.setBackdrop(true);
    await user.migrate();
    store.setBackdrop(false);
  };

  const handleSettingsDialogOpen = () => {
    setSettingsDialog(true);
  };
  const handleSettingsDialogClose = () => {
    setSettingsDialog(false);
  };

  const handleRenameDialogOpen = () => {
    setRenameDialog(true);
  };
  const handleRenameDialogClose = () => {
    setRenameDialog(false);
  };

  const handleMergeDialogOpen = () => {
    setMergeDialog(true);
  };
  const handleMergeDialogClose = () => {
    setMergeDialog(false);
  };

  const handleRiskProfileDialogOpen = () => {
    setRiskProfileDialog(true);
  };

  const handleRiskProfileDialogClose = () => {
    setRiskProfileDialog(false);
  };

  const handleCompareChange = (event) => {
    profile.comparePortfolio(event.target.value);
  };

  const handleAssetAdd = (hit) => {
    try {
      portfolio.addAsset(hit.symbol);
    } catch (err) {
      alert(err);
    }
  };

  const handleAssetRemove = (asset) => {
    portfolio.removeAsset(asset);
  };

  const handleAssetChange = (asset, val) => {
    // Amount
    if (portfolio.existing) {
      asset.setAmount(val);
      return;
    }

    // Percentage
    if (val === asset.allocation || val > 100) return;

    asset.setAllocation(val);
    profile.setEqualize(false);

    const sum = assets.reduce((acc, a) => {
      if (!a.allocation) return acc;
      return acc + Number(a.allocation);
    }, 0);
    let delta = 100 - sum;

    if (profile.dynamicCash) {
      if (asset.isCash || sum <= 100) {
        return;
      }

      const cash = assets.find((a) => a.isCash);
      if (!cash) return;

      if (cash.allocation < Math.abs(delta)) {
        cash.setAllocation(0);
      } else {
        cash.setAllocation(Number(cash.allocation) + delta);
      }
      return;
    }
  };

  const handleFix = (a) => {
    const sum = assets.reduce((acc, a) => {
      if (!a.allocation) return acc;
      return acc + Number(a.allocation);
    }, 0);
    let delta = 100 - sum;
    if (delta + a.allocation < 0) {
      a.setAllocation(0);
      return;
    }

    a.setAllocation(Number(a.allocation) + delta);
  };

  const handleReset = () => {
    for (const a of assets) {
      a.isCash ? a.setAllocation(100) : a.setAllocation(0);
    }

    profile.setEqualize(false);
    profile.setDynamicCash(true);
  };

  const handleEqalize = () => {
    portfolio.equalize();
  };

  const handlePortfolioCreate = () => {
    profile.addPortfolio();
  };

  const handlePortfolioRename = (portfolio, name) => {
    portfolio.rename(name);
  };

  const handlePortfolioCreateExisting = () => {
    profile.addPortfolio({ existing: true, name: 'EXISTING' });
  };

  const handlePortfolioRemove = (portfolio) => {
    profile.removePortfolio(portfolio);
  };

  const handlePortfolioCopy = (portfolio) => {
    profile.addPortfolio({ source: portfolio });
  };

  const handleShare = (shareAmount = null) => {
    return profile.sharePortfolio(portfolio, shareAmount);
    // handleShareDialogClose();
  };

  const handleShareDialogOpen = () => {
    setShareDialog(true);
  };

  const handleShareDialogClose = () => {
    setShareDialog(false);
  };

  const handleDrWarningDialogOpen = () => {
    setDrWarningDialog(true);
  };

  const handleDrWarningDialogClose = () => {
    setDrWarningDialog(false);
  };

  const handleGuidesDialogOpen = () => {
    setGuidesDialog(true);
  };

  const handleGuidesDialogClose = () => {
    setGuidesDialog(false);
  };

  const handleViewAssetDetails = (e, asset) => {
    if (e.shiftKey) {
      if (asset.isEtf && !selectedAssets.includes(asset)) {
        setSelectedAssets((sa) => {
          const newSelection = [...sa, asset];
          if (newSelection.length > 1) {
            setAssetCompareDialog(true);
          }
          return newSelection;
        });
      }
    } else {
      setSelectedAssets([]);
      setViewAsset(asset);
      setAssetDialog(true);
    }
  };

  const handleImportTopHolings = (_, asset = null) => {
    setAssetDialog(false);
    setTimeout(async () => {
      store.setBackdrop(true);
      await profile.importPotfolioFromFund(asset || viewAsset);
      store.setBackdrop(false);
    }, 200);
  };
  const handleCloseAssetDialog = () => setAssetDialog(false);

  const handleCloseAssetCompareDialog = () => {
    setAssetCompareDialog(false);
    setTimeout(() => setSelectedAssets([]), 500);
  };

  const handleSelectPortfolio = (p) => profile.selectPortfolio(p);

  const renderHelpFab = (
    <Fab
      color="primary"
      variant="extended"
      className={classes.fab}
      style={{ marginRight: 24 }}
      onClick={handleGuidesDialogOpen}
    >
      <HelpOutlineIcon className={classes.extendedIcon} />
      User Guides
    </Fab>
  );
  if (!profile || !profile.hasPortfolios || !portfolio) {
    return (
      <>
        <PortfolioStartScreen
          onPortfolioCreateExisting={handlePortfolioCreateExisting}
          onPortfolioCreateNew={handlePortfolioCreate}
        />
        <GuidesDialog open={guidesDialog} onClose={handleGuidesDialogClose} />
      </>
    );
  }

  const renderDateRange = (
    <PortfolioDateRange
      dateRange={currentDateRange}
      constrained={profile.isConstrained}
      onDialogOpen={handleDrWarningDialogOpen}
    />
  );

  const renderHeader = (
    <Grid container spacing={3} style={{ marginTop: -4 }}>
      <Grid item xs>
        <Button
          variant="outlined"
          fullWidth
          // color={'primary'}
          startIcon={<HelpOutlineIcon />}
          onClick={handleGuidesDialogOpen}
        >
          Step-by-step Tutorial
        </Button>
      </Grid>
      {user.isAnon && (
        <Grid item xs>
          <GoogleSignInButton fullWidth onClick={handleLogin} />
        </Grid>
      )}
    </Grid>
  );

  const renderSnackbar = user.isAnon && (
    <InfoSnackbar
      key={'info-snackbar'}
      message={
        'You are currently operating in Guest Mode. Sign in to save your progress, or to enter the existing account.'
      }
      action={
        <Button color="inherit" size="small" onClick={handleLogin}>
          Sign In
        </Button>
      }
    />
  );

  const renderDialogs = (
    <>
      <DateRangeWarningDialog
        open={drWarningDialog}
        onClose={handleDrWarningDialogClose}
        currentDateRange={currentDateRange}
        constrained={profile.isConstrained}
        expertMode={profile.expertMode}
        constrainingPortfolios={profile.constrainingPortfolios}
        onSettingsOpen={handleSettingsDialogOpen}
      />

      <ShareDialog
        open={shareDialog}
        onClose={handleShareDialogClose}
        portfolio={portfolio}
        share={handleShare}
      />
      <AssetDetailsDialog
        open={assetDialog}
        asset={viewAsset}
        onImportHoldings={handleImportTopHolings}
        onClose={handleCloseAssetDialog}
      />
      <AssetCompareDialog
        open={assetCompareDialog}
        assets={selectedAssets}
        onClose={handleCloseAssetCompareDialog}
        onImportHoldings={handleImportTopHolings}
      />
      <GuidesDialog open={guidesDialog} onClose={handleGuidesDialogClose} />
      <RiskProfileDialog
        open={riskProfileDialog}
        onClose={handleRiskProfileDialogClose}
      />
      <MergeDialog open={mergeDialog} onClose={handleMergeDialogClose} />
      <RenamePortfolioDialog
        name={portfolio.name}
        onRename={handlePortfolioRename}
        open={renameDialog}
        onClose={handleRenameDialogClose}
      />
      <SettingsDialog
        open={settingsDialog}
        onClose={handleSettingsDialogClose}
      />
    </>
  );

  const renderNoData = <Placeholder text={'Complete Portfolio to see Chart'} />;

  const {
    assets,
    annualReturnsChartData,
    portfolioGrowthChartData,
    chartsMetaData,
  } = portfolio;

  const handleCreate = (existing) => {
    if (existing) {
      profile.addPortfolio({ existing: true, name: 'EXISTING' });
    } else {
      profile.addPortfolio();
    }

    // if (e.shiftKey) {
    //   profile.addPortfolio({ existing: true, name: 'EXISTING' });
    // } else {
    //   profile.addPortfolio();
    // }
  };
  const handleSelect = (portfolio) => {
    profile.selectPortfolio(portfolio);
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <Drawer
        selected={profile.selectedPortfolio}
        benchmark={store.benchmark}
        portfolios={profile.sortedPortfolios}
        onPortfolioCreate={handleCreate}
        onPortfolioSelect={handleSelect}
        onPortfolioRemove={handlePortfolioRemove}
        onSettingsOpen={handleSettingsDialogOpen}
        onGuidesOpen={handleGuidesDialogOpen}
      />
      <LayoutPortfolioConstruction
        headerBlock={renderHeader}
        dateRange={renderDateRange}
        riskAssesment={
          <RiskAssesment
            riskProfile={riskProfile}
            onStart={handleRiskProfileDialogOpen}
          />
        }
        chartScope={
          RENDER_CHARTS && store.benchmark.metricsReady ? (
            <ChartScope
              animate={true}
              benchmark={store.benchmark}
              compare={comparedPortfolio}
              hasPortfolios={profile && profile.hasPortfolios}
              portfoliosList={profile.visibleSortedPortfolios}
              selectedPortfolio={portfolio}
              onSelect={handleSelectPortfolio}
              assetTrend={profile.assetTrend}
            />
          ) : (
            renderNoData
          )
        }
        chartPortfolioGrowth={
          portfolioGrowthChartData && RENDER_CHARTS ? (
            <ChartPortfolioGrowth
              data={portfolioGrowthChartData}
              meta={chartsMetaData}
            />
          ) : (
            renderNoData
          )
        }
        chartPortfolioReturns={
          annualReturnsChartData && RENDER_CHARTS ? (
            <ChartAnnualReturns
              data={annualReturnsChartData}
              meta={chartsMetaData}
            />
          ) : (
            renderNoData
          )
        }
        portfolioCard={
          <PortfolioCard
            portfolio={portfolio}
            expertMode={profile.expertMode}
            selectedAssets={selectedAssets}
            onAssetChange={(a, val) => handleAssetChange(a, val)}
            onAssetRemove={(a) => handleAssetRemove(a)}
            onAssetAdd={handleAssetAdd}
            onAssetDetailOpen={handleViewAssetDetails}
            onAllocationReset={handleReset}
            onAllocationEqualize={handleEqalize}
            onAllocationFix={handleFix}
            onPortfolioRename={handlePortfolioRename}
            onPortfolioShare={handleShareDialogOpen}
            onPortfolioRemove={() => handlePortfolioRemove(portfolio)}
            onPortfolioMerge={handleMergeDialogOpen}
            onPortfolioCopy={() => handlePortfolioCopy(portfolio)}
          />
        }
        portfolioOptimization={
          !existing && (
            <OptimizationControls portfolio={profile.selectedPortfolio} />
          )
        }
        portfolioCompare={
          !existing &&
          profile.sortedPortfoliosWithoutSelected && (
            <PortfolioCompare
              compare={comparedPortfolio}
              onChange={handleCompareChange}
              portfolio={profile.selectedPortfolio}
              comparePortfolios={profile.sortedPortfoliosWithoutSelected}
            />
          )
        }
      >
        {renderDialogs}
      </LayoutPortfolioConstruction>
      {renderSnackbar}
    </Container>
  );
}

Portfolio.propTypes = {};

export default observer(Portfolio);
