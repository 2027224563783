import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(() => ({
  root: {
    width: 90,
  },
}));

function InputPercent({ value, name, onChange, onBlur, disabled }) {
  const classes = useStyles();

  return (
    <Input
      className={classes.root}
      autoComplete={'off'}
      value={value}
      margin="dense"
      onChange={onChange}
      onBlur={onBlur}
      endAdornment={<InputAdornment position="start">%</InputAdornment>}
      name={name}
      inputProps={{
        step: 10,
        min: 0,
        max: 100,
        type: 'number',
        'data-hj-allow': '',
      }}
      disabled={disabled}
    />
  );
}

InputPercent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

InputPercent.defaultProps = {};

export default React.memo(InputPercent);
