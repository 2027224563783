import { computed, observable, action } from 'mobx';
import { matchPath } from 'react-router';
import moment from 'moment';
import { createMuiTheme } from '@material-ui/core/styles';
import { dark, light } from '../../common/theme';
import { isServer } from '../utils/env';
import Profile from './Profile';
import SymbolsSearch from './SymbolsSearch';
import User from './User';
import SharedPortfolio from './SharedPortfolio';
import RiskProfile from './RiskProfile';
import { SEC_LOOKUP_MAX_AGE } from './constants';

function getPreferredColorScheme() {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }
  return 'dark';
}

class Store {
  _timeout = null;
  _interval = null;
  @observable _options = {};
  @observable _profile = null;
  @observable _sharedPortfolio = null;
  @observable _riskProfile = null;
  @observable _themeType = null;
  @observable backdrop = false;
  @observable symbolsSearch = null;
  @observable user = null;
  @observable router = null;

  constructor(cache, router) {
    this._options = { cache, store: this };
    this.router = router;
    this.user = new User(this._options);
    this.symbolsSearch = new SymbolsSearch();

    if (!isServer() && window.matchMedia) {
      const colorSchemeQuery = window.matchMedia(
        '(prefers-color-scheme: dark)',
      );
      colorSchemeQuery.addEventListener('change', () => {
        if (this.isAutoTheme) {
          this.syncThemeWithPreferred();
        }
      });

      if (this.isAutoTheme) {
        this.syncThemeWithPreferred();
      }

      this._timeout = setTimeout(() => {
        this.checkForUpdates();
      }, 5000);

      this._interval = setInterval(() => {
        this.checkForUpdates();
      }, 30000);
    }
  }

  ready() {
    return Promise.all([
      this.user.ready(),
      this.user.signedIn && this.profile.ready(),
      this.benchmark && this.benchmark.ready(),
      this.sharedPortfolio && this.sharedPortfolio.ready(),
      this.riskProfile && this.riskProfile.ready(),
    ]);
  }

  dispose() {
    clearTimeout(this._timeout);
    clearInterval(this._interval);
  }

  checkForUpdates() {
    const portfolios = [];
    const list = {};

    if (this.profile) {
      portfolios.push(...this.profile.portfolios);
    }
    if (this.sharedPortfolio) {
      portfolios.push(this.sharedPortfolio);
    }

    for (const p of portfolios) {
      if (!p) continue;

      for (const a of [
        ...p.assets,
        {
          security: this.profile
            ? this.profile.benchmark
            : this.sharedPortfolio.benchmark,
        },
      ]) {
        const sec = a.security;
        if (list[a.symbol]) continue;
        list[sec.symbol] = sec;

        const ts = sec.lastLookup.unix();
        const now = moment().unix();
        if (!sec.hasError && ts + SEC_LOOKUP_MAX_AGE < now) {
          sec.incrementLookup();
        }
      }
    }
  }

  get profile() {
    if (!this.user.signedIn) return null;

    if (this._profile) return this._profile;

    this._profile = new Profile(
      () => `profiles/${this.user.uid}`,
      this._options,
    );
    return this._profile;
  }

  get sharedPortfolio() {
    const match = matchPath(this.router.location.pathname, {
      path: '/p/:id',
      exact: false,
      strict: false,
    });

    const selected = this.profile && this.profile.selectedPortfolio;
    let pid = match ? match.params.id : selected && selected.sharedId;

    if (!pid) return null;
    if (this._sharedPortfolio) return this._sharedPortfolio;

    this._sharedPortfolio = new SharedPortfolio(
      () => `sharedPortfolios/${pid}`,
      {
        cache: this._options.cache,
      },
    );

    return this._sharedPortfolio;
  }

  get riskProfile() {
    if (this._riskProfile) return this._riskProfile;

    this._riskProfile = new RiskProfile(() => `riskProfiles/${this.user.uid}`, {
      cache: this._options.cache,
    });

    return this._riskProfile;
  }

  @computed get benchmark() {
    return this.profile ? this.profile.benchmark : null;
  }

  @computed get portfolio() {
    return this.profile && this.profile.selectedPortfolio;
  }

  @computed get themeType() {
    return this._themeType || (this.profile && this.profile.theme) || 'dark';
  }

  @computed get theme() {
    return createMuiTheme(this.themeType === 'light' ? light : dark);
  }

  @computed get isAutoTheme() {
    return this.profile && this.profile.isAutoTheme;
  }

  @action setTheme(type, manual = true) {
    if (type !== 'dark' && type !== 'light') {
      throw new Error(`'type' should be 'dark' or 'light', got ${type}`);
    }
    this._themeType = type;
    if (this.profile) this.profile.setTheme(type);
    if (manual && this.isAutoTheme) this.profile.setAutoTheme(false);
  }

  @action setAutoTheme(enabled) {
    this.profile.setAutoTheme(enabled);
    if (enabled) {
      this.syncThemeWithPreferred();
    }
  }

  @action syncThemeWithPreferred() {
    const current = getPreferredColorScheme();
    if (this.themeType !== current) {
      this.setTheme(current, false);
    }
  }

  @action setBackdrop(state) {
    this.backdrop = state;
  }
}

export default Store;
