import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
  dataArea: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.sizes.dataAreaMaxWidth,
    },
    flexWrap: 'nowrap',
  },
  chartArea: {
    height: '40vh',
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.sizes.dataAreaMaxWidth - 40,
    },
    [theme.breakpoints.up('lg')]: {
      height: '85vh',
    },
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  sticky: {
    [theme.breakpoints.up('lg')]: {
      top: 0,
      position: 'sticky',
    },
  },
}));

function LayoutPortfolioConstruction({
  headerBlock,
  guestMode,
  children,
  chartScope,
  chartPortfolioGrowth,
  chartPortfolioReturns,
  portfolioList,
  portfolioAssets,
  portfolioCard,
  dateRange,
  portfolioActions,
  portfolioOptimization,
  portfolioCompare,
  riskAssesment,
}) {
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  const [toolsTab, setToolsTab] = useState(0);

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid
        item
        xs={12}
        md={'auto'}
        container
        direction={'column'}
        spacing={3}
        className={classes.dataArea}
      >
        <Grid item hidden={!headerBlock}>
          {headerBlock}
        </Grid>
        <Grid item hidden={!riskAssesment}>
          {riskAssesment}
        </Grid>
        {/* <Grid item hidden={!dateRange}>
          {dateRange}
        </Grid> */}
        <Grid item hidden={!portfolioList}>
          {portfolioList}
        </Grid>
        <Grid item hidden={!portfolioAssets}>
          {portfolioAssets}
        </Grid>
        <Grid item>{portfolioCard}</Grid>
        <Grid item hidden={!portfolioOptimization || !portfolioOptimization}>
          <Paper elevation={1}>
            <Tabs
              value={toolsTab}
              onChange={(e, v) => setToolsTab(v)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              centered
              className={classes.tabs}
            >
              <Tab label="Optimize" />
              <Tab label="Compare" />
            </Tabs>
            <Box>
              {toolsTab === 0 && portfolioOptimization}
              {toolsTab === 1 && portfolioCompare}
            </Box>
          </Paper>
        </Grid>
        <Grid item hidden={!portfolioActions}>
          {portfolioActions}
        </Grid>
        <Grid item hidden>
          {children}
        </Grid>
      </Grid>
      <Grid item xs={12} md zeroMinWidth className={classes.sticky}>
        <Grid container direction={'column'} spacing={3}>
          <Grid item xs>
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              centered
              className={classes.tabs}
            >
              <Tab label="Radar" />
              <Tab label="Portfolio Growth" />
              <Tab label="Annual Returns" />
            </Tabs>
          </Grid>
          <Grid item xs>
            <Box pl={0} mt={-3}>
              {dateRange}
            </Box>
            <Box>
              <div className={classes.chartArea}>
                {tab === 0 && chartScope}
                {tab === 1 && chartPortfolioGrowth}
                {tab === 2 && chartPortfolioReturns}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

LayoutPortfolioConstruction.propTypes = {
  children: PropTypes.node,
  headerBlock: PropTypes.element,
  guestMode: PropTypes.element,
  chartScope: PropTypes.element.isRequired,
  chartPortfolioGrowth: PropTypes.element.isRequired,
  chartPortfolioReturns: PropTypes.element.isRequired,
  portfolioList: PropTypes.element,
  portfolioCard: PropTypes.element,
  tickerSearch: PropTypes.element,
  portfolioAssets: PropTypes.element,
  portfolioActions: PropTypes.element,
  portfolioOptimization: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]),
  portfolioCompare: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  dateRange: PropTypes.element,
  riskAssesment: PropTypes.element.isRequired,
};

LayoutPortfolioConstruction.defaultProps = {
  portfolioOptimization: false,
  portfolioCompare: false,
};

export default React.memo(LayoutPortfolioConstruction);
