export function isServer() {
  return !(typeof window !== 'undefined' && window.document);
}

export default {
  isServer: process.env.BUILD_TARGET === 'server',
  isClient: process.env.BUILD_TARGET === 'client',
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
};
