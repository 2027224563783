import React from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition, interpolate } from 'react-spring';

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function ChartAnalystRatingPie({ animate, arcs, path, getKey, getColor }) {
  const transitions = useTransition(
    arcs,
    getKey,
    // @ts-ignore react-spring doesn't like this overload
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    },
  );
  return (
    <>
      {transitions.map(({ item: arc, props, key }) => {
        return (
          <g key={key}>
            <animated.path
              // compute interpolated path d attribute from intermediate angle values
              d={interpolate(
                [props.startAngle, props.endAngle],
                (startAngle, endAngle) =>
                  path({
                    ...arc,
                    startAngle,
                    endAngle,
                  }),
              )}
              fill={getColor(arc)}
            />
          </g>
        );
      })}
    </>
  );
}

ChartAnalystRatingPie.propTypes = {
  animate: PropTypes.bool,
  arcs: PropTypes.any,
  path: PropTypes.any,
  opacity: PropTypes.any,
  startAngle: PropTypes.any,
  endAngle: PropTypes.any,
  getKey: PropTypes.func,
  getColor: PropTypes.func,
};
export default React.memo(ChartAnalystRatingPie);
