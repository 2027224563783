import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useLocation } from 'react-router-dom';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Portfolio from '../Portfolio';
import Info from '../Info';
import NotFound from '../NotFound';
import Login from '../Login';
import Account from '../Account';
import Profile from '../Profile';
import RiskProfile from '../RiskProfile';
import SharedPortfolio from '../SharedPortfolio';
import PreviewPage from '../PreviewPage';
import './App.css';
import { useStore } from '../../store';

import AppMenu from '../../components/AppMenu';
import Layout from '../../components/Layout';
import BottomAppMenu from '../../components/BottomAppMenu';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
}));

function App() {
  const classes = useStyles();
  const { user, backdrop } = useStore();
  const location = useLocation();

  const currentLocation = location.pathname;

  return (
    <Layout
      appMenuDocked={user.signedIn}
      // appMenu={<AppMenu />}
      bottomAppMenu={<BottomAppMenu />}
    >
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Switch>
        <Route exact path="/" component={Portfolio} />
        <Route exact path="/info" component={Info} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/p/:id" component={SharedPortfolio} />
        <Route exact path="/p/:id/preview" component={PreviewPage} />
        <Route exact path="/risk-profile" component={RiskProfile} />
        <Route path="" component={NotFound} />
      </Switch>
    </Layout>
  );
}

App.propTypes = {};

App.defaultProps = {};

export default observer(App);
