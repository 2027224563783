import React from 'react';

const StoreContext = React.createContext();

/* eslint-disable react/prop-types */
export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
/* eslint-enable react/prop-types */

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
/* eslint-disable react/display-name */
export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};
/* eslint-enable react/display-name */

export { default as Store } from './Store';
export { default as Profile } from './Profile';
export { default as Portfolio } from './Portfolio';
export { default as Cache } from './Cache';
export { default as Asset } from './Asset';
export { default as Security } from './Security';
export { default as Mixer } from './Mixer';
