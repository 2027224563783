import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { Portfolio } from '../../store';

import PortfolioHead from '../PortfolioHead';
import PortfolioMetricsTable from '../PortfolioMetricsTable';
import TickerSearch from '../TickerSearch';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: lighten(theme.palette.background.paper, 0.05),
  },
}));

function PortfolioCard({
  portfolio,
  expertMode,
  selectedAssets,
  onAssetChange,
  onAssetAdd,
  onAssetRemove,
  onAllocationReset,
  onAllocationEqualize,
  onAllocationFix,
  onAssetDetailOpen,
  onPortfolioShare,
  onPortfolioRemove,
  onPortfolioMerge,
  onPortfolioCopy,
  onPortfolioRename,
}) {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.root}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <PortfolioHead
            portfolio={portfolio}
            onPortfolioShare={onPortfolioShare}
            onPortfolioRemove={onPortfolioRemove}
            onPortfolioMerge={onPortfolioMerge}
            onPortfolioCopy={onPortfolioCopy}
            onPortfolioRename={onPortfolioRename}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="column">
            {/* TODO: hidden if preview or benchmark */}
            <Grid item>
              <TickerSearch onSelect={onAssetAdd} />
            </Grid>
            {/* TODO: hidden if benchmark or no assets */}
            <Grid item hidden={false}>
              <PortfolioMetricsTable
                onChange={onAssetChange}
                onRemove={onAssetRemove}
                onReset={onAllocationReset}
                onEqualize={onAllocationEqualize}
                onFix={onAllocationFix}
                onAssetDetailOpen={onAssetDetailOpen}
                portfolio={portfolio}
                expertMode={expertMode}
                highlightAssets={selectedAssets}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

PortfolioCard.propTypes = {
  onAssetChange: PropTypes.func,
  onAssetRemove: PropTypes.func,
  portfolio: PropTypes.instanceOf(Portfolio).isRequired,
  expertMode: PropTypes.bool,
  selectedAssets: PropTypes.array,
  onAssetAdd: PropTypes.func,
  onAssetDetailOpen: PropTypes.func,
  onAllocationReset: PropTypes.func,
  onAllocationEqualize: PropTypes.func,
  onAllocationFix: PropTypes.func,
  onPortfolioShare: PropTypes.func,
  onPortfolioRemove: PropTypes.func,
  onPortfolioMerge: PropTypes.func,
  onPortfolioCopy: PropTypes.func,
  onPortfolioRename: PropTypes.func,
};

PortfolioCard.defaultProps = {};

export default React.memo(PortfolioCard);
