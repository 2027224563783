import { toJS } from 'mobx';
import { Document } from 'firestorter';
import { isServer } from '../utils/env';

class UniversalDocument extends Document {
  _cache = null;

  constructor(source, options = {}) {
    if (!isServer()) {
      super(source, options);

      if (options.cache) {
        this._cache = options.cache;
      }
      return;
    }

    options.mode = 'off';
    super(source, options);
    if (options.cache) {
      this._cache = options.cache;
    }

    this.fetch();
  }

  ready() {
    return new Promise((resolve) => {
      super.ready().then((v) => {
        // console.log('DOCUMENT->CACHE:', this.path);
        if (isServer() && this._cache) {
          this._cache.set(this.path, toJS(this.data));
        }
        resolve(v);
      });
    });
  }

  log(msg) {
    const enabled = false;
    if (!enabled || isServer()) return;

    const filter = ['Portfolio C', 'Security AMZN'];
    if (filter.length > 0 && !filter.includes(this.debugName)) return;

    console.log(`${this.debugName}:`, msg); // eslint-disable-line no-console
  }

  get hasCache() {
    return this._cache !== null && this._cache.has(this.path);
  }

  get hasData() {
    return super.hasData || (this.hasCache && !super.isLoaded);
  }

  get isLoading() {
    return !this.hasCache && super.isLoading;
  }

  get data() {
    if ((super.isLoading || !super.isLoaded) && this.hasCache) {
      return this._cache.get(this.path);
    }

    return super.data;
  }
}

export default UniversalDocument;
