import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { xor } from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import DialogTransition from '../DialogTransition';
import EtfDetail from '../EtfDetail';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
  },
  inner: {
    padding: theme.spacing(3),
  },
  itemBorder: {
    borderRightWidth: 1,
    borderRightColor: theme.palette.divider,
    borderRightStyle: 'dashed',
  },
}));

function AssetCompareDialog({ open, assets, onClose, onImportHoldings }) {
  const classes = useStyles();
  const [highlightUnique, setHighlightUnique] = useState(false);

  const handleHighlightUniqueToggle = (event) => {
    setHighlightUnique(event.target.checked);
  };

  const handleImportSingle = (asset) => (e) => {
    onImportHoldings(e, asset);
    onClose();
  };

  if (!assets || assets.length === 0) return false;

  let uniquePositions = [];
  if (highlightUnique && assets.length == 2) {
    uniquePositions = xor(
      ...assets.reduce(
        (acc, x) => [...acc, x.fundTopHoldings.map((x) => x.symbol)],
        [],
      ),
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      TransitionComponent={DialogTransition}
      scroll={'paper'}
    >
      <DialogContent className={classes.content}>
        <Grid container spacing={6} direction="row" className={classes.inner}>
          {assets.map((asset, index) => (
            <Grid
              key={asset.id}
              className={index !== assets.length - 1 ? classes.itemBorder : ''}
              item
              xs
            >
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Typography variant={'h2'}>{asset.symbol}</Typography>
                  <Typography variant="body2">{asset.name}</Typography>
                </Grid>
                <Grid item>
                  <EtfDetail asset={asset} highlight={uniquePositions} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={highlightUnique}
              onChange={handleHighlightUniqueToggle}
              color="secondary"
            />
          }
          label="Highlight unique positions"
        />
        <Tooltip title="Import fund top holdings as new portfolio" arrow>
          <ButtonGroup
            aria-label="import options button group"
            variant="outlined"
          >
            {assets.map((asset) => (
              <Button key={asset.id} onClick={handleImportSingle(asset)}>
                {asset.label}
              </Button>
            ))}
            <Button onClick={handleImportSingle(assets)}>Combined</Button>
          </ButtonGroup>
        </Tooltip>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

AssetCompareDialog.propTypes = {
  open: PropTypes.bool,
  assets: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onImportHoldings: PropTypes.func,
};

AssetCompareDialog.defaultProps = {};

export default React.memo(AssetCompareDialog);
