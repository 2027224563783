import { computed, observable, action, flow, reaction, comparer } from 'mobx';
import { computedFn } from 'mobx-utils';
import moment from 'moment';

import firebase from 'firebase/app';
import { axis_op, toPlainArray } from '../empyrical-js/utils';
import { cum_returns, aggregate_returns } from '../empyrical-js/stats';
import {
  calculateMetrics,
  dataframeComparer,
  momentComparer,
  today,
} from './helpers';
import { numRoundF } from '../../common/utils/helpers';
import UniversalDocument from './UniversalDocument';
import UniversalCollection from './UniversalCollection';
import Asset from './Asset';
import { isServer } from '../utils/env';
import { INITIAL_AMOUNT } from './constants';

function calcMinVolatility(assets, includeCash = true) {
  const vol = (x) => numRoundF(x.metrics.stdDev * 100);
  const min = assets.reduce(
    (acc, a) => ((includeCash || !a.isCash) && vol(a) < acc ? vol(a) : acc),
    1000,
  );
  return min !== 1000 ? numRoundF(min) : 0;
}

function transformToDatePrice(d) {
  return d ? d.to_json()['price'] : null;
}

class Portfolio extends UniversalDocument {
  _totalAmountReaction = null;
  _assetsReaction = null;
  @observable _assetsCollection = null;
  @observable _profile = null;
  @observable _benchmark = null;
  @observable _options = null;
  @observable _constrainer = null;

  constructor(source, options) {
    super(source, options);

    const { store } = options;
    this._profile = store && store.profile;
    this._benchmark = store && store.benchmark;
    this._constrainer = options.constrainer;
    this._options = options;
  }

  get _assets() {
    if (this._assetsCollection) return this._assetsCollection;

    this._assetsCollection = new UniversalCollection(
      () => `${this.path}/assets`,
      {
        ...this._options,
        createDocument: (s, o) => new Asset(s, { ...this._options, ...o }),
      },
    );

    if (!isServer()) {
      this._totalAmountReaction = reaction(
        () => this.totalAmount,
        (totalAmount) => {
          if (!this.existing) return;
          for (const a of this.assets) {
            a.setAllocation((a.amount / totalAmount) * 100);
          }
        },
        { delay: 500 },
      );

      this._assetsReaction = reaction(
        () => this.assets.map((x) => x.allocation),
        async () => {
          if (!this._assetsReaction || !super.hasData) return;
          try {
            await this.update({
              updatedAt: new Date(),
              sharedId: firebase.firestore.FieldValue.delete(),
            });
          } catch (e) {} // eslint-disable-line no-empty
        },
        {
          delay: 1000,
          equals: comparer.structural,
        },
      );
    }

    return this._assetsCollection;
  }

  ready() {
    return Promise.all([
      super.ready(),
      this._assets.ready(),
      ...this.assets.map((a) => a.ready()),
    ]);
  }

  get debugName() {
    return `Portfolio ${this.tag}`;
  }

  delete() {
    this._totalAmountReaction();
    this._assetsReaction();
    return Promise.all([...this.assets.map((a) => a.delete()), super.delete()]);
  }

  @computed get assets() {
    return this._assets.docs;
  }

  @computed get tag() {
    const { tag } = this.data;
    return typeof tag === 'string' ? tag : '-';
  }

  @computed get name() {
    return this.data.name;
  }

  @computed get createdAt() {
    const { createdAt } = this.data;
    return new Date(createdAt ? createdAt.seconds * 1000 : 0);
  }

  @computed({ equals: momentComparer }) get since() {
    const { assets } = this;
    if (assets.length === 0) {
      return moment.unix(0);
    }

    return assets.reduce(
      (acc, a) => (a.since.isAfter(acc) ? a.since : acc),
      moment.unix(0),
    );
  }

  @computed({ equals: momentComparer }) get till() {
    const { assets } = this;
    if (assets.length === 0) {
      return today();
    }

    return assets.reduce(
      (acc, a) => (a.till.isBefore(acc) ? a.till : acc),
      moment(),
    );
  }

  @computed get constrainingAssets() {
    const { assets, _benchmark } = this;
    if (assets.length === 0) {
      return [];
    }

    return assets.filter((a) => a.since.isAfter(_benchmark.since));
  }

  _returns = computedFn(
    (optimized) => {
      let returns = null;
      this.log('compute _returns');

      for (const a of this.assets) {
        const allocation = optimized ? a.optimizedAllocation : a.allocation;
        if (!a.returns || allocation === 0) continue;

        const index = a.returns.index;
        const mask = index.map((idx) =>
          idx.isBetween(
            this._constrainer ? this._constrainer.since : this.since,
            this._constrainer ? this._constrainer.till : this.till,
            undefined,
            '[]',
          ),
        );
        const slice = a.returns.filter(mask);
        const reduced = axis_op(slice, (f) => f.mul(allocation / 100));

        if (!returns) {
          returns = reduced;
        } else {
          returns = axis_op(returns, (f) => f.add(reduced.get('price')));
        }
      }

      return returns;
    },
    { equals: dataframeComparer },
  );

  @computed({ equals: dataframeComparer }) get returns() {
    return this._returns(false);
  }

  @computed get cumReturns() {
    return cum_returns(this.returns);
  }

  @computed get benchmarkCumReturns() {
    const index = this._benchmark.returns.index;
    const mask = index.map((idx) =>
      idx.isBetween(
        this._constrainer ? this._constrainer.since : this.since,
        this._constrainer ? this._constrainer.till : this.till,
        undefined,
        '[]',
      ),
    );
    const slice = this._benchmark.returns.filter(mask);

    return cum_returns(slice);
  }

  @computed get comparedCumReturns() {
    if (!this._profile.comparedPortfolio) return null;
    return (
      this._profile.comparedPortfolio.returns &&
      cum_returns(this._profile.comparedPortfolio.returns)
    );
  }

  @computed get plainCumReturns() {
    return toPlainArray(this.cumReturns);
  }

  @computed get cumAmount() {
    if (!this.cumReturns) return null;

    return axis_op(this.cumReturns, (f) =>
      f.mul(INITIAL_AMOUNT).add(INITIAL_AMOUNT),
    );
  }

  @computed get plainCumAmount() {
    return toPlainArray(this.cumAmount);
  }

  @computed get annualReturns() {
    return aggregate_returns(this.returns);
  }

  @computed get plainAnnualReturns() {
    return toPlainArray(this.annualReturns);
  }

  @computed get optimizedReturns() {
    return this._returns(true);
  }

  @computed get plainOptimizedReturns() {
    return toPlainArray(this.optimizedReturns);
  }

  @computed get optimizedAnnualReturns() {
    if (!this.optimizedReturns) return null;

    return aggregate_returns(this.optimizedReturns);
  }

  @computed get plainOptmizedAnnualReturns() {
    return toPlainArray(this.optimizedAnnualReturns);
  }

  @computed get optimizedCumReturns() {
    if (!this.optimizedReturns) return null;

    return cum_returns(this.optimizedReturns);
  }

  @computed get plainOptimizedCumReturns() {
    return toPlainArray(this.optimizedReturns);
  }

  @computed get optimizedCumAmount() {
    if (!this.optimizedCumReturns) return null;

    return axis_op(this.optimizedCumReturns, (f) =>
      f.mul(INITIAL_AMOUNT).add(INITIAL_AMOUNT),
    );
  }

  @computed get plainOptimizedCumAmount() {
    return toPlainArray(this.optimizedCumAmount);
  }

  @computed get benchmarkAnnualReturns() {
    return aggregate_returns(this._benchmark.returns);
  }

  @computed get benchmarkCumAmount() {
    if (!this.benchmarkCumReturns) return null;

    return axis_op(this.benchmarkCumReturns, (f) =>
      f.mul(INITIAL_AMOUNT).add(INITIAL_AMOUNT),
    );
  }

  @computed get comparedAnnualReturns() {
    if (!this._profile.comparedPortfolio) return null;
    return (
      this._profile.comparedPortfolio.returns &&
      aggregate_returns(this._profile.comparedPortfolio.returns)
    );
  }

  @computed get comparedCumAmount() {
    if (!this.comparedCumReturns) return null;

    return axis_op(this.comparedCumReturns, (f) =>
      f.mul(INITIAL_AMOUNT).add(INITIAL_AMOUNT),
    );
  }

  _metrics = computedFn(
    (optimized) => {
      this.log(`compute _metrics ${optimized ? '(optimized)' : ''}`);

      return calculateMetrics(
        optimized ? this.optimizedReturns : this.returns,
        this._benchmark ? this._benchmark.returns : null,
        this._constrainer ? this._constrainer.since : this.since,
        this._constrainer ? this._constrainer.till : this.till,
      );
    },
    { equals: comparer.structural, keepAlive: true },
  );

  @computed.struct get metrics() {
    return this._metrics(false);
  }

  @computed.struct get optimizedMetrics() {
    return this._metrics(true);
  }

  @computed get sortedAssets() {
    return this.assets
      .slice()
      .sort((a, b) => b.isCash - a.isCash || b.createdAt - a.createdAt);
  }

  @computed get previewAssets() {
    return this.sortedAssets.filter((a) => a.allocation !== 0);
  }

  @computed get sortedAssetsDivided() {
    const cash = this.assets.find((x) => x.isCash);
    const list = this.sortedAssets.filter((x) => !x.isCash);

    return { cash, list };
  }

  @computed get sortedByOptAllocationAssets() {
    return this.assets
      .slice()
      .sort((a, b) => b.optimizedAllocation - a.optimizedAllocation);
  }

  @computed get assetsSortedByAllocation() {
    return this.assets.slice().sort((a, b) => b.allocation - a.allocation);
  }

  @computed get totalAllocation() {
    return this.assets.reduce((acc, a) => {
      if (!a.allocation) return acc;
      return acc + Number(a.allocation);
    }, 0);
  }

  @computed get totalAmount() {
    return this.assets.reduce((acc, a) => {
      if (!a.amount) return acc;
      return acc + Number(a.amount);
    }, 0);
  }

  @computed get allocationDelta() {
    return 100 - this.totalAllocation;
  }

  @computed get allocationFull() {
    return numRoundF(this.totalAllocation) === numRoundF(100);
  }

  @computed get hasAssets() {
    return (
      this.assets.length > 1 ||
      (this.assets.length === 1 && !this.assets[0].isCash)
    );
  }

  @computed get metricsReady() {
    const cash = this.assets.find((a) => a.isCash === true);
    return (
      this.hasAssets &&
      this.allocationFull &&
      (!cash || cash.allocation !== 100)
    );
  }

  @computed get existing() {
    const { existing } = this.data;
    return typeof existing === 'boolean' ? existing : false;
  }

  @computed get optimization() {
    const { optimization } = this.data;
    return typeof optimization === 'string' ? optimization : 'efficient_risk';
  }

  @computed get optimizationIsLoading() {
    const { optRequest } = this.data;
    return typeof optRequest === 'string' && optRequest !== 'done';
  }

  @computed get optimizationIsReady() {
    const { optResult } = this.data;
    return typeof optResult === 'string' && optResult === this.optimization;
  }

  @computed get comparedExists() {
    return this._profile && this._profile.comparedPortfolio;
  }

  @computed get isOptimized() {
    return this.assets.reduce(
      (acc, a) =>
        numRoundF(a.allocation, 1) !== numRoundF(a.optimizedAllocation, 1)
          ? false
          : acc,
      true,
    );
  }

  @computed get optimizationError() {
    const { optResult, optError } = this.data;
    return optResult === 'error' ? optError : false;
  }

  @computed get nominalVolatility() {
    return calcMinVolatility(this.assets, this.assets.length === 1);
  }

  @computed get minVolatility() {
    return calcMinVolatility(this.assets, true);
  }

  @computed get targetVolatility() {
    const { optTargetVolatility } = this.data;
    if (
      typeof optTargetVolatility === 'number' ||
      typeof optTargetVolatility === 'string'
    ) {
      return optTargetVolatility;
    }

    return this.nominalVolatility;
  }

  @computed get sharedId() {
    return this.data.sharedId;
  }

  @computed get shareAmount() {
    // return this.data.shareAmount;
    const { shareAmount } = this.data;
    return typeof shareAmount === 'boolean' ? shareAmount : false;
  }

  @computed get imported() {
    return this.data.imported;
  }

  @computed get annualReturnsChartData() {
    if (!this.metricsReady) return null;

    const portfolio = transformToDatePrice(this.annualReturns);
    const benchmark = transformToDatePrice(this.benchmarkAnnualReturns);
    const optimized = transformToDatePrice(this.optimizedAnnualReturns);

    let compared = null;
    if (this.comparedExists) {
      compared = transformToDatePrice(this.comparedAnnualReturns);
    }

    let data = [];
    for (const date in portfolio) {
      const dp = {
        date: Number(date),
        portfolio: portfolio[date],
      };
      if (this.comparedExists) {
        dp.compared = compared[date];
      }
      if (this.optimizationIsReady) {
        dp.optimized = optimized[date];
      }

      dp.benchmark = benchmark[date];

      data.push(dp);
    }
    return data;
  }

  @computed get chartsMetaData() {
    const data = {
      portfolio: this.name,
      benchmark: this._benchmark.symbol,
      optimized: this.optimizationIsReady ? `OPTIMIZED` : '',
      compared: this.comparedExists ? this._profile.comparedPortfolio.name : '',
    };
    return data;
  }

  @computed get portfolioGrowthChartData() {
    if (!this.metricsReady) return null;

    const portfolio = transformToDatePrice(this.cumAmount);
    const benchmark = transformToDatePrice(this.benchmarkCumAmount);
    const optimized = transformToDatePrice(this.optimizedCumAmount);

    let compared = null;
    if (this.comparedExists) {
      compared = transformToDatePrice(this.comparedCumAmount);
    }

    let data = [];

    if (portfolio && Object.keys(portfolio).length > 0) {
      const dp = {
        date: moment(new Date(Object.keys(portfolio)[0])).subtract(1, 'months'),
        portfolio: INITIAL_AMOUNT,
      };
      if (this.comparedExists) {
        dp.compared = INITIAL_AMOUNT;
      }
      if (this.optimizationIsReady) {
        dp.optimized = INITIAL_AMOUNT;
      }
      dp.benchmark = INITIAL_AMOUNT;

      data.push(dp);
    }

    for (const date in portfolio) {
      const dp = {
        date: moment(new Date(date)),
        portfolio: portfolio[date],
      };
      if (this.comparedExists) {
        dp.compared = compared[date];
      }
      if (this.optimizationIsReady) {
        dp.optimized = optimized[date];
      }
      dp.benchmark = benchmark[date];

      data.push(dp);
    }

    return data;
  }

  @computed get dateRange() {
    const dateFormat = 'MMM YYYY';
    return `${this.since.format(dateFormat)} - ${this.till.format(dateFormat)}`;
  }

  _addAsset = flow(function* (symbol, allocation, amount, createdAt) {
    this.invalidateOptimization();

    const asset = yield this._assets.add({
      symbol,
      allocation,
      createdAt,
      amount,
    });
    asset.security.incrementLookup();
    if (this._profile && this._profile.equalize) {
      this.equalize();
    }
  });

  @action addAsset(symbol, allocation = 0, amount = 0, createdAt = new Date()) {
    if (!symbol || symbol.length < 1) {
      throw new Error('Symbol is required');
    }
    if (typeof allocation !== 'number') {
      throw new Error(
        `Allocation should be a number, got ${allocation}(${typeof allocation})`,
      );
    }
    if (allocation < 0 || allocation > 100) {
      throw new Error('Allocation should be in range 0-100');
    }
    if (this.assets.find((a) => a.symbol === symbol)) {
      throw new Error('Already in list');
    }

    return this._addAsset(symbol, allocation, amount, createdAt);
  }

  @action equalize() {
    const { list, cash } = this.sortedAssetsDivided;

    if (cash) {
      cash.setAllocation(0);
    }

    const eqaulAlloc = 100 / list.length;
    for (const a of list) {
      a.setAllocation(eqaulAlloc);
    }
  }

  _removeAsset = flow(function* (asset) {
    this.invalidateOptimization();

    yield asset.delete();

    if (this._profile && this._profile.equalize) {
      this.equalize();
    }
  });

  @action removeAsset(asset, symbol = null) {
    if (!asset && !symbol) {
      throw new Error('Either asset or symbol argument should be specified');
    }
    if (!asset && symbol) {
      asset = this.assets.find((a) => a.symbol === symbol);
    }
    if (asset) {
      this._removeAsset(asset);
    }
  }

  @action setOptimization(goal) {
    if (goal !== 'max_sharpe' && goal !== 'efficient_risk') {
      throw new Error(
        `Gaol should be either 'max_sharpe' or 'efficient_risk' but got ${goal}`,
      );
    }
    this.update({ optimization: goal });
  }

  @action invalidateOptimization() {
    this.update({
      optResult: 'invalidated',
      optTargetVolatility: firebase.firestore.FieldValue.delete(),
    });
  }

  @action setTargetVolatility(percentage) {
    if (percentage < 0 && percentage !== '') {
      throw new Error(`'percentage' should be positive, got ${percentage}`);
    }
    if (percentage !== '') {
      percentage = parseFloat(percentage);
    }
    this.update({ optTargetVolatility: percentage });
  }

  @action optimize() {
    this.update({
      optTargetVolatility: this.targetVolatility,
      optRequest: this.optimization,
    });
  }

  @action applyOptimization() {
    for (const a of this.assets) {
      a.setAllocation(a.optimizedAllocation);
    }
  }

  @action rename(name) {
    this.update({ name });
  }
}

export default Portfolio;
