import React from 'react';
import PropTypes from 'prop-types';
import { numRound } from '../../utils/helpers';

import Indicator from './Indicator';

function CagrStdev({ cagr, stdDev, neutral, ready, large, showCaptions }) {
  const caption = 'CAGR/SD';

  if (isNaN(cagr) || isNaN(stdDev) || !ready) {
    return (
      <Indicator
        val1={'--'}
        val2={'--'}
        tint={'default'}
        large={large}
        caption={caption}
        showCaptions={showCaptions}
      />
    );
  }

  const cagrValue = numRound(cagr * 100);
  const stdevValue = numRound(stdDev * 100);

  const ratio = cagrValue / stdevValue;

  const getTint = () => {
    if (neutral) return 'default';
    if (ratio >= 1 && ratio < 1.25) return 'yellow';
    if (ratio >= 1.25) return 'green';
    return 'red';
  };

  return (
    <Indicator
      val1={cagrValue}
      val2={stdevValue}
      tint={getTint()}
      caption={caption}
      large={large}
      showCaptions={showCaptions}
    />
  );
}

CagrStdev.propTypes = {
  cagr: PropTypes.number,
  stdDev: PropTypes.number,
  neutral: PropTypes.bool,
  large: PropTypes.bool,
  ready: PropTypes.bool,
  showCaptions: PropTypes.bool,
  onClick: PropTypes.func,
};

CagrStdev.defaultProps = {
  stdDev: 0,
  neutral: false,
  showCaptions: false,
};

export default React.memo(CagrStdev);
