import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PortfolioTag from '../PortfolioTag';
import PortfolioName from '../PortfolioName';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function PortfolioSymbol({ name, tag, selected, imported, compared }) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      spacing={2}
      alignItems={'center'}
    >
      <Grid item>
        <PortfolioTag tag={tag} selected={selected} compared={compared} />
      </Grid>
      <Grid item>
        <PortfolioName
          imported={imported}
          selected={selected}
          compared={compared}
        >
          {name}
        </PortfolioName>
      </Grid>
    </Grid>
  );
}

PortfolioSymbol.propTypes = {
  name: PropTypes.string,
  tag: PropTypes.string,
  selected: PropTypes.bool,
  imported: PropTypes.bool,
  compared: PropTypes.bool,
};

PortfolioSymbol.defaultProps = {
  selected: false,
  imported: false,
  compared: false,
};

export default React.memo(PortfolioSymbol);
