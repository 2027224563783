import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  primaryStroke: {
    stroke: theme.palette.primary.main,
  },
  stroke: {
    stroke: theme.palette.text.secondary,
  },
}));

export function ExistingIllustration() {
  const classes = useStyles();

  return (
    <svg
      width="96px"
      height="112px"
      viewBox="0 0 96 112"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Create Existing Portfolio</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1280-Menu-Copy-11"
          transform="translate(-817.000000, -296.000000)"
        >
          <g id="Group-13" transform="translate(320.000000, 232.000000)">
            <g id="Group-8" transform="translate(497.000000, 64.000000)">
              <rect
                id="Rectangle"
                fillOpacity="0"
                fillRule="nonzero"
                x="0"
                y="0"
                width="96"
                height="112"
                rx="4"
              ></rect>
              <g
                id="Group-6"
                transform="translate(13.000000, 22.000000)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path
                  d="M5.85432826,0.638186567 L0.179568265,10.0113512 C-0.0585159702,10.4192891 -0.0599374701,10.9215869 0.175834126,11.3308375 C0.411605722,11.7400882 0.849157798,11.9948227 1.32524,12 L12.67476,12 C13.1508422,11.9948227 13.5883943,11.7400882 13.8241659,11.3308375 C14.0599375,10.9215869 14.058516,10.4192891 13.8204317,10.0113512 L8.14567174,0.638186567 C7.90275873,0.241969057 7.46837618,0 7,0 C6.53162382,0 6.09724127,0.241969057 5.85432826,0.638186567 Z"
                  id="Path"
                  className={classes.primaryStroke}
                ></path>
                <path
                  d="M5.85432826,28.6381866 L0.179568265,38.0113512 C-0.0585159702,38.4192891 -0.0599374701,38.9215869 0.175834126,39.3308375 C0.411605722,39.7400882 0.849157798,39.9948227 1.32524,40 L12.67476,40 C13.1508422,39.9948227 13.5883943,39.7400882 13.8241659,39.3308375 C14.0599375,38.9215869 14.058516,38.4192891 13.8204317,38.0113512 L8.14567174,28.6381866 C7.90275873,28.2419691 7.46837618,28 7,28 C6.53162382,28 6.09724127,28.2419691 5.85432826,28.6381866 Z"
                  id="Path"
                  className={classes.primaryStroke}
                ></path>
                <line
                  x1="28.5"
                  y1="28"
                  x2="28.5"
                  y2="40"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M31.4166667,30.1818182 L27.0416667,30.1818182 C25.9140853,30.1818182 25,31.0365473 25,32.0909091 C25,33.1452709 25.9140853,34 27.0416667,34 L29.9583333,34 C31.0859147,34 32,34.8547291 32,35.9090909 C32,36.9634527 31.0859147,37.8181818 29.9583333,37.8181818 L25,37.8181818"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="41.5"
                  y1="28"
                  x2="41.5"
                  y2="40"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M44.4166667,30.1818182 L40.0416667,30.1818182 C38.9140853,30.1818182 38,31.0365473 38,32.0909091 C38,33.1452709 38.9140853,34 40.0416667,34 L42.9583333,34 C44.0859147,34 45,34.8547291 45,35.9090909 C45,36.9634527 44.0859147,37.8181818 42.9583333,37.8181818 L38,37.8181818"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <path
                  d="M5.85432826,56.6381866 L0.179568265,66.0113512 C-0.0585159702,66.4192891 -0.0599374701,66.9215869 0.175834126,67.3308375 C0.411605722,67.7400882 0.849157798,67.9948227 1.32524,68 L12.67476,68 C13.1508422,67.9948227 13.5883943,67.7400882 13.8241659,67.3308375 C14.0599375,66.9215869 14.058516,66.4192891 13.8204317,66.0113512 L8.14567174,56.6381866 C7.90275873,56.2419691 7.46837618,56 7,56 C6.53162382,56 6.09724127,56.2419691 5.85432826,56.6381866 Z"
                  id="Path"
                  className={classes.primaryStroke}
                ></path>
                <line
                  x1="28.5"
                  y1="56"
                  x2="28.5"
                  y2="68"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M31.4166667,58.1818182 L27.0416667,58.1818182 C25.9140853,58.1818182 25,59.0365473 25,60.0909091 C25,61.1452709 25.9140853,62 27.0416667,62 L29.9583333,62 C31.0859147,62 32,62.8547291 32,63.9090909 C32,64.9634527 31.0859147,65.8181818 29.9583333,65.8181818 L25,65.8181818"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="41.5"
                  y1="56"
                  x2="41.5"
                  y2="68"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M44.4166667,58.1818182 L40.0416667,58.1818182 C38.9140853,58.1818182 38,59.0365473 38,60.0909091 C38,61.1452709 38.9140853,62 40.0416667,62 L42.9583333,62 C44.0859147,62 45,62.8547291 45,63.9090909 C45,64.9634527 44.0859147,65.8181818 42.9583333,65.8181818 L38,65.8181818"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="54.5"
                  y1="56"
                  x2="54.5"
                  y2="68"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M57.4166667,58.1818182 L53.0416667,58.1818182 C51.9140853,58.1818182 51,59.0365473 51,60.0909091 C51,61.1452709 51.9140853,62 53.0416667,62 L55.9583333,62 C57.0859147,62 58,62.8547291 58,63.9090909 C58,64.9634527 57.0859147,65.8181818 55.9583333,65.8181818 L51,65.8181818"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="28.5"
                  y1="0"
                  x2="28.5"
                  y2="12"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M31.4166667,2.18181818 L27.0416667,2.18181818 C25.9140853,2.18181818 25,3.0365473 25,4.09090909 C25,5.14527089 25.9140853,6 27.0416667,6 L29.9583333,6 C31.0859147,6.00000002 32,6.85472913 32,7.90909091 C32,8.96345269 31.0859147,9.81818182 29.9583333,9.81818182 L25,9.81818182"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="41.5"
                  y1="0"
                  x2="41.5"
                  y2="12"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M44.4166667,2.18181818 L40.0416667,2.18181818 C38.9140853,2.18181818 38,3.0365473 38,4.09090909 C38,5.14527089 38.9140853,6 40.0416667,6 L42.9583333,6 C44.0859147,6.00000002 45,6.85472913 45,7.90909091 C45,8.96345269 44.0859147,9.81818182 42.9583333,9.81818182 L38,9.81818182"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="54.5"
                  y1="0"
                  x2="54.5"
                  y2="12"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M57.4166667,2.18181818 L53.0416667,2.18181818 C51.9140853,2.18181818 51,3.0365473 51,4.09090909 C51,5.14527089 51.9140853,6 53.0416667,6 L55.9583333,6 C57.0859147,6.00000002 58,6.85472913 58,7.90909091 C58,8.96345269 57.0859147,9.81818182 55.9583333,9.81818182 L51,9.81818182"
                  id="Path"
                  className={classes.stroke}
                ></path>
                <line
                  x1="67.5"
                  y1="0"
                  x2="67.5"
                  y2="12"
                  id="Path"
                  className={classes.stroke}
                ></line>
                <path
                  d="M70.4166667,2.18181818 L66.0416667,2.18181818 C64.9140853,2.18181818 64,3.0365473 64,4.09090909 C64,5.14527089 64.9140853,6 66.0416667,6 L68.9583333,6 C70.0859147,6.00000002 71,6.85472913 71,7.90909091 C71,8.96345269 70.0859147,9.81818182 68.9583333,9.81818182 L64,9.81818182"
                  id="Path"
                  className={classes.stroke}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(ExistingIllustration);
