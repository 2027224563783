import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Glyph as CustomGlyph } from '@visx/glyph';
import { Security } from '../../store';

const size = 18;

const useStyles = makeStyles((theme) => ({
  square: {
    width: size,
    height: size,
    x: -size / 2,
    y: -size / 2,
    fill: theme.palette.scope.benchmark,
    transform: 'rotate(45deg)',
  },
  text: {
    fontSize: 12,
    textAnchor: 'middle',
    fill: theme.palette.text.primary,
    y: 24,
  },
}));

function BenchmarkTicker({ benchmark, top, left, onMouseOver, onMouseOut }) {
  const classes = useStyles();

  return (
    <svg onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <CustomGlyph top={top} left={left}>
        <rect className={classes.square} rx={2} ry={2} />
        <text className={classes.text} y={size + 9}>
          {benchmark.symbol}
        </text>
      </CustomGlyph>
    </svg>
  );
}

BenchmarkTicker.propTypes = {
  benchmark: PropTypes.instanceOf(Security),
  top: PropTypes.number,
  left: PropTypes.number,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

BenchmarkTicker.defaultProps = {};

export default React.memo(BenchmarkTicker);
