import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import RiskProfile from '../../containers/RiskProfile';
import DialogTransition from '../DialogTransition';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function RiskProfileDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      TransitionComponent={DialogTransition}
    >
      <div style={{ width: 650 }}>
        {/* <DialogTitle>Risk Profile Test</DialogTitle> */}
        <DialogContent>
          <RiskProfile onClose={onClose} />
        </DialogContent>
      </div>
    </Dialog>
  );
}

RiskProfileDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

RiskProfileDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default React.memo(RiskProfileDialog);
