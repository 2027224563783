import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { fade } from '@material-ui/core/styles/colorManipulator';
import { useTheme } from '@material-ui/core/styles';

import { Group } from '@visx/group';
import { BarRounded } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { withParentSize } from '@visx/responsive';

import CaptionLarge from '../CaptionLarge';
import { getXMax } from '../../../common/utils/chart';

function ChartInstitutionalPositions({ positive, negative, parentWidth }) {
  const theme = useTheme();

  const xMax = getXMax(parentWidth);
  const yMax = 20;

  const vPadding = yMax / 4;
  const barHeight = yMax / 2;

  const total = positive.positions + negative.positions;

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, total],
        range: [0, xMax],
      }),
    [total, xMax],
  );

  const formatValue = (v) => {
    return v.toLocaleString();
  };

  return (
    <Grid container direction={'column'} spacing={1}>
      <Grid item>
        <Grid container justify={'space-between'}>
          <Grid item>
            <CaptionLarge>{startCase(positive.label)}</CaptionLarge>
          </Grid>

          <Grid item>
            <CaptionLarge>{startCase(negative.label)}</CaptionLarge>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <svg width={xMax} height={yMax}>
          <BarRounded
            all
            radius={theme.shape.borderRadius}
            x={xScale(total / 2) - 1}
            y={0}
            width={2}
            height={yMax}
            fill={fade(theme.palette.text.primary, 0.2)}
          />
          <Group>
            <BarRounded
              left
              radius={theme.shape.borderRadius}
              x={0}
              y={vPadding}
              width={xScale(positive.positions)}
              height={barHeight}
              fill={
                positive.positions >= negative.positions
                  ? theme.palette.success.main
                  : fade(theme.palette.success.main, 0.75)
              }
            />
            <BarRounded
              right
              radius={theme.shape.borderRadius}
              x={xScale(positive.positions)}
              y={vPadding}
              width={xScale(negative.positions)}
              height={barHeight}
              fill={
                positive.positions < negative.positions
                  ? theme.palette.error.main
                  : fade(theme.palette.error.main, 0.75)
              }
            />
          </Group>
          <BarRounded
            all
            radius={theme.shape.borderRadius}
            x={xScale(positive.positions) - 2}
            y={0}
            width={4}
            height={yMax}
            fill={fade(theme.palette.text.primary, 1)}
          />
        </svg>
      </Grid>
      <Grid item>
        <Grid container justify={'space-between'}>
          <Grid item>
            <Typography>{formatValue(positive.positions)}</Typography>
          </Grid>
          <Grid item>
            <Typography>{formatValue(negative.positions)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ChartInstitutionalPositions.propTypes = {
  parentWidth: PropTypes.number,
  positive: PropTypes.shape({
    label: PropTypes.string,
    positions: PropTypes.number,
  }).isRequired,
  negative: PropTypes.shape({
    label: PropTypes.string,
    positions: PropTypes.number,
  }).isRequired,
};

ChartInstitutionalPositions.defaulrProps = {};

export default React.memo(withParentSize(ChartInstitutionalPositions));
