import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

function SectionHeading({ children }) {
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Grid container direction="row" spacing={3} alignItems={'center'}>
        <Grid item>
          <Typography variant={'h5'} className={classes.sectionHeading}>
            {children}
          </Typography>
        </Grid>
        <Grid item xs>
          <Box height={'1px'} width={'100%'} bgcolor="divider" />
        </Grid>
      </Grid>
    </Box>
  );
}

SectionHeading.propTypes = {
  children: PropTypes.string.isRequired,
};

export default React.memo(SectionHeading);
