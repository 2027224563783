import React from 'react';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { withParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleUtc } from '@visx/scale';
import { GridRows } from '@visx/grid';
import { LinePath, Line, Bar, AreaClosed } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';

import GlyphEstimate from './GlyphEstimate';
import GlyphCurrent from './GlyphCurrent';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PatternLines } from '@visx/pattern';

import { relDiff, numRound } from '../../utils/helpers';

const useStyles = makeStyles(() => ({}));

function ChartPriceForecast({ prices, estimates, parentWidth }) {
  const classes = useStyles();
  const theme = useTheme();

  // const { prices, estimates } = data;
  const { high, avg, low } = estimates;

  const lastDataPoint = prices[prices.length - 1];
  const firstDataPoint = prices[0];

  const lineColor =
    firstDataPoint.price > lastDataPoint.price
      ? theme.palette.error.main
      : theme.palette.success.main;

  const x = (d) => d.date;
  const y = (d) => d.price;

  const parentHeight = 300;
  const margin = {
    top: 20,
    bottom: 40,
    left: 40,
    right: 0,
  };

  const wMax = parentWidth - margin.left - margin.right;
  const hMax = parentHeight - margin.top - margin.bottom;

  const midPosition = wMax / 2;
  const almostEndPosition = wMax - 10;

  const tickLabelColor = theme.palette.text.secondary;
  const tickFontSize = 12;
  const tickLabelProps = {
    fill: tickLabelColor,
    fontSize: tickFontSize,
  };

  const gridColor = theme.palette.divider;

  const datesTickLabelProps = () => ({
    ...tickLabelProps,
    textAnchor: 'middle',
  });

  const pricesTickLabelProps = () => ({
    ...tickLabelProps,
    textAnchor: 'end',
  });

  const datesScale = scaleBand({
    domain: ['Last', 'Next'],
    range: [0, wMax],
    round: true,
    padding: 0,
    nice: true,
  });

  const pricesDomainMin = Math.min(
    ...prices.map((d) => Math.min(d.price)),
    high,
    avg,
    low,
  );
  const pricesDomainMax = Math.max(
    ...prices.map((d) => Math.max(d.price)),
    high,
    avg,
    low,
    0,
  );

  const currentPrice = y(lastDataPoint);

  const pDelta = Math.max(
    pricesDomainMax - currentPrice,
    currentPrice - pricesDomainMin,
  );

  const minPrice = currentPrice - pDelta > 0 ? currentPrice - pDelta : 0;

  const pricesScale = scaleLinear({
    domain: [minPrice, currentPrice + pDelta],
    range: [hMax, 0],
    nice: true,
  });

  const datesArray = prices.map(x);
  const domainDatesMin = Math.min(...datesArray);
  const domainDatesMax = Math.max(...datesArray);

  const xScale = scaleUtc({
    domain: [domainDatesMin, domainDatesMax],
    range: [0, midPosition],
  });

  return (
    <div className={classes.root}>
      <svg width={parentWidth} height={parentHeight}>
        <PatternLines
          id="lines3"
          height={16}
          width={16}
          stroke={theme.palette.divider}
          strokeWidth={1}
          orientation={['diagonalRightToLeft']}
        />
        <LinearGradient
          id="fill"
          from={lineColor}
          to={lineColor}
          fromOpacity={0.2}
          toOpacity={0}
        />
        <Group left={margin.left} top={margin.top}>
          <Bar
            x={midPosition}
            y={0}
            fill={`url('#lines3')`}
            width={midPosition}
            height={hMax}
          />
          <Group>
            <AxisBottom
              hideAxisLine
              hideTicks
              top={hMax}
              scale={datesScale}
              numTicks={4}
              tickFormat={(v) => `${v} 12 months`}
              tickLabelProps={datesTickLabelProps}
            />
          </Group>
          <Group>
            <AxisLeft
              hideAxisLine
              hideTicks
              scale={pricesScale}
              tickFormat={(v) => `${v}`}
              tickLabelProps={pricesTickLabelProps}
              numTicks={8}
            />
            <GridRows
              key={`amounts-gridrows`}
              scale={pricesScale}
              stroke={gridColor}
              width={wMax}
              numTicks={8}
            />
          </Group>
          <Line
            from={{
              x: 0,
              y: pricesScale(y(lastDataPoint)),
            }}
            to={{
              x: wMax,
              y: pricesScale(y(lastDataPoint)),
            }}
            stroke={fade(theme.palette.text.secondary, 0.2)}
            pointerEvents="none"
          />

          <Group>
            <LinePath
              data={prices}
              x={(d) => xScale(x(d))}
              y={(d) => pricesScale(y(d))}
              strokeWidth={2}
              stroke={lineColor}
            />
            <AreaClosed
              stroke="transparent"
              data={prices}
              yScale={pricesScale}
              x={(d) => xScale(x(d))}
              y={(d) => pricesScale(y(d))}
              fill="url(#fill)"
            />
          </Group>

          <Group>
            <Line
              from={{
                x: xScale(x(lastDataPoint)),
                y: pricesScale(y(lastDataPoint)),
              }}
              to={{
                x: almostEndPosition,
                y: pricesScale(high),
              }}
              stroke={fade(theme.palette.success.main, 0.8)}
              strokeDasharray={3}
              pointerEvents="none"
            />
            <Line
              from={{
                x: xScale(x(lastDataPoint)),
                y: pricesScale(y(lastDataPoint)),
              }}
              to={{
                x: almostEndPosition,
                y: pricesScale(avg),
              }}
              stroke={fade(theme.palette.text.primary, 0.8)}
              strokeDasharray={3}
              pointerEvents="none"
            />
            <Line
              from={{
                x: xScale(x(lastDataPoint)),
                y: pricesScale(y(lastDataPoint)),
              }}
              to={{
                x: almostEndPosition,
                y: pricesScale(low),
              }}
              stroke={fade(theme.palette.error.main, 0.8)}
              strokeDasharray={3}
              pointerEvents="none"
            />
          </Group>
          <GlyphCurrent
            left={xScale(x(lastDataPoint))}
            top={pricesScale(y(lastDataPoint))}
            value={numRound(lastDataPoint.price)}
          />
          <Group left={almostEndPosition}>
            <GlyphEstimate
              top={pricesScale(low)}
              value={numRound(low)}
              color={theme.palette.error.main}
            />
            <GlyphEstimate
              top={pricesScale(avg)}
              value={numRound(avg)}
              color={theme.palette.text.primary}
            />
            <GlyphEstimate
              top={pricesScale(high)}
              value={numRound(high)}
              color={theme.palette.success.main}
            />
          </Group>
        </Group>
      </svg>
      <Grid container spacing={1} direction={'row'}>
        <Grid item xs>
          <Box
            height={65}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(255,255,255, 0.02)"
          >
            <Typography variant="caption">LOW</Typography>
            <Typography
              variant="subtitle2"
              style={{
                color:
                  relDiff(low, currentPrice) > 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            >
              {relDiff(low, currentPrice)} %
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            height={65}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(255,255,255, 0.02)"
          >
            <Typography variant="caption">AVERAGE</Typography>
            <Typography
              variant="subtitle2"
              style={{
                color:
                  relDiff(avg, currentPrice) > 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            >
              {relDiff(avg, currentPrice)} %
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            height={65}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(255,255,255, 0.02)"
          >
            <Typography variant="caption">HIGH</Typography>
            <Typography
              variant="subtitle2"
              style={{
                color:
                  relDiff(high, currentPrice) > 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            >
              {relDiff(high, currentPrice)} %
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

ChartPriceForecast.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      date: PropTypes.any,
    }),
  ).isRequired,
  estimates: PropTypes.shape({
    high: PropTypes.number,
    avg: PropTypes.number,
    low: PropTypes.number,
  }).isRequired,
  parentWidth: PropTypes.number.isRequired,
};

export default React.memo(withParentSize(ChartPriceForecast));
