import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';

import { Asset } from '../../store';
import { numRound } from '../../utils/helpers';

import {
  CagrStdev,
  Alpha,
  Sharpe,
  Sortino,
  UpsideDownside,
  Message,
} from '../Indicator';
import InputDollar from '../InputDollar';
import InputPercent from '../InputPercent';

import { styled } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const Ticker = styled(Typography)({
  textTransform: 'uppercase',
  letterSpacing: 1,
  lineHeight: 1.25,
  cursor: 'pointer',
  touchAction: 'auto',
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    userSelect: 'none',
    background:
      theme.palette.type === 'light'
        ? theme.palette.background.default //darken(theme.palette.background.paper, 0.05)
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0.5),
  },
  input: {
    width: '100%',
  },
  cash: {
    background: 'transparent',
  },
}));

function PortfolioAssetRow({
  asset,
  loading,
  nomove,
  error,
  metricsReady,
  action,
  onRemove,
  onChange,
  onAssetDetailOpen,
  units,
  highlight,
  ...rest
}) {
  const classes = useStyles();
  const [warnDialog, setWarnDialog] = useState(false);

  const handleInputChange = (event) => {
    onChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (units === 'amount') return;

    if (asset.allocation < 0) {
      onChange(0);
    } else if (asset.allocation > 100) {
      onChange(100);
    }
  };

  const handleWarnDialogClose = () => {
    setWarnDialog(false);
  };

  const handleWarnDialogOpen = () => {
    setWarnDialog(true);
  };

  const renderMetrics = () => {
    const {
      expectedReturn,
      stdDev,
      sharpe,
      sortino,
      upsideCapture,
      downsideCapture,
      alpha,
    } = asset.metrics;

    if (asset && asset.isCash) {
      return (
        <Grid container spacing={1} justify="center">
          <Grid item xs>
            <Message>
              <Typography variant={'caption'}>SPDR Bloomberg</Typography>
              <Typography variant={'caption'}>
                Barclays 1-3 Month T-Bill ETF
              </Typography>
            </Message>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={1} wrap="nowrap">
        <Grid item>
          <UpsideDownside
            upsideCapture={upsideCapture}
            downsideCapture={downsideCapture}
            ready={metricsReady}
          />
        </Grid>
        <Grid item>
          <CagrStdev
            cagr={expectedReturn}
            stdDev={stdDev}
            ready={metricsReady}
          />
        </Grid>
        <Grid item>
          <Alpha alpha={alpha} ready={metricsReady} />
        </Grid>
        <Grid item>
          <Sharpe sharpe={sharpe} ready={metricsReady} />
        </Grid>
        <Grid item>
          <Sortino sortino={sortino} ready={metricsReady} />
        </Grid>
      </Grid>
    );
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={warnDialog}
        onClose={handleWarnDialogClose}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle>About security data</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Alert severity="error">
              {asset.isSupported
                ? `Data is not available for ${asset.symbol}`
                : 'Symbol is not supported'}
            </Alert>
          </Box>
          <DialogContentText>
            {asset.isSupported
              ? `Our data providers do not provide data for ${asset.symbol}.`
              : 'Data is restricted to U.S.-based stocks and funds only.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarnDialogClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Box width={270}>
        <LinearProgress />
      </Box>
    );
  };

  const renderError = () => {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs>
            <Message error>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant={'caption'}>
                    {asset.isSupported
                      ? `Data is not available for ${asset.symbol}`
                      : 'Symbol is not supported'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={'About Error'} arrow placement="top">
                    <IconButton
                      onClick={handleWarnDialogOpen}
                      aria-label="view error"
                      size="small"
                      color="inherit"
                    >
                      <HelpTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Message>
          </Grid>
        </Grid>
        {renderDialog()}
      </div>
    );
  };

  return (
    <ListItem
      className={classNames(classes.root, {
        [classes.cash]: asset.isCash,
      })}
      {...rest}
    >
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        spacing={2}
        wrap="nowrap"
      >
        <Grid xs item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            wrap="nowrap"
          >
            <Grid item>
              <Tooltip title={asset.name || false} placement="top-start" arrow>
                <Ticker
                  onClick={(e) =>
                    !loading && onAssetDetailOpen && onAssetDetailOpen(e, asset)
                  }
                  color={highlight ? 'primary' : 'textPrimary'}
                >
                  {asset.label}
                </Ticker>
              </Tooltip>
            </Grid>
            <Grid item>{action && action}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          {onChange ? (
            <Tooltip
              title={units === 'amount' ? `${asset.allocation} %` : ''}
              placement="top"
              arrow
            >
              {asset && !loading ? (
                <FormControl>
                  {units === 'amount' ? (
                    <InputDollar
                      value={asset.amount}
                      name={`asset-amount-i-${asset.id}`}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      disabled={!onChange}
                    />
                  ) : (
                    <InputPercent
                      value={asset.allocation}
                      name={`asset-allocation-i-${asset.id}`}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      disabled={!onChange}
                    />
                  )}
                </FormControl>
              ) : (
                <Box />
              )}
            </Tooltip>
          ) : (
            <Box
              width={
                units === 'amount' && !action
                  ? 120
                  : units === 'amount' && action
                  ? 120
                  : 90
              }
              pr={1}
            >
              <Typography variant={'body1'} align={'right'}>
                {units === 'amount'
                  ? `$ \u00A0${asset.amount.toLocaleString()}`
                  : `${numRound(asset.allocation)} %`}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          item
          style={{
            cursor: asset.isCash || nomove ? '' : 'move',
            touchAction: 'none',
          }}
          className={asset.isCash ? '' : 'dnd-handle'}
        >
          {error ? renderError() : loading ? renderLoading() : renderMetrics()}
        </Grid>
        <Grid item>
          <Box
            width={40}
            display={'flex'}
            justifyContent="center"
            color={'text.secondary'}
          >
            {onRemove && !asset.isCash && (
              <Tooltip title={'Remove Asset'} placement="top" arrow>
                <IconButton onClick={onRemove} tabIndex="-1" color="inherit">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

PortfolioAssetRow.propTypes = {
  asset: PropTypes.instanceOf(Asset),
  action: PropTypes.node,
  loading: PropTypes.bool,
  nomove: PropTypes.bool,
  error: PropTypes.bool,
  metricsReady: PropTypes.bool,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onAssetDetailOpen: PropTypes.func,
  units: PropTypes.oneOf(['percentage', 'amount']),
  highlight: PropTypes.bool,
};

PortfolioAssetRow.defaultProps = {
  metricsReady: true,
  loading: false,
  error: false,
  selected: false,
  nomove: false,
  units: 'percentage',
  highlight: false,
};

export default observer(PortfolioAssetRow);
