import React from 'react';
import { Spring } from 'react-spring/renderprops.cjs';

function EnhancedSpring(props) {
  if (props.animate) {
    return <Spring {...props}>{props.children}</Spring>;
  }

  return props.children(props.to);
}

export default React.memo(EnhancedSpring);
