import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { CagrStdev, Alpha, UpsideDownside } from '../Indicator';

function OptimizationMetrics({
  optimizationIsReady,
  optimizationIsLoading,
  expectedReturn,
  stdDev,
  alpha,
  upsideCapture,
  downsideCapture,
}) {
  return (
    <Grid container spacing={1} justify="center">
      <Grid item>
        <UpsideDownside
          upsideCapture={upsideCapture}
          downsideCapture={downsideCapture}
          ready={optimizationIsReady && !optimizationIsLoading}
        />
      </Grid>
      <Grid item>
        <CagrStdev
          cagr={expectedReturn}
          stdDev={stdDev}
          ready={optimizationIsReady && !optimizationIsLoading}
        />
      </Grid>
      <Grid item>
        <Alpha
          alpha={alpha}
          ready={optimizationIsReady && !optimizationIsLoading}
        />
      </Grid>
    </Grid>
  );
}

OptimizationMetrics.propTypes = {
  optimizationIsReady: PropTypes.bool,
  optimizationIsLoading: PropTypes.bool,
  expectedReturn: PropTypes.any,
  stdDev: PropTypes.any,
  alpha: PropTypes.any,
  upsideCapture: PropTypes.any,
  downsideCapture: PropTypes.any,
};

OptimizationMetrics.defaultProps = {};

export default React.memo(OptimizationMetrics);
