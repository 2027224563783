import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Tooltip } from '@material-ui/core';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import DialogTransition from '../DialogTransition';

import { dark } from '../../../common/theme';
import { useStore } from '../../store';
import PortfolioPreview from '../PortfolioPreview';
import InputPercent from '../InputPercent';

const darkTheme = createMuiTheme(dark);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  assetItem: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
  },
  placeholder: {
    width: 552,
    height: 292,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function MergeDialog({ open, onClose }) {
  const classes = useStyles();
  const store = useStore();
  const { profile } = store;
  const { mixer } = profile;
  const [removeSource, setRemoveSource] = React.useState(false);

  const handleMerge = async () => {
    onClose();
    store.setBackdrop(true);
    await profile.importFromMixer({ removeSource });
    store.setBackdrop(false);
  };
  const handleToggle = (p) => () => {
    mixer.togglePortfolio(p);
  };
  const handleWeightChange = (p) => (e) => {
    mixer.setAllocation(p, e.target.value);
  };
  const handleRemoveSourceToggle = () => {
    setRemoveSource(!removeSource);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      TransitionComponent={DialogTransition}
    >
      <DialogTitle>Merge Portfolios</DialogTitle>
      <DialogContent style={{ height: '80vh !important' }}>
        <Grid container direction="column" spacing={2} wrap={'nowrap'}>
          <Grid item xs>
            <List disablePadding component="nav" aria-label="portfolio list">
              {profile.sortedPortfolios.map((p) => {
                const labelId = `checkbox-list-label-${p.id}`;
                return (
                  <ListItem
                    key={p.id}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(p)}
                    tabIndex={-1}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={mixer.portfoliosIds.indexOf(p.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                      />
                    </ListItemIcon>
                    <ListItemText primary={p.name} />
                    <ListItemSecondaryAction>
                      <InputPercent
                        value={mixer.weights[p.id] || 0}
                        onChange={handleWeightChange(p)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item align="center">
            {mixer.ready ? (
              <ThemeProvider theme={darkTheme}>
                <PortfolioPreview
                  portfolio={mixer}
                  showZeroes
                  animate
                  fixed={false}
                />
              </ThemeProvider>
            ) : (
              <div className={classes.placeholder}>
                {!mixer.correctSelection ? (
                  <Alert severity="info">
                    Select two or more portfolios to merge
                  </Alert>
                ) : (
                  <Alert severity="warning">
                    Allocations should add up to 100%
                  </Alert>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip
          title="Remove source portfolios after merge"
          placement="top"
          arrow
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={removeSource}
                onChange={handleRemoveSourceToggle}
                color="primary"
              />
            }
            label="Remove source"
          />
        </Tooltip>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleMerge} color="primary" disabled={!mixer.ready}>
          Merge
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MergeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

MergeDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default observer(MergeDialog);
