import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeading from '../SectionHeading';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('lg')]: {
      columnCount: 2,
      columnGap: 56,
      columnFill: 'auto',
    },
  },
}));

function AboutCompany({ name, description }) {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant={'h2'} gutterBottom>
        {name}
      </Typography>
      <SectionHeading>About company</SectionHeading>
      <Typography
        variant={'body2'}
        color={'textSecondary'}
        className={classes.content}
      >
        {description}
      </Typography>
    </Box>
  );
}

AboutCompany.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

AboutCompany.defaultProps = {};

export default React.memo(AboutCompany);
