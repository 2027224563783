import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { Portfolio } from '../../store';
import { numRound } from '../../utils/helpers';

import ChartPortfolioComposition from '../ChartPortfolioComposition';
import OptimizationForm from './OptimizationForm';
import OptimizationDescription from './OptimizationDescription';
import OptimizationMetrics from './OptimizationMetrics';
import Caption from '../Caption';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  progress: {
    color: theme.palette.text.primary,
  },
  caption: {
    width: 56,
  },
}));

function OptimizationControls({ portfolio }) {
  const classes = useStyles();
  const {
    optimization,
    targetVolatility,
    optimizationIsLoading,
    isOptimized,
    optimizationIsReady,
    optimizationError,
    minVolatility,
    optimizedMetrics,
  } = portfolio;

  const valueError = targetVolatility < minVolatility;

  const handleChange = (event) => {
    portfolio.setOptimization(event.target.value);
  };

  const handleVolChange = (event) => {
    portfolio.setTargetVolatility(event.target.value);
  };

  const handleOptimize = () => {
    if (valueError) return;

    portfolio.optimize();
  };

  const handleApply = () => {
    portfolio.applyOptimization();
  };

  const handleDismiss = () => {
    portfolio.invalidateOptimization();
  };

  const renderResult = () => {
    return (
      <Grid container direction="column" spacing={2} wrap={'nowrap'}>
        <Grid item>
          <Grid container spacing={1} justify="center">
            <Grid item className={classes.caption}>
              <Caption align="center">U/D</Caption>
            </Grid>
            <Grid item className={classes.caption}>
              <Caption align="center">CAGR/SD</Caption>
            </Grid>
            <Grid item className={classes.caption}>
              <Caption align="center">Alpha</Caption>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <OptimizationMetrics
            optimizationIsReady={optimizationIsReady}
            optimizationIsLoading={optimizationIsLoading}
            expectedReturn={optimizedMetrics.expectedReturn}
            stdDev={optimizedMetrics.stdDev}
            alpha={optimizedMetrics.alpha}
            upsideCapture={optimizedMetrics.upsideCapture}
            downsideCapture={optimizedMetrics.downsideCapture}
          />
        </Grid>
        <Grid item xs align="center">
          {!optimizationIsLoading &&
            portfolio.sortedByOptAllocationAssets.map((asset) => {
              return (
                asset.optimizedAllocation > 0 && (
                  <Box
                    key={asset.id}
                    display={'flex'}
                    justifyContent={'space-between'}
                    pr={2}
                    pl={2}
                  >
                    <Typography
                      variant={'caption'}
                      component={'span'}
                      color={asset.isLocked ? 'secondary' : 'inherit'}
                    >
                      {asset.label}:
                    </Typography>
                    <Typography
                      variant={'caption'}
                      component={'span'}
                      color={asset.isLocked ? 'secondary' : 'inherit'}
                    >
                      {numRound(asset.optimizedAllocation)}%
                    </Typography>
                  </Box>
                )
              );
            })}
        </Grid>
        <Grid item>
          <Grid container justify="space-evenly">
            <Grid item>
              <Button
                onClick={handleApply}
                disabled={isOptimized || optimizationIsLoading}
                variant="outlined"
                fullWidth
              >
                Apply
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleDismiss} variant="text" fullWidth>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container direction="row" spacing={3} alignItems="stretch">
        <Grid item>
          <OptimizationForm
            optimization={optimization}
            targetVolatility={targetVolatility}
            minVolatility={minVolatility}
            optimizationIsLoading={optimizationIsLoading}
            optimizationError={optimizationError}
            valueError={valueError}
            onChange={(e) => handleChange(e)}
            onVolChange={(e) => handleVolChange(e)}
            onOptimize={() => handleOptimize()}
          />
        </Grid>
        <Grid item xs hidden={!optimizationIsReady && !optimizationIsLoading}>
          <Box
            display={'flex'}
            position={'relative'}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
          >
            {portfolio && optimizationIsReady && !optimizationIsLoading && (
              <ChartPortfolioComposition
                assets={portfolio.sortedByOptAllocationAssets}
              />
            )}
            {optimizationIsLoading && (
              <CircularProgress size={36} className={classes.progress} />
            )}
          </Box>
        </Grid>
        <Grid item xs>
          {optimizationIsReady || optimizationIsLoading ? (
            renderResult()
          ) : optimizationError && !valueError ? (
            <Box>
              <Alert severity="error">
                No asset allocation is possible with the current constraints.
              </Alert>
            </Box>
          ) : (
            <OptimizationDescription />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

OptimizationControls.propTypes = {
  portfolio: PropTypes.instanceOf(Portfolio),
};

OptimizationControls.defaultProps = {};

export default observer(OptimizationControls);
