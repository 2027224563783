import React from 'react';
import PropTypes from 'prop-types';

import Indicator from './Indicator';

function UpsideDownside({
  upsideCapture,
  downsideCapture,
  neutral,
  ready,
  large,
  showCaptions,
}) {
  const caption = 'U/D';

  if ((isNaN(upsideCapture) && isNaN(downsideCapture)) || !ready) {
    return (
      <Indicator
        val1={'--'}
        val2={'--'}
        tint={'default'}
        large={large}
        caption={caption}
        showCaptions={showCaptions}
      />
    );
  }
  const valueFormat = (v) => Number(v * 100).toFixed(0);

  const upsideValue = valueFormat(upsideCapture);
  const downsideValue = valueFormat(downsideCapture);

  const downsideForFormula =
    downsideValue <= 0 ? 1 : valueFormat(downsideCapture);

  const ratio = upsideValue / downsideForFormula;

  const getTint = () => {
    if (neutral) return 'default';
    if (ratio >= 1 && ratio < 1.25) return 'yellow';
    if (ratio >= 1.25) return 'green';
    return 'red';
  };

  return (
    <Indicator
      val1={upsideValue}
      val2={downsideValue}
      highlight={!neutral}
      tint={getTint()}
      large={large}
      caption={caption}
      showCaptions={showCaptions}
    />
  );
}

UpsideDownside.propTypes = {
  upsideCapture: PropTypes.number,
  downsideCapture: PropTypes.number,
  neutral: PropTypes.bool,
  large: PropTypes.bool,
  ready: PropTypes.bool,
  showCaptions: PropTypes.bool,
  onClick: PropTypes.func,
};

UpsideDownside.defaultProps = {
  neutral: false,
  large: false,
  showCaptions: false,
};

export default React.memo(UpsideDownside);
