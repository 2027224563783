import React from 'react';
import PropTypes from 'prop-types';
import { AxisLeft } from '@visx/axis';

import { useTheme } from '@material-ui/core/styles';

function ChartYAxis({ scale, ...rest }) {
  const theme = useTheme();

  const tickLabelProps = () => ({
    fill: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    textAnchor: 'end',
    dy: '0.25em',
  });

  return (
    <AxisLeft
      hideAxisLine
      hideTicks
      scale={scale}
      tickLabelProps={tickLabelProps}
      {...rest}
    />
  );
}

ChartYAxis.propTypes = {
  scale: PropTypes.any.isRequired,
};

export default React.memo(ChartYAxis);
