import { flow, observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { isServer } from '../utils/env';

class User {
  _cache = null;
  _options = null;
  _init = false;
  @observable signedIn = false;
  @observable uid = null;
  @observable email = null;
  @observable displayName = null;
  @observable photoURL = null;
  @observable isAnon = false;

  constructor(options) {
    this._options = options;
    this._cache = options.cache;

    if (this._cache && this._cache.has('_user')) {
      const user = this._cache.get('_user');
      this.fromFirebaseUser(user);
    }

    if (!isServer()) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const { pathname } = options.store.router.location;
          if (!this._init && pathname === '/login') {
            // Sign out user on client with stale server session token
            firebase.auth().signOut();
          } else {
            this.fromFirebaseUser(user);
            this.signedIn = true;
          }
        } else {
          // redirect if session expired while client was active
          if (this.signedIn) {
            await firebase.auth().signOut();
            await axios.get(`/sessionLogout`);
            window.location.assign('/login');
          }

          this.signedIn = false;
        }
        this._init = true;
      });
    }
  }

  async ready() {
    if (isServer() && this._cache) {
      this._cache.set('_user', {
        signedIn: this.signedIn,
        uid: this.uid,
        email: this.email,
        displayName: this.displayName,
      });
    }
  }

  fromFirebaseUser(user) {
    if (typeof user.signedIn !== 'undefined') {
      this.signedIn = user.signedIn;
    }
    this.uid = user.uid;
    this.displayName = user.displayName;
    this.email = user.email;
    this.isAnon = user.isAnonymous;
  }

  login = flow(function* ({ redirect = true, anon = false } = {}) {
    try {
      let result;
      if (!anon) {
        const provider = new firebase.auth.GoogleAuthProvider();
        result = yield firebase.auth().signInWithPopup(provider);
      } else {
        result = yield firebase.auth().signInAnonymously();
      }

      const user = result.user;
      const idToken = yield user.getIdToken();
      yield axios.post(`/sessionLogin`, { idToken });

      if (redirect) {
        window.location.assign('/');
      } else {
        this.fromFirebaseUser(user);
        this.signedIn = true;
        this._options.store.router.history.push('/');
      }
    } catch (error) {
      console.log('Login error:', error);
    }
  });

  migrate = flow(function* () {
    const auth = firebase.auth();
    const anonUser = auth.currentUser;
    if (!anonUser.isAnonymous) {
      throw new Error('Current user is not anonymous');
    }
    const profile = this._options.store.profile;
    const portfolios = profile.sortedPortfolios;

    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = yield auth.signInWithPopup(provider);
      const user = result.user;
      const idToken = yield user.getIdToken();
      yield axios.post(`/sessionLogin`, { idToken });

      yield profile.invalidateCache();
      // yield profile.delete();
      const empty = profile.portfolios.length === 0;

      yield Promise.all(
        portfolios.map(async (p) => {
          if (p.hasAssets) {
            await profile.addPortfolio({
              source: p,
              name: p.name,
              tag: empty ? p.tag : null,
            });
          }
        }),
      );

      this.fromFirebaseUser(user);
      this.signedIn = true;

      // yield anonUser.delete();
    } catch (err) {
      console.log(err);
    }
  });

  logout = flow(function* () {
    try {
      if (this.isAnon) {
        const profile = this._options.store.profile;
        yield profile.delete();
      }
      yield firebase.auth().signOut();
      yield axios.get(`/sessionLogout`);
      window.location.assign('/login');
    } catch (error) {
      console.log('Logout error:', error);
    }
  });
}

export default User;
