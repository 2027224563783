import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  description: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

function OptimizationDescription() {
  const classes = useStyles();

  return (
    <div className={classes.description}>
      <Typography variant={'subtitle1'} className={classes.title} gutterBottom>
        Apply Asset Allocation modeling to find the optimal risk adjusted
        portfolio that lies on the efficient frontier
      </Typography>
      <Typography variant={'caption'} component={'p'} color="textSecondary">
        The actual performance of portfolios constructed using the optimized
        asset weights may vary from the given performance goal.
      </Typography>
    </div>
  );
}

OptimizationDescription.propTypes = {};

OptimizationDescription.defaultProps = {};

export default React.memo(OptimizationDescription);
