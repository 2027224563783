import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Grid from '@material-ui/core/Grid';

import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    height: 64,
  },
  icon: {
    fontSize: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}));

function DrawerMenuItem({ icon, text, onClick, highlight }) {
  const classes = useStyles();

  return (
    <ListItem button className={classes.root} onClick={onClick} tabIndex="-1">
      <Grid container direction={'row'} alignItems={'center'} spacing={2}>
        <Grid item>
          <Box
            className={classNames(classes.icon, {
              [classes.highlight]: highlight,
            })}
          >
            {icon}
          </Box>
        </Grid>
        <Grid item>{text}</Grid>
      </Grid>
    </ListItem>
  );
}

DrawerMenuItem.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  highlight: PropTypes.bool,
};

export default React.memo(DrawerMenuItem);
