import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Asset } from '../../store';

import ChartRevenueEarnings from '../ChartRevenueEarnings';
import ChartEsimatedActual from '../ChartEstimatedActual';
import ChartAnalystRating from '../ChartAnalystRating';
import ChartPriceForecast from '../ChartPriceForecast';
import ChartInstitutionalOwnership from '../ChartInstitutionalOwnership';
import ChartInstitutionalPositions from '../ChartInstitutionalPositions';
// import PerformanceMetrics from '../PerformanceMetrics';

import SectionHeading from '../SectionHeading';
import AboutCompany from '../AboutCompany';

const useStyles = makeStyles((theme) => ({
  sectionBox: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
  },
  about: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },
}));

function StockDetail({ asset }) {
  const classes = useStyles();

  const {
    security,
    revenueEarningsChartData,
    estimateActualChartData,
    analystRatingChartData,
    priceForecastChartData,
    institutionalChartData,
    metrics,
    description,
  } = asset;

  const headingVariant = 'subtitle2';

  return (
    <Grid container spacing={10} direction={'column'}>
      <Grid item>
        <SectionHeading>Financial Metrics</SectionHeading>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box className={classes.sectionBox}>
              <Typography
                variant={headingVariant}
                className={classes.blockHeading}
                gutterBottom
              >
                Revenue / Earnings / Free Cash Flow
              </Typography>
              <ChartRevenueEarnings data={revenueEarningsChartData} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className={classes.sectionBox}>
              <Typography
                variant={headingVariant}
                className={classes.blockHeading}
                gutterBottom
              >
                Estimated to Actual Earnings
              </Typography>
              <ChartEsimatedActual data={estimateActualChartData} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SectionHeading>Analyst Recommendations</SectionHeading>
        <Grid container direction="row" spacing={3} alignItems="stretch">
          <Grid item xs={12} lg={4}>
            <Box className={classes.sectionBox}>
              <Typography
                variant={headingVariant}
                className={classes.blockHeading}
                gutterBottom
              >
                Analyst Rating Consensus
              </Typography>
              <ChartAnalystRating
                data={analystRatingChartData}
                company={security.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box className={classes.sectionBox}>
              <Typography
                variant={headingVariant}
                className={classes.blockHeading}
                gutterBottom
              >
                Stock Price Forecast
              </Typography>
              <ChartPriceForecast
                prices={priceForecastChartData.prices}
                estimates={priceForecastChartData.estimates}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {institutionalChartData && (
        <Grid item>
          <SectionHeading>Institutional Holdings</SectionHeading>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} lg={4}>
              <Box className={classes.sectionBox}>
                {institutionalChartData && (
                  <ChartInstitutionalOwnership
                    percentage={institutionalChartData.ownershipPercentage}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className={classes.sectionBox}>
                {institutionalChartData.increasedPositions &&
                  institutionalChartData.decreasedPositions && (
                    <ChartInstitutionalPositions
                      positive={{
                        label: 'increasedPositions',
                        positions: institutionalChartData.increasedPositions,
                      }}
                      negative={{
                        label: 'decreasedPositions',
                        positions: institutionalChartData.decreasedPositions,
                      }}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className={classes.sectionBox}>
                {institutionalChartData.newPositions &&
                  institutionalChartData.soldOutPositions && (
                    <ChartInstitutionalPositions
                      positive={{
                        label: 'enteringPositions',
                        positions: institutionalChartData.newPositions,
                      }}
                      negative={{
                        label: 'exitingPositions',
                        positions: institutionalChartData.soldOutPositions,
                      }}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* <Grid item>
        <SectionHeading>Performance Metrics</SectionHeading>
        <PerformanceMetrics metrics={metrics} />
      </Grid> */}
      <Grid item>
        <Box className={classes.about}>
          <AboutCompany name={security.name} description={description} />
        </Box>
      </Grid>
    </Grid>
  );
}

StockDetail.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
};

export default observer(StockDetail);
