import { computed, observable, action } from 'mobx';
import { computedFn } from 'mobx-utils';
import UniversalDocument from './UniversalDocument';
import moment from 'moment';
import firebase from 'firebase/app';
import { DataFrame } from 'pandas-js';
import { simple_returns } from '../empyrical-js/stats';
import {
  calculateMetrics,
  dataframeComparer,
  momentComparer,
  today,
  parseDate,
} from './helpers';
import { isServer } from '../utils/env';

function dataComparer(a, b) {
  if (!a || !b || !a.updatedAt || !b.updatedAt) return false;

  return (
    a.updatedAt.seconds === b.updatedAt.seconds &&
    a.lastLookup.seconds === b.lastLookup.seconds &&
    a.lock === b.lock &&
    a.error === b.error
  );
}

class Security extends UniversalDocument {
  static _instances = {};
  @observable _symbol = null;
  @observable _constrainer = null;
  @observable _benchmark = null;

  constructor(source, options) {
    super(source, options);

    this._benchmark = options.benchmark;
    this._constrainer = options.constrainer;

    this._symbol = new UniversalDocument(
      () => `symbols/${this.symbol}`,
      options,
    );
  }

  static instance(symbol, options) {
    if (isServer() || !Security._instances[symbol]) {
      Security._instances[symbol] = new Security(
        `securities/${symbol}`,
        options,
      );
    }

    return Security._instances[symbol];
  }

  ready() {
    return Promise.all([super.ready(), this._symbol.ready()]);
  }

  get debugName() {
    return `Security ${this.symbol}`;
  }

  @computed get hasData() {
    return (
      !!this.data.prices &&
      this.data.prices.t5yMonthly &&
      this.data.prices.t5yMonthly.length > 1
    );
  }

  @computed get hasError() {
    return !!this.data.error;
  }

  @computed get symbol() {
    return this.id.toUpperCase();
  }

  @computed get name() {
    return this._symbol.hasData ? this._symbol.data.name : 'N/A';
  }

  @computed get isEtf() {
    if (!this._symbol.hasData) return false;

    const { type } = this._symbol.data;
    return type === 'et' || type === 'oef' || type === 'cef';
  }

  @computed({ equals: dataframeComparer }) get prices() {
    if (!this.t5yMonthly) {
      return null;
    }
    this.log('compute prices');

    const { t5yMonthly } = this;

    const prices = [];
    const index = [];
    for (let i = 0; i < t5yMonthly.length; i++) {
      const p = t5yMonthly[i];
      const date = parseDate(p.date).startOf('month');
      if (i > 0 && date.isSame(index[i - 1])) {
        // use only latest price if month if not complete and have two prices
        index[i - 1] = date;
        prices[i - 1] = { price: Number(p.price) };
      } else {
        index.push(date);
        prices.push({ price: Number(p.price) });
      }
    }

    return new DataFrame(prices, { index });
  }

  @computed({ equals: dataframeComparer }) get returns() {
    if (!this.prices) {
      return this.prices;
    }

    this.log('compute returns');
    return simple_returns(this.prices);
  }

  @computed({ equals: dataComparer }) get data() {
    this.log('compute data');
    return super.data;
  }

  @computed({ equals: dataframeComparer }) get t5yMonthly() {
    const { prices } = this.data;
    if (!prices || !prices.t5yMonthly || prices.t5yMonthly.length < 2) {
      return null;
    }
    this.log('compute t5yMonthly');
    return prices.t5yMonthly;
  }

  @computed({ equals: dataframeComparer }) get ttmMonthly() {
    const { prices } = this.data;
    if (!prices) return null;

    const { t5yMonthly } = prices;
    this.log('compute ttmMonthly');
    return t5yMonthly.length > 13 ? t5yMonthly.slice(-13) : t5yMonthly;
  }

  @computed({ equals: momentComparer }) get since() {
    if (!this.t5yMonthly) {
      return moment.unix(0);
    }
    this.log('compute since');

    return parseDate(this.t5yMonthly[1].date);
  }

  @computed({ equals: momentComparer }) get till() {
    if (!this.t5yMonthly) {
      return today();
    }
    this.log('compute till');

    return parseDate(this.t5yMonthly[this.t5yMonthly.length - 1].date);
  }

  _metrics = computedFn(
    (monthsOffset = 0) => {
      this.log('compute _metrics');

      const since = this._constrainer ? this._constrainer.since : this.since;
      let till = this._constrainer ? this._constrainer.till : this.till;
      const benchmark_returns = this._benchmark
        ? this._benchmark.returns
        : null;

      if (monthsOffset > 0) {
        till = till.clone().subtract(monthsOffset, 'months').endOf('month');
      }

      return calculateMetrics(this.returns, benchmark_returns, since, till);
    },
    { keepAlive: true },
  );

  @computed.struct get metrics() {
    return this._metrics(0);
  }

  @computed get metricsReady() {
    return this.hasData && !!this.returns;
  }

  @computed get updatedAt() {
    return this.hasData && this.data.updatedAt
      ? moment.unix(this.data.updatedAt.seconds)
      : moment.unix(0);
  }

  @computed get lastLookup() {
    return this.hasData && this.data.lastLookup
      ? moment.unix(this.data.lastLookup.seconds)
      : moment.unix(0);
  }

  @action incrementLookup() {
    const increment = firebase.firestore.FieldValue.increment(1);
    return this.set({ lookup: increment }, { merge: true });
  }
}

export default Security;
