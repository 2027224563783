import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import CaptionLarge from '../../components/CaptionLarge';
import RiskProfileLabel from '../../components/RiskProfileLabel';
import TargetUD from '../../components/TargetUD';
import TargetQuadrant from '../../components/TargetQuadrant';

import { useStore } from '../../store';
import { PROFILE_TYPES } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  answerItem: {
    paddingBottom: theme.spacing(1),
  },
  result: {
    textAlign: 'center',
  },
  content: {
    color: theme.palette.text.secondary,
  },
}));

export function RiskProfile({ onClose }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [finished, setFinished] = React.useState(true);
  const { riskProfile } = useStore();
  const { questions } = riskProfile;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep >= questions.length - 1) {
      setFinished(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFinished(false);
    riskProfile.clear();
  };

  const handleChange = (key) => (event) => {
    riskProfile.answer(key, Number(event.target.value));
  };

  const renderTest = () => {
    return (
      <Stepper activeStep={activeStep} orientation="vertical">
        {questions.map((q, index) => (
          <Step key={q.key}>
            <StepLabel>{q.question}</StepLabel>
            <StepContent>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="option"
                  name={`option-${q.key}`}
                  value={q.answer}
                  onChange={handleChange(q.key)}
                >
                  {q.options.map((o, i) => (
                    <FormControlLabel
                      key={`option-${i}`}
                      className={classes.answerItem}
                      value={i}
                      control={<Radio />}
                      label={o}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={q.answer < 0}
                  >
                    {activeStep === questions.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    );
  };

  const renderTargetQuadrantTitles = () => {
    if (riskProfile.resultLabel === PROFILE_TYPES.c) {
      return 'CONSERVATIVE quadrant';
    }
    if (riskProfile.resultLabel === PROFILE_TYPES.cm) {
      return 'CONSERVATIVE or MODERATE quadrants';
    }
    if (riskProfile.resultLabel === PROFILE_TYPES.ma) {
      return 'MODERATE or AGGRESSIVE quadrants';
    }
    if (riskProfile.resultLabel === PROFILE_TYPES.a) {
      return 'AGGRESSIVE quadrant';
    }
    return 'MODERATE quadrant';
  };

  const renderResult = () => {
    return (
      <Box className={classes.resetContainer}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={3}
        >
          <Grid item className={classes.result}>
            <CaptionLarge color={'textSecondary'}>
              Your investor profile is
            </CaptionLarge>
            <RiskProfileLabel
              text={riskProfile.resultLabel}
              score={riskProfile.result}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={3} wrap="nowrap">
              <Grid item>
                <TargetQuadrant resultLabel={riskProfile.resultLabel} />
              </Grid>
              <Grid item>
                <Typography variant={'body2'} className={classes.content}>
                  Based on results of your Risk Assesment, your resulting
                  Portfolio may fall into {renderTargetQuadrantTitles()} of the
                  Radar screen.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            <Grid container direction="row" spacing={3} wrap="nowrap">
              <Grid item>
                <TargetUD resultLabel={riskProfile.resultLabel} />
              </Grid>
              <Grid item>
                <Typography variant={'body2'}>
                  The UPSIDE/DOWNSIDE CAPTURE varies based upon the individual
                  investor’s INVESTMENT POLICY. For example, many investors
                  prefer a targeted ratio of 80/60, which indicates a portfolio
                  that will likely capture 80% of the index UPSIDE and only 60%
                  of its DOWNSIDE.
                </Typography>
                <ul>
                  <li>CONSERVATIVE INVESTOR may target 15/10</li>
                  <li>MODERATE-CONSERVATIVE - 60/40</li>
                  <li>MODERATE - 80/60</li>
                  <li>MODERATELY AGGRESSIVE - 100/80</li>
                  <li>AGGRESSIVE - 125/100</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleReset}
              className={classes.button}
            >
              Retake assessment
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
              className={classes.button}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      {finished && riskProfile.isComplete ? renderResult() : renderTest()}
    </div>
  );
}

RiskProfile.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default observer(RiskProfile);
