import React from 'react';
import PropTypes from 'prop-types';

import { Glyph as CustomGlyph, GlyphCircle } from '@visx/glyph';

import { useTheme } from '@material-ui/core/styles';

function GlyphEstimate({ value, color, top }) {
  const theme = useTheme();

  return (
    <CustomGlyph top={top}>
      <GlyphCircle
        size={200}
        fill={theme.palette.background.paper}
        stroke={color}
        strokeDasharray={3}
      />
      <text
        fontSize={16}
        fontWeight={400}
        textAnchor="end"
        dx={-16}
        dy={6}
        fill={color}
      >
        ${value.toLocaleString()}
      </text>
    </CustomGlyph>
  );
}

GlyphEstimate.propTypes = {
  top: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default React.memo(GlyphEstimate);
