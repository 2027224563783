import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Caption from '../Caption';
import {
  CagrStdev,
  Alpha,
  UpsideDownside,
  Sharpe,
  Sortino,
} from '../Indicator';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  caption: {
    width: 56,
  },
}));

function PortfolioMetrics({
  upsideCapture,
  downsideCapture,
  expectedReturn,
  stdDev,
  alpha,
  sharpe,
  sortino,
  metricsReady,
}) {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2} wrap={'nowrap'}>
      <Grid item>
        <Grid container spacing={1} justify="center">
          <Grid item className={classes.caption}>
            <Caption align="center">U/D</Caption>
          </Grid>
          <Grid item className={classes.caption}>
            <Caption align="center">CAGR/SD</Caption>
          </Grid>
          <Grid item className={classes.caption}>
            <Caption align="center">Alpha</Caption>
          </Grid>
          <Grid item className={classes.caption}>
            <Caption align="center">Sharpe</Caption>
          </Grid>
          <Grid item className={classes.caption}>
            <Caption align="center">Sortino</Caption>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <UpsideDownside
              upsideCapture={upsideCapture}
              downsideCapture={downsideCapture}
              ready={metricsReady}
            />
          </Grid>
          <Grid item>
            <CagrStdev
              cagr={expectedReturn}
              stdDev={stdDev}
              ready={metricsReady}
            />
          </Grid>
          <Grid item>
            <Alpha alpha={alpha} ready={metricsReady} />
          </Grid>
          <Grid item>
            <Sharpe sharpe={sharpe} ready={metricsReady} />
          </Grid>
          <Grid item>
            <Sortino sortino={sortino} ready={metricsReady} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PortfolioMetrics.propTypes = {
  expectedReturn: PropTypes.number,
  stdDev: PropTypes.number,
  alpha: PropTypes.number,
  sharpe: PropTypes.number,
  sortino: PropTypes.number,
  upsideCapture: PropTypes.number,
  downsideCapture: PropTypes.number,
  metricsReady: PropTypes.bool,
};

PortfolioMetrics.defaultProps = {};

export default React.memo(PortfolioMetrics);
