import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';

import DrawerMenuItem from './DrawerMenuItem';

import { SettingsIcon, VideosIcon, LogOutIcon } from '../AppIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(3),
  },
}));

function DrawerMenu({ onGuidesOpen, onSettingsOpen }) {
  const classes = useStyles();

  return (
    <List disablePadding className={classes.root}>
      {/* <DrawerMenuItem
        icon={<VideosIcon />}
        text="User Guides"
        onClick={onGuidesOpen}
      /> */}
      <DrawerMenuItem
        icon={<SettingsIcon />}
        text="Settings"
        onClick={onSettingsOpen}
      />
      {/* <DrawerMenuItem
          icon={<LogOutIcon />}
          text="Log Out"
          onClick={() => console.log(1)}
        /> */}
    </List>
  );
}

DrawerMenu.propTypes = {
  onGuidesOpen: PropTypes.func,
  onSettingsOpen: PropTypes.func,
};

DrawerMenu.defaultProps = {};

export default React.memo(DrawerMenu);
