import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';

import { Portfolio, Mixer } from '../../store';
import { numRound } from '../../utils/helpers';
import {
  CagrStdev,
  Alpha,
  UpsideDownside,
  Sharpe,
  Sortino,
} from '../Indicator';
import ChartPortfolioComposition from '../ChartPortfolioComposition';
import Caption from '../Caption';
import LogoIcon from '../Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  preview: {
    width: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    borderRadius: 4,
  },
  fixed: {
    height: 300,
  },
  scaled: {
    transformOrigin: 'center',
    transform: 'scale(3.333333333333333) translate(0%, 33%)',
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoContainer: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
    height: 34,
    width: 45,
  },
  assetsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  gridList: {
    width: '100%',
  },
  shortAssetsList: {
    width: 180,
  },
  longAssetsList: {
    width: 300,
  },
  assetItem: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
  },
  caption: {
    width: 56,
  },
}));

const PortfolioPreview = ({
  animate,
  portfolio,
  useAmount,
  scaled,
  fixed,
  showLogo,
  showZeroes,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    upsideCapture,
    downsideCapture,
    expectedReturn,
    stdDev,
    alpha,
    sharpe,
    sortino,
  } = portfolio.metrics;

  const twoColumns = portfolio.assetsSortedByAllocation.length > 10;

  const renderMetrics = () => {
    return (
      <Grid container spacing={1} align="center" direction="column">
        <Grid item>
          <ChartPortfolioComposition
            assets={portfolio.assetsSortedByAllocation}
            animate={animate}
            optimized={false}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={1} className={classes.center}>
            <Grid item className={classes.caption}>
              <Caption align="center">U/D</Caption>
            </Grid>
            <Grid item className={classes.caption}>
              <Caption align="center">CAGR/SD</Caption>
            </Grid>
            <Grid item className={classes.caption}>
              <Caption align="center">Alpha</Caption>
            </Grid>
            <Grid item className={classes.caption}>
              <Caption align="center">Sharpe</Caption>
            </Grid>
            <Grid item className={classes.caption}>
              <Caption align="center">Sortino</Caption>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} className={classes.center}>
            <Grid item>
              <UpsideDownside
                upsideCapture={upsideCapture}
                downsideCapture={downsideCapture}
                ready={portfolio.metricsReady}
              />
            </Grid>
            <Grid item>
              <CagrStdev
                cagr={expectedReturn}
                stdDev={stdDev}
                ready={portfolio.metricsReady}
              />
            </Grid>
            <Grid item>
              <Alpha alpha={alpha} ready={portfolio.metricsReady} />
            </Grid>
            <Grid item>
              <Sharpe sharpe={sharpe} ready={portfolio.metricsReady} />
            </Grid>
            <Grid item>
              <Sortino sortino={sortino} ready={portfolio.metricsReady} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAssets = () => {
    return (
      <GridList
        className={classes.gridList}
        cellHeight={20}
        spacing={4}
        cols={twoColumns ? 2 : 1}
      >
        {portfolio.assetsSortedByAllocation.map((asset) => {
          return (
            (showZeroes || asset.allocation > 0) && (
              <Box
                key={asset.id}
                display={'flex'}
                justifyContent={'space-between'}
                className={classes.assetItem}
              >
                <Typography
                  variant={'body1'}
                  component={'span'}
                  color="textPrimary"
                >
                  {asset.label}
                </Typography>
                <Typography
                  variant={'body1'}
                  component={'span'}
                  color="textSecondary"
                >
                  {useAmount
                    ? `$\u00A0${asset.amount.toLocaleString()}`
                    : `${numRound(asset.allocation)}%`}
                </Typography>
              </Box>
            )
          );
        })}
      </GridList>
    );
  };

  return (
    <Box className={classes.previewContainer}>
      <Grid
        container
        spacing={1}
        direction="row"
        className={classNames(classes.preview, {
          [classes.scaled]: scaled,
          [classes.fixed]: fixed,
        })}
      >
        <Box className={classes.logoContainer} hidden={!showLogo}>
          <LogoIcon />
        </Box>
        <Grid item>{renderMetrics()}</Grid>
        <Grid
          item
          className={classNames(classes.assetsList, {
            [classes.shortAssetsList]: !twoColumns,
            [classes.longAssetsList]: twoColumns,
          })}
        >
          {renderAssets()}
        </Grid>
      </Grid>
    </Box>
  );
};

PortfolioPreview.displayName = 'PortfolioPreview';

PortfolioPreview.propTypes = {
  animate: PropTypes.bool,
  portfolio: PropTypes.oneOfType([
    PropTypes.instanceOf(Portfolio),
    PropTypes.instanceOf(Mixer),
  ]),
  useAmount: PropTypes.bool,
  scaled: PropTypes.bool,
  fixed: PropTypes.bool,
  showLogo: PropTypes.bool,
  showZeroes: PropTypes.bool,
};

PortfolioPreview.defaultProps = {
  animate: false,
  useAmount: false,
  scaled: false,
  fixed: true,
  showLogo: false,
  showZeroes: false,
};

export default observer(PortfolioPreview);
