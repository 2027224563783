import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { truncate } from '../../utils/helpers';
import { observer } from 'mobx-react';
import { withStore } from '../../store';
import AssetControl from '../../components/AssetControl';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import TickerSearch from '../../components/TickerSearch';

import { numRound } from '../../../common/utils/helpers';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3),
  },
  content: {
    width: 900,
  },
  divider: {
    margin: theme.spacing(3),
  },
  table: {
    minWidth: 900,
  },
  totalRow: {
    '&>*': {
      fontWeight: '900',
    },
  },
  symbol: {
    color: '#b3e5fc',
    width: 80,
  },
  assetsControls: {
    width: '85%',
  },
  tabsContainer: {
    width: '100%',
  },
});

const pp = (v) => numRound(v * 100);
const getVal = (x, i) => (x ? x[i].value : NaN);

@withStore
@observer
class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autobalance: true,
      backendMetrics: false,
      tab: 0,
    };
  }

  handleAssetAdd = (hit) => {
    const { store } = this.props;
    const { portfolio } = store;
    try {
      portfolio.addAsset(hit.symbol);
    } catch (err) {
      alert(err);
    }
  };

  handleAssetRemove = (asset) => {
    const { store } = this.props;
    const { portfolio } = store;
    portfolio.removeAsset(asset);
  };

  handleAssetChange = (asset, val) => {
    const { store } = this.props;
    const { portfolio } = store;
    const { assets } = portfolio;
    if (val === asset.allocation) return;
    // asset.allocation = val;
    asset.setAllocation(val);

    if (!this.state.autobalance) return;

    const sum = assets.reduce((acc, a) => acc + a.allocation, 0);
    let delta = 100 - sum;

    if (sum <= 100) {
      return;
    }

    for (let i = assets.length - 1; i >= 0; i--) {
      const a = assets[i];

      if (a.symbol === asset.symbol) continue;
      if (delta < 0 && a.allocation > 0) {
        if (a.allocation < Math.abs(delta)) {
          delta += a.allocation;
          a.setAllocation(0);
        } else {
          a.setAllocation(a.allocation + delta);
          return;
        }
      }
    }
  };

  renderMetricsTable() {
    const { classes, store } = this.props;
    const { portfolio } = store;

    const pm = portfolio.metrics;
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">EE</TableCell>
              <TableCell align="right">SD</TableCell>
              <TableCell align="right">Sortino</TableCell>
              <TableCell align="right">Sharpe</TableCell>
              <TableCell align="right">Alpha</TableCell>
              <TableCell align="right">UP / DOWN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pm && (
              <TableRow key="TOTAL" className={classes.totalRow}>
                <TableCell component="th" scope="row">
                  PORTFOLIO
                </TableCell>
                <TableCell align="right">{pp(pm.expectedReturn)}%</TableCell>
                <TableCell align="right">{pp(pm.stdDev)}%</TableCell>
                <TableCell align="right">{numRound(pm.sortino)}</TableCell>
                <TableCell align="right">{numRound(pm.sharpe)}</TableCell>
                <TableCell align="right">{pp(pm.alpha)}%</TableCell>
                <TableCell align="right">
                  {pp(pm.upsideCapture)}% / {pp(pm.downsideCapture)}%
                </TableCell>
              </TableRow>
            )}
            {portfolio.assets.map((a) => {
              const { backendMetrics } = this.state;
              const m = backendMetrics
                ? a.security.data.perfMetrics
                : a.metrics;
              if (!a.hasData || a.isLoading) {
                return (
                  <TableRow key={a.symbol}>
                    <TableCell component="th" scope="row">
                      {a.symbol}
                    </TableCell>
                    <TableCell colSpan={6}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                );
              }
              if (!m) return;
              return (
                <TableRow key={a.symbol}>
                  <TableCell component="th" scope="row">
                    {a.symbol}
                  </TableCell>
                  <TableCell align="right">{pp(m.expectedReturn)}%</TableCell>
                  <TableCell align="right">{pp(m.stdDev)}%</TableCell>
                  <TableCell align="right">{numRound(m.sortino)}</TableCell>
                  <TableCell align="right">{numRound(m.sharpe)}</TableCell>
                  <TableCell align="right">{pp(m.alpha)}%</TableCell>
                  <TableCell align="right">
                    {pp(m.upsideCapture)}% / {pp(m.downsideCapture)}%
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderReturnsTable() {
    const { classes, store } = this.props;
    const { portfolio } = store;

    if (!portfolio.returns) return;

    const { assets } = portfolio;
    const returns = portfolio.returns.to_json().price;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div style={{ width: 70 }}>Date</div>
              </TableCell>
              {assets.map((a) => {
                return (
                  <TableCell key={`returns-col-${a.symbol}`} align="right">
                    {a.symbol}
                  </TableCell>
                );
              })}
              <TableCell align="right">Portfolio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(returns).map((date) => {
              return (
                <TableRow key={date}>
                  <TableCell component="th" scope="row">
                    {moment(date).format('DD MMM YYYY')}
                  </TableCell>
                  {assets.map((a) => {
                    if (!a.returns) return;
                    const ar = a.returns.to_json().price;
                    return (
                      <TableCell key={`returns-${a.symbol}`} align="right">
                        {pp(ar[date])}%
                      </TableCell>
                    );
                  })}
                  <TableCell align="right">{pp(returns[date])}%</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderCumulativeTable() {
    const { classes, store } = this.props;
    const { portfolio } = store;

    if (!portfolio.returns) return;
    const {
      plainCumReturns,
      plainCumAmount,
      plainOptimizedCumReturns,
      plainOptimizedCumAmount,
    } = portfolio;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div style={{ width: 70 }}>Date</div>
              </TableCell>
              <TableCell align="right">Amount (Optimized)</TableCell>
              <TableCell align="right">Return (Optimized)</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Return</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plainCumReturns.map(({ date, value }, i) => {
              const cumRet = value;
              const cumAmount = getVal(plainCumAmount, i);
              const optCumRet = getVal(plainOptimizedCumReturns, i);
              const optCumAmount = getVal(plainOptimizedCumAmount, i);

              return (
                <TableRow key={date.toISOString()}>
                  <TableCell component="th" scope="row">
                    {date.format('DD MMM YYYY')}
                  </TableCell>
                  <TableCell align="right">{`$\u00A0${optCumAmount.toLocaleString()}`}</TableCell>
                  <TableCell align="right">{pp(optCumRet)}%</TableCell>
                  <TableCell align="right">{`$\u00A0${cumAmount.toLocaleString()}`}</TableCell>
                  <TableCell align="right">{pp(cumRet)}%</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderAnnualTable() {
    const { classes, store } = this.props;
    const { portfolio } = store;
    const pp = (v) => numRound(v * 100);

    if (!portfolio.returns) return;
    const { plainAnnualReturns, plainOptmizedAnnualReturns } = portfolio;

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div style={{ width: 70 }}>Year</div>
              </TableCell>
              <TableCell align="right">Return (Optimized)</TableCell>
              <TableCell align="right">Return</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plainAnnualReturns.map(({ date, value }, i) => {
              const ret = value;
              const optRet = getVal(plainOptmizedAnnualReturns, i);

              return (
                <TableRow key={date.toISOString()}>
                  <TableCell component="th" scope="row">
                    {date.format('YYYY')}
                  </TableCell>
                  <TableCell align="right">{pp(optRet)}%</TableCell>
                  <TableCell align="right">{pp(ret)}%</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { classes, store } = this.props;
    const { portfolio } = store;

    return (
      <div className={classes.root}>
        <Grid
          className={classes.content}
          container
          spacing={4}
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Box width={700}>
              <TickerSearch onSelect={this.handleAssetAdd} />
            </Box>
          </Grid>
          {portfolio.assets.length > 0 && (
            <React.Fragment>
              <Grid item>
                <Box width={700}>
                  {portfolio.assets.map((a) => (
                    <AssetControl
                      key={a.symbol}
                      asset={a}
                      onChange={(val) => this.handleAssetChange(a, val)}
                      onRemove={() => this.handleAssetRemove(a)}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item className={classes.assetsControls}>
                <Grid container justify="space-between">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.autobalance}
                          onChange={(e) =>
                            this.setState({ autobalance: e.target.checked })
                          }
                          color="primary"
                          name="autobalance"
                        />
                      }
                      label="Rebalance overflow"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.backendMetrics}
                          onChange={(e) =>
                            this.setState({
                              backendMetrics: e.target.checked,
                            })
                          }
                          color="primary"
                          name="backendMetrics"
                        />
                      }
                      label="Backend metrics"
                    />
                  </Grid>
                  <Grid item>
                    <Typography id="input-slider">
                      {portfolio.assets.reduce(
                        (acc, a) => acc + a.allocation,
                        0,
                      )}
                      %
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.tabsContainer}>
                <Tabs
                  value={this.state.tab}
                  onChange={(e, v) => this.setState({ tab: v })}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  centered
                >
                  <Tab label="Metrics" />
                  <Tab label="Returns" />
                  <Tab label="Cumulative" />
                  <Tab label="Annual" />
                </Tabs>
              </Grid>
              {this.state.tab === 0 && (
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    {portfolio.since.format('MMMM YYYY')} -{' '}
                    {portfolio.till.format('MMMM YYYY')}
                  </Typography>
                  {this.renderMetricsTable()}
                </Grid>
              )}
              {this.state.tab === 1 && (
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    {portfolio.since.format('MMMM YYYY')} -{' '}
                    {portfolio.till.format('MMMM YYYY')}
                  </Typography>
                  {this.renderReturnsTable()}
                </Grid>
              )}
              {this.state.tab === 2 && (
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    {portfolio.since.format('MMMM YYYY')} -{' '}
                    {portfolio.till.format('MMMM YYYY')}
                  </Typography>
                  {this.renderCumulativeTable()}
                </Grid>
              )}
              {this.state.tab === 3 && (
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    {portfolio.since.format('MMMM YYYY')} -{' '}
                    {portfolio.till.format('MMMM YYYY')}
                  </Typography>
                  {this.renderAnnualTable()}
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

Info.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object,
};

export default withStyles(styles)(Info);
