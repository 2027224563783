import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { useStore } from '../../store';

import RiskProfileDialog from '../../components/RiskProfileDialog';
import RiskProfileLabel from '../../components/RiskProfileLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  intro: {
    paddingTop: '150px',
  },
  cardContent: {
    minHeight: 150,
  },
}));

export function Profile() {
  const classes = useStyles();
  const { riskProfile } = useStore();

  const [riskProfileDialog, setRiskProfileDialog] = useState(false);

  const handleDialogOpen = () => {
    setRiskProfileDialog(true);
  };

  const handleDialogClose = () => {
    setRiskProfileDialog(false);
  };

  return (
    <Container className={classes.root}>
      <Grid container direction="row" alignContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Investor Profile</Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Typography
                variant="caption"
                color={'textSecondary'}
                gutterBottom
              >
                MVP
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                Risk Profile
              </Typography>
              {riskProfile.isComplete ? (
                <RiskProfileLabel
                  text={riskProfile.resultLabel}
                  score={riskProfile.result}
                />
              ) : (
                <Typography variant="body2" component="p" color="textSecondary">
                  Complete the survey to determine your Risk Profile.
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <Button size="small" onClick={handleDialogOpen}>
                {riskProfile.isComplete ? 'View result' : 'Start Test'}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Typography
                variant="caption"
                color={'textSecondary'}
                gutterBottom
              >
                COMING SOON
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                Net Worth
              </Typography>
              <Typography variant="h3" component="p" color={'textSecondary'}>
                ${Number(235000).toLocaleString()}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" disabled>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Typography
                variant="caption"
                color={'textSecondary'}
                gutterBottom
              >
                COMING SOON
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                Budget Gap
              </Typography>
              <Typography variant="h3" component="p" color={'textSecondary'}>
                ${Number(5000).toLocaleString()}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" disabled>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Typography
                variant="caption"
                color={'textSecondary'}
                gutterBottom
              >
                COMING SOON
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                Bucket Strategy
              </Typography>
              <Typography variant="body2" component="p" color="textSecondary">
                Smart strategy to reach your financial goals.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" disabled>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <RiskProfileDialog open={riskProfileDialog} onClose={handleDialogClose} />
    </Container>
  );
}

export default observer(Profile);
