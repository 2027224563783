/**
 *
 * Loader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    margin: `${theme.spacing(4)}px auto`,
  },
});

function Loader({ classes, color, style, inputClassName }) {
  return (
    <div className={classNames(classes.root, inputClassName)} style={style}>
      <CircularProgress color={color} />
    </div>
  );
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  style: PropTypes.object,
  inputClassName: PropTypes.string,
};

Loader.defaultProps = {
  color: 'primary',
  style: {},
  inputClassName: '',
};

export default withStyles(styles)(Loader);
