import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import NumberFormat from 'react-number-format';
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: 120,
  },
}));

function InputDollar({ value, name, onChange, onBlur, disabled }) {
  const classes = useStyles();

  return (
    <Input
      className={classes.root}
      autoComplete={'off'}
      value={value}
      margin="dense"
      onChange={onChange}
      onBlur={onBlur}
      startAdornment={
        <InputAdornment style={{ marginTop: -2 }} position="start">
          $
        </InputAdornment>
      }
      name={name}
      inputComponent={NumberFormatCustom}
      inputProps={{ 'data-hj-allow': '', style: { textAlign: 'right' } }}
      disabled={disabled}
    />
  );
}

InputDollar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

InputDollar.defaultProps = {};

export default React.memo(InputDollar);
