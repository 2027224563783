import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ConstrainedAssetsTable from '../ConstrainedAssetsTable';
import { SettingsIcon } from '../AppIcons';

import { Link } from 'react-router-dom';

import DialogTransition from '../DialogTransition';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function DateRangeWarningDialog({
  open,
  expertMode,
  constrained,
  constrainingPortfolios,
  currentDateRange,
  onSettingsOpen,
  onClose,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={DialogTransition}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>Date Range</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Alert severity={constrained ? 'warning' : 'info'}>
            {currentDateRange}
          </Alert>
        </Box>
        <DialogContentText>
          {constrained
            ? `The date range for performance analysis is constrained by the minimum available date range of the Assets listed below. ${
                !expertMode
                  ? 'Continue to revert recommended setting (last 5 years).'
                  : 'Remove constraining assets if you want to revert to recommended (last 5 years) date range.'
              }`
            : 'The date range for performance analysis is set for last 5 years.'}
        </DialogContentText>
        {constrained && (
          <Box mb={3}>
            <ConstrainedAssetsTable
              constrainingPortfolios={constrainingPortfolios}
            />
          </Box>
        )}
        {!expertMode && constrained && (
          <DialogContentText>
            <Typography variant="caption" color={'textSecondary'}>
              If you wish to operate with constraining assets, turn on{' '}
              <b>Expert Mode</b> in{' '}
              <Button
                onClick={onSettingsOpen}
                startIcon={<SettingsIcon />}
                size="small"
                variant={'text'}
                color="default"
              >
                Settings
              </Button>
            </Typography>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {!expertMode && constrained ? 'Continue' : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DateRangeWarningDialog.propTypes = {
  open: PropTypes.bool,
  expertMode: PropTypes.bool.isRequired,
  constrained: PropTypes.bool.isRequired,
  constrainingPortfolios: PropTypes.any,
  currentDateRange: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSettingsOpen: PropTypes.func.isRequired,
};

DateRangeWarningDialog.defaultProps = {};

export default React.memo(DateRangeWarningDialog);
