import { computed, observable, action } from 'mobx';
import UniversalDocument from './UniversalDocument';

class RiskProfile extends UniversalDocument {
  @observable _answers = {}; // local copy of answers
  @observable qa = [
    {
      key: 'objectives',
      question:
        'Which of the following statements best describes your investment objectives?',
      options: [
        'My highest priority is to preserve my investments and I am willing to accept minimal return to do so',
        'I want to generate income from my investments and am interested in investments that have a low degree of volatility',
        'I want to grow my investments and am willing to accept a moderate-to-above average degree of volatility',
        'I want significant appreciation of my investments and am willing to accept a higher degree of volatility',
      ],
      weights: [0, 4, 8, 16],
    },
    {
      key: 'term',
      question:
        'How long do you plan to keep these funds invested in order to achieve your financial goals?',
      options: ['Less than 1 year', '1-2 years', '3-5 years', '5-10 years'],
      weights: [0, 2, 4, 20],
    },
    {
      key: 'decline',
      question:
        'How would you respond in the event of a 15-20% decline in the stock market?',
      options: [
        'Sell all immediately to avoid further risk',
        'Sell some, reducing my exposure to further declines',
        'Don’t sell - remain fully invested',
        'Don’t sell - consider investing more to take advantage of the buying opportunity',
      ],
      weights: [0, 4, 8, 16],
    },
    {
      key: 'loss',
      question:
        'How soon would you need your investments to recover after experiencing a sudden meaningful loss in value?',
      options: ['0-6 months', '6 months to 1 year', '1-3 years', '3+ years'],
      weights: [0, 4, 8, 16],
    },
    {
      key: 'tradeoff',
      question:
        'The stock market involves risk-versus-reward tradeoffs. Which best describes you?',
      options: [
        'I will choose to be limited to a 2-4% return if I can rest assured I will not lose any money',
        'I will trade off some market upside in order to minimize my downside',
        'I’m okay going up and down in proportion to the total market',
        'I’m willing to accept more volatility in order to attempt to outperform the market',
      ],
      weights: [0, 4, 8, 16],
    },
    {
      key: 'target',
      question:
        'Your portfolio will ‘follow’ the overall stock market to some degree - and, ideally, you would participate more in the market gains while participating less in the market losses. Which scenario do you want to target?',
      options: [
        '2-4% return: 2-4% of the market downside',
        '20% of the market upside: 10% of the market downside',
        '60% of the market upside: 40% of the market downside',
        '80% of the market upside: 60% of the market downside',
        '100% of the market upside: 80% of the market downside',
        '125% of the market upside: 100% of the market downside',
      ],
      weights: [0, 4, 6, 8, 12, 16],
    },
  ];

  constructor(source, options) {
    super(source, options);
  }

  getAnswerIndex(questionKey) {
    if (typeof this._answers[questionKey] === 'number')
      return this._answers[questionKey];
    if (typeof this.data[questionKey] === 'number')
      return this.data[questionKey];
    return -1;
  }

  @computed get questions() {
    return this.qa.map((q) => ({ ...q, answer: this.getAnswerIndex(q.key) }));
  }

  @computed get answeredQuestions() {
    return this.questions.filter((x) => x.answer > -1);
  }

  @computed get isComplete() {
    return this.answeredQuestions.length === this.qa.length;
  }

  @computed get result() {
    return this.answeredQuestions.reduce(
      (acc, x) => acc + x.weights[x.answer],
      0,
    );
  }

  @computed get resultLabel() {
    const r = this.result;

    if (r <= 20) return 'Conservative';
    if (r > 20 && r <= 40) return 'Conservative-Moderate';
    if (r > 40 && r <= 60) return 'Moderate';
    if (r > 60 && r <= 80) return 'Moderate-Aggressive';

    return 'Aggressive';
  }

  @action answer(questionKey, answerIndex) {
    const question = this.qa.find((q) => q.key === questionKey);
    if (!question) {
      throw new Error(`Key '${questionKey}' is not found in questions list.`);
    }
    if (typeof answerIndex !== 'number') {
      throw new Error(`Expected 'answerIndex' to be a number.`);
    }
    if (answerIndex > question.options.length - 1) {
      throw new Error(
        `answerIndex('${answerIndex}') exceeds question answers number.`,
      );
    }

    this._answers[questionKey] = answerIndex;
    this.set(
      { [questionKey]: answerIndex, result: this.result },
      { merge: true },
    );

    return this.result;
  }

  @action clear() {
    this._answers = {};
    this.delete();
  }
}

export default RiskProfile;
