import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import Box from '@material-ui/core/Box';

import Caption from '../Caption';
import { Portfolio } from '../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function ConstrainedAssetsTable({ constrainingPortfolios }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Caption align="left">asset</Caption>
            </TableCell>
            <TableCell>
              <Caption align="left">portfolio</Caption>
            </TableCell>
            <TableCell>
              <Caption align="left">since</Caption>
            </TableCell>
            <TableCell>
              <Caption align="left">till</Caption>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {constrainingPortfolios.map((p) =>
            p.constrainingAssets.map((a) => (
              <TableRow key={a.id}>
                <TableCell>
                  <Box width={60}>{a.symbol}</Box>
                </TableCell>
                <TableCell>{p.name}</TableCell>
                <TableCell>{a.since.format('MMMM YYYY')}</TableCell>
                <TableCell>{a.till.format('MMMM YYYY')}</TableCell>
                <TableCell>
                  <Tooltip title={'Remove asset'} arrow placement="top">
                    <IconButton onClick={() => p.removeAsset(a)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ConstrainedAssetsTable.propTypes = {
  constrainingPortfolios: PropTypes.arrayOf(PropTypes.instanceOf(Portfolio)),
};

ConstrainedAssetsTable.defaultProps = {};

export default React.memo(ConstrainedAssetsTable);
