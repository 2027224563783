import React from 'react';
import PropTypes from 'prop-types';
import { Glyph as CustomGlyph, GlyphCircle } from '@visx/glyph';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Spring } from 'react-spring/renderprops.cjs';

const useStyles = makeStyles((theme) => ({
  portfolio: {
    stroke: theme.palette.success.main,
    strokeWidth: 2,
    strokeDasharray: 3,
    fill: 'none',
  },
}));

function OptimizedTicker({
  name,
  top,
  left,
  onMouseOver,
  onMouseOut,
  onClick,
}) {
  const classes = useStyles();
  const theme = useTheme();
  if (!top || !left) return true;

  return (
    <svg
      onClick={onClick}
      style={{ pointerEvents: 'all' }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Spring to={{ top: top, left: left }}>
        {(props) => (
          <CustomGlyph top={props.top} left={props.left}>
            <GlyphCircle
              className={classes.portfolio}
              size={800}
              fill={'inherit'}
            />
            <text
              fontSize={16}
              fontWeight={500}
              textAnchor="middle"
              dy={5}
              cursor="default"
              fill={theme.palette.text.primary}
            >
              {name}
            </text>
          </CustomGlyph>
        )}
      </Spring>
    </svg>
  );
}

OptimizedTicker.propTypes = {
  name: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClick: PropTypes.func,
};

OptimizedTicker.defaultProps = {};

export default React.memo(OptimizedTicker, (prevProps, nextProps) => {
  return (
    prevProps.name === nextProps.name &&
    prevProps.top === nextProps.top &&
    prevProps.left === nextProps.left
  );
});
