import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { startCase } from 'lodash';

import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

function ChartLegend({ scale, meta, size, shape, padding }) {
  const classes = useStyles();

  const fadeRatio = 0.2;

  return (
    <Box p={padding}>
      <LegendOrdinal scale={scale} labelFormat={(label) => `${label}`}>
        {(labels) => (
          <div className={classes.root}>
            {labels.map((label, i) => (
              <LegendItem key={`legend-${label}-${i}`} margin={`0 ${size}px`}>
                <svg width={size} height={size}>
                  {shape === 'line' && (
                    <line
                      x1={0}
                      y1={size / 2}
                      x2={size}
                      y2={size / 2}
                      stroke={label.value}
                      strokeWidth={2}
                    />
                  )}
                  {shape === 'rect' && (
                    <rect
                      fill={fade(label.value, fadeRatio)}
                      stroke={label.value}
                      width={size}
                      height={size}
                    />
                  )}
                  {shape === 'circle' && (
                    <circle
                      cx={size / 2}
                      cy={size / 2}
                      r={size / 2 - 2}
                      fill={fade(label.value, fadeRatio)}
                      stroke={label.value}
                    />
                  )}
                </svg>
                <LegendLabel align="left" margin={`0 0 0 ${size / 2}px`}>
                  {meta ? meta[label.text] : startCase(label.text)}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </Box>
  );
}

ChartLegend.propTypes = {
  scale: PropTypes.any.isRequired,
  meta: PropTypes.object,
  size: PropTypes.number.isRequired,
  shape: PropTypes.oneOf(['line', 'rect', 'circle']),
  padding: PropTypes.number,
};

ChartLegend.defaultProps = {
  size: 20,
  shape: 'line',
  padding: 3,
};

export default React.memo(ChartLegend);
