import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { startCase } from 'lodash';

import { scaleBand, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { withParentSize } from '@visx/responsive';
import { GridRows, GridColumns } from '@visx/grid';

import { GlyphCircle } from '@visx/glyph';
import { Bar, Line } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { PatternLines } from '@visx/pattern';
import { LegendItem, LegendLabel } from '@visx/legend';

import { numRound } from '../../utils/helpers';

import { getXMax, getYMax, getKeys } from '../../../common/utils/chart';

import ChartXAxis from '../ChartXAxis';
import ChartYAxis from '../ChartYAxis';
import ChartTooltip from '../ChartTooltip';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

function ChartEstimatedActual({ data, parentWidth }) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const [highlightCol, setHighlightCol] = useState(null);

  const highlightColColor = fade(theme.palette.primary.light, 0.05);

  const margin = {
    top: 20,
    bottom: 40,
    left: 40,
    right: 20,
  };
  const parentHeight = theme.sizes.chartHeight;
  const gridColor = theme.palette.divider;

  const dateTickFormat = (v) => `Q${v.format('Q YYYY')}`;
  const tickFormat = (v) => `${numRound(v)}`;

  const xAccessor = (d) => d.date;

  const xMax = getXMax(parentWidth, margin);
  const yMax = getYMax(parentHeight, margin);

  const keys = getKeys(data);

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        domain: [...data.map(xAccessor)],
        padding: 0,
        nice: true,
      }),
    [data, xMax],
  );

  const epsDomainMin = Math.min(
    ...data.map((d) =>
      d.current ? d.estimate : Math.min(d.estimate, d.actual),
    ),
  );

  const epsDomainMax = Math.max(
    ...data.map((d) =>
      d.current ? d.estimate : Math.max(d.estimate, d.actual),
    ),
  );

  const padding = (epsDomainMax - epsDomainMin) * 0.1;
  const espMinPad = epsDomainMin - padding;
  const epsMaxPad = epsDomainMax + padding;

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [espMinPad, epsMaxPad],
        range: [yMax, 0],
        nice: true,
      }),
    [espMinPad, epsMaxPad, yMax],
  );

  const handleTooltip = useCallback(
    (event, d, i, length) => {
      const lp = localPoint(event);
      setHighlightCol(i);
      const top = lp.y;
      const left = lp.x;

      const groupX = xScale(xAccessor(d));
      const shift = xMax / length;
      const lineX = groupX + shift / 2;

      const resolution =
        d.actual === d.estimate
          ? 'met'
          : d.actual > d.estimate
          ? 'beat'
          : 'miss';

      const by = Math.abs(d.estimate - d.actual);

      const data = {
        estimate: d.estimate,
        actual: d.actual,
        resolution: d.actual ? resolution : null,
        by: d.actual
          ? by === 0
            ? `$${d.actual}`
            : `by $${numRound(by)}`
          : null,
        date: d.date.format('DD MMM YYYY'),
        lineX: lineX,
      };
      showTooltip({
        tooltipData: data,
        tooltipTop: top,
        tooltipLeft: left,
      });
    },
    [showTooltip, xScale],
  );
  const getTitleColor = (resolution) => {
    if (resolution === 'beat') {
      return 'success.main';
    }
    if (resolution === 'miss') {
      return 'error.main';
    }
    if (resolution === 'met') {
      return 'primary.main';
    }
    return 'inherit';
  };

  return (
    <div className={classes.root}>
      <svg width={parentWidth} height={parentHeight}>
        <PatternLines
          id="lines2"
          height={16}
          width={16}
          stroke={theme.palette.divider}
          strokeWidth={1}
          orientation={['diagonalRightToLeft']}
        />
        <Group left={margin.left} top={margin.top}>
          {data.map((d, i) => {
            const barWidth = xScale.bandwidth();
            const barHeight = yMax;
            const groupX = xScale(xAccessor(d));

            return (
              d.current && (
                <Bar
                  key={`bar-2-${i}`}
                  x={groupX}
                  y={0}
                  fill={`url('#lines2')`}
                  width={barWidth}
                  height={barHeight}
                />
              )
            );
          })}
          <Group>
            <ChartXAxis top={yMax} scale={xScale} tickFormat={dateTickFormat} />
            <GridColumns
              key={`dates-gridcolumns`}
              scale={xScale}
              stroke={gridColor}
              height={yMax}
              numTicks={4}
            />
          </Group>
          <Group>
            <ChartYAxis scale={yScale} tickFormat={tickFormat} numTicks={8} />
            <GridRows
              key={`amounts-gridrows`}
              scale={yScale}
              stroke={gridColor}
              width={xMax}
              numTicks={8}
            />
          </Group>
          {data.map((d, i) => {
            const groupX = xScale(xAccessor(d));
            const shift = xMax / data.length;

            return (
              <Group key={`group-${i}`} left={groupX + shift / 2}>
                <GlyphCircle
                  size={300}
                  top={yScale(d.estimate)}
                  fill={'transparent'}
                  stroke={theme.palette.text.primary}
                  strokeDasharray={d.current ? 3 : null}
                />
                {!d.current && d.estimate === d.actual ? (
                  <GlyphCircle
                    size={300}
                    top={yScale(d.actual)}
                    fill={theme.palette.primary.dark}
                    stroke={theme.palette.primary.main}
                  />
                ) : (
                  d.actual && (
                    <GlyphCircle
                      size={300}
                      top={yScale(d.actual)}
                      fill={
                        d.actual > d.estimate
                          ? theme.palette.success.dark
                          : theme.palette.error.dark
                      }
                      stroke={
                        d.actual > d.estimate
                          ? theme.palette.success.main
                          : theme.palette.error.main
                      }
                    />
                  )
                )}
              </Group>
            );
          })}
          {data.map((d, i) => {
            const barWidth = xScale.bandwidth();
            const barHeight = yMax;

            const groupX = xScale(xAccessor(d));

            return (
              <Bar
                key={`bar-${i}`}
                x={groupX}
                y={0}
                fill={highlightCol === i ? highlightColColor : 'transparent'}
                width={barWidth}
                height={barHeight}
                onMouseLeave={() => {
                  setHighlightCol(null);
                  hideTooltip();
                }}
                onMouseMove={(event) => handleTooltip(event, d, i, data.length)}
              />
            );
          })}
          {tooltipData && (
            <Group>
              <Line
                from={{ x: tooltipData.lineX, y: 0 }}
                to={{ x: tooltipData.lineX, y: yMax }}
                stroke={fade(theme.palette.text.primary, 0.25)}
                strokeDasharray={3}
                pointerEvents="none"
              />
              <Line
                from={{
                  x: 0,
                  y: tooltipData.actual
                    ? yScale(tooltipData.actual)
                    : yScale(tooltipData.estimate),
                }}
                to={{
                  x: xMax,
                  y: tooltipData.actual
                    ? yScale(tooltipData.actual)
                    : yScale(tooltipData.estimate),
                }}
                stroke={fade(theme.palette.text.primary, 0.25)}
                strokeDasharray={3}
                pointerEvents="none"
              />
            </Group>
          )}
        </Group>
      </svg>
      <div className={classes.legendContainer}>
        <LegendItem margin={`0 10px`}>
          <svg width={20} height={20}>
            <GlyphCircle
              size={250}
              top={10}
              left={10}
              fill={'transparent'}
              stroke={theme.palette.text.primary}
            />
          </svg>
          <LegendLabel align="left" margin="0 0 0 6px">
            Estimated
          </LegendLabel>
        </LegendItem>
        <LegendItem margin={`0 10px`}>
          <svg width={20} height={20}>
            <GlyphCircle
              size={250}
              top={10}
              left={10}
              fill={theme.palette.success.dark}
              stroke={theme.palette.success.main}
            />
          </svg>
          <LegendLabel align="left" margin="0 0 0 6px">
            Beat
          </LegendLabel>
        </LegendItem>
        <LegendItem margin={`0 10px`}>
          <svg width={20} height={20}>
            <GlyphCircle
              size={250}
              top={10}
              left={10}
              fill={theme.palette.primary.dark}
              stroke={theme.palette.primary.main}
            />
          </svg>
          <LegendLabel align="left" margin="0 0 0 6px">
            Met
          </LegendLabel>
        </LegendItem>
        <LegendItem margin={`0 10px`}>
          <svg width={20} height={20}>
            <GlyphCircle
              size={250}
              top={10}
              left={10}
              fill={theme.palette.error.dark}
              stroke={theme.palette.error.main}
            />
          </svg>
          <LegendLabel align="left" margin="0 0 0 6px">
            Miss
          </LegendLabel>
        </LegendItem>
      </div>
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={{ position: 'absolute' }}
        >
          <ChartTooltip
            title={
              tooltipData.actual
                ? `${startCase(tooltipData.resolution)} ${tooltipData.by}`
                : tooltipData.date
            }
            data={keys.map((k) => {
              if (!tooltipData[k]) {
                return {
                  label: '',
                  value: '',
                };
              }
              const sign = tooltipData[k] < 0 ? '-' : '';
              const value = `${sign}$${numRound(Math.abs(tooltipData[k]))}`;
              return {
                label: startCase(k),
                value: value,
              };
            })}
            titleColor={getTitleColor(tooltipData.resolution)}
          />
        </TooltipWithBounds>
      )}
    </div>
  );
}

ChartEstimatedActual.propTypes = {
  parentWidth: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      estimate: PropTypes.number,
      actual: PropTypes.number,
      date: PropTypes.any,
    }),
  ).isRequired,
};
ChartEstimatedActual.defaultProps = {};

export default React.memo(withParentSize(ChartEstimatedActual));
