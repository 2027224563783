import React from 'react';
import PropTypes from 'prop-types';
import { numRound } from '../../../common/utils/helpers';

import Indicator from './Indicator';

function Alpha({ alpha, neutral, large, ready, showCaptions }) {
  const caption = 'Alpha';

  if (isNaN(alpha) || !ready) {
    return (
      <Indicator
        val1={'--'}
        tint={'default'}
        large={large}
        caption={caption}
        showCaptions={showCaptions}
      />
    );
  }
  const alphaValue = numRound(alpha * 100);

  const getTint = () => {
    if (neutral) return 'default';
    if (alphaValue >= 5) return 'green';
    if (alphaValue >= 1) return 'yellow';
    return 'red';
  };

  return (
    <Indicator
      val1={alphaValue}
      tint={getTint()}
      large={large}
      caption={caption}
      showCaptions={showCaptions}
    />
  );
}

Alpha.propTypes = {
  alpha: PropTypes.number,
  neutral: PropTypes.bool,
  large: PropTypes.bool,
  ready: PropTypes.bool,
  showCaptions: PropTypes.bool,
  onClick: PropTypes.func,
};

Alpha.defaultProps = {
  neutral: false,
  large: false,
  showCaptions: false,
};

export default React.memo(Alpha);
