import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import CreatePortfolioCard from '../CreatePortfolioCard';
import YoutubePlayer from '../YoutubePlayer';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    maxWidth: 648,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  cardsLayout: {},
}));

function PortfolioStartScreen({
  onPortfolioCreateExisting,
  onPortfolioCreateNew,
}) {
  const classes = useStyles();

  const handleGuidesButton = () => {
    const url =
      'https://www.youtube.com/watch?v=TnQ7lk4wsuE&list=PLDh2UGkph9o92f6Ixg0GfGSzZ4U5PGwLz&index=2';
    window.open(url, '_blank').focus();
  };

  return (
    <Container className={classes.root}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Welcome to investblaze
          </Typography>
        </Grid>
        <Grid item>
          <Grid container className={classes.cardsLayout} spacing={3}>
            <Grid item xs={12} sm={6}>
              <CreatePortfolioCard onClick={onPortfolioCreateNew} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CreatePortfolioCard
                existing
                onClick={onPortfolioCreateExisting}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Card>
            <YoutubePlayer videoId={'TnQ7lk4wsuE'} />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                There are more tutorials available, they will guide you from
                financial basics into details on how to use investblaze to
                develop your investment strategy.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                endIcon={<OpenInNewIcon>Open in new tab</OpenInNewIcon>}
                size="small"
                color="primary"
                onClick={handleGuidesButton}
              >
                Watch Playlist with all guides on YouTube
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

PortfolioStartScreen.propTypes = {
  onPortfolioCreateExisting: PropTypes.func.isRequired,
  onPortfolioCreateNew: PropTypes.func.isRequired,
};

PortfolioStartScreen.defaultProps = {};

export default React.memo(PortfolioStartScreen);
