import React from 'react';
import PropTypes from 'prop-types';

import { Glyph as CustomGlyph, GlyphCircle } from '@visx/glyph';

import { useTheme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

function GlyphCurrent({ value, top, left }) {
  const theme = useTheme();
  const color = theme.palette.text.primary;
  return (
    <CustomGlyph top={top} left={left}>
      <GlyphCircle
        size={200}
        fill={lighten(theme.palette.background.paper, 0.04)}
        stroke={color}
        strokeWidth={2}
      />
      <text
        fontSize={16}
        fontWeight={400}
        textAnchor="end"
        dx={-16}
        dy={4}
        fill={color}
      >
        ${value.toLocaleString()}
      </text>
    </CustomGlyph>
  );
}

GlyphCurrent.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default React.memo(GlyphCurrent);
