import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import copyToClipboard from 'copy-to-clipboard';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopyTwoTone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinearProgress from '@material-ui/core/LinearProgress';

import PortfolioPreview from '../../components/PortfolioPreview';
import { Portfolio } from '../../store';
import { dark } from '../../../common/theme';
import { isServer } from '../../utils/env';

import DialogTransition from '../DialogTransition';

const darkTheme = createMuiTheme(dark);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  inputProgress: {
    position: 'absolute',
    top: '50%',
    left: 16,
    marginTop: 4,
    width: 'calc(100% - 16px)',
    height: 1,
  },
}));

function ShareDialog({ open, onClose, portfolio, share }) {
  const classes = useStyles();
  const [shareAmount, setShareAmount] = useState(portfolio.shareAmount);
  const [ready, setReady] = useState(true);
  const linkExist =
    ready && portfolio.sharedId && portfolio.shareAmount === shareAmount;
  const [message, setMessage] = useState(null);

  const us = !isServer() ? window.location.href.split('/') : '';
  const link = `${us[0]}//${us[2]}/p/${portfolio.sharedId}`;

  if (shareAmount && !portfolio.existing) {
    setShareAmount(false);
  }

  const linkStatus = () => {
    return message
      ? message
      : linkExist
      ? 'Link is ready to share'
      : 'Click SHARE to create a public copy of the portfolio';
  };

  const setLinkStatus = (status, permanent = false) => {
    setMessage(status);
    if (!permanent) setTimeout(() => setMessage(null), 2000);
  };

  const handleAmountToggle = (event) => {
    setShareAmount(event.target.checked);
  };

  const handleShare = async () => {
    if (linkExist) {
      handleCopy();
      return;
    }
    setReady(false);
    setLinkStatus('Creating a copy of the portfolio...', true);
    await share(shareAmount);
    setLinkStatus(null, true);
    setReady(true);
  };

  const handleCopy = () => {
    copyToClipboard(link);
    setLinkStatus('Link copied to clipboard');
  };

  const handleOpenLink = () => {
    window.open(`/p/${portfolio.sharedId}`, '_blank');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      TransitionComponent={DialogTransition}
    >
      <div style={{ width: 650 }}>
        <DialogTitle>Share portfolio</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Typography variant="body2" align="justify">
              {portfolio.existing &&
                `You are about to share a portfolio with actual dollar amounts
            allocated to each asset. You have an option to share it as-is or
            convert dollar amount to percentage if you don't want to share
            actual amount.`}
              {portfolio.existing && <br />}
              By sharing portfolio, you are creating a public copy of the actual
              portfolio that can be viewed by anyone who has a link. Changes to
              the original portfolio will not be reflected on the shared copy.
              Once shared copy is created, it cannot be revoked.
            </Typography>
          </DialogContentText>

          <Grid container direction="column" spacing={2}>
            <Grid item>
              {portfolio && (
                <ThemeProvider theme={darkTheme}>
                  <PortfolioPreview
                    portfolio={portfolio}
                    useAmount={shareAmount}
                  />
                </ThemeProvider>
              )}
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs className={classes.wrapper}>
                  <TextField
                    onClick={(e) => e.target.select && e.target.select()}
                    style={{ margin: 8 }}
                    placeholder="https://"
                    helperText={linkStatus()}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={linkExist ? link : ''}
                  />
                  {!ready && (
                    <LinearProgress
                      width={1}
                      className={classes.inputProgress}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip
                    title={'Copy link to the clipboard'}
                    placement="top"
                    arrow
                  >
                    <span>
                      <IconButton
                        disabled={!linkExist}
                        onClick={handleCopy}
                        color="primary"
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={'Open link in a new tab'}
                    placement="top"
                    arrow
                  >
                    <span>
                      <IconButton
                        disabled={!linkExist}
                        onClick={handleOpenLink}
                        color="primary"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {portfolio.existing && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareAmount}
                  onChange={handleAmountToggle}
                  color="primary"
                />
              }
              label="Share amount"
            />
          )}
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={!ready}
            onClick={handleShare}
            color={!linkExist ? 'primary' : 'default'}
            variant={'outlined'}
          >
            Share
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

ShareDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  share: PropTypes.func,
  portfolio: PropTypes.instanceOf(Portfolio).isRequired,
};

ShareDialog.defaultProps = {
  open: false,
  onClose: () => {},
  share: () => {},
};

export default observer(ShareDialog);
