import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useHistory, useLocation } from 'react-router-dom';

import { PortfolioIcon, SettingsIcon, UserIcon } from '../AppIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 72,
  },
  button: {
    fontSize: 24,
  },
}));

function BottomAppMenu() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currentLocation = location.pathname;

  const [value, setValue] = React.useState(currentLocation);

  const handleChange = (event, newValue) => {
    history.push(newValue);
    setValue(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Investor Profile"
        value="/profile"
        className={classes.button}
        icon={<UserIcon />}
      />
      <BottomNavigationAction
        label="Portfolio"
        value="/"
        className={classes.button}
        icon={<PortfolioIcon />}
      />
      <BottomNavigationAction
        label="Settings"
        value="/account"
        className={classes.button}
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
  );
}

BottomAppMenu.propTypes = {};

BottomAppMenu.defaultProps = {};

export default React.memo(BottomAppMenu);
