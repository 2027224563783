import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { PORTFOLIO_TYPES } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.tooltip.background,
    border: `1px solid ${fade(theme.palette.text.primary, 0.17)}`,
    backdropFilter: theme.blur,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    minWidth: 180,
  },
  itemBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  label: {
    paddingRight: theme.spacing(1),
  },

  portfolio: {
    background: theme.palette.primary.main,
  },
  benchmark: {
    background: theme.palette.text.primary,
  },
  optimized: {
    background: theme.palette.success.main,
  },
  compared: {
    background: theme.palette.chart.compare,
  },
}));

function ChartTooltip({ title, data, titleColor, color }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box color={titleColor} align="center" pb={1}>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Grid container direction="column">
        {data.map((d, i) => (
          <Box
            key={`${d.label}-${i}`}
            display={'flex'}
            justifyContent={'space-between'}
            className={classNames({
              [classes.itemBorder]: i < data.length - 1,
            })}
          >
            <Grid container direction="row" spacing={2} alignItems={'center'}>
              <Grid item xs>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems={'center'}
                >
                  <Grid item hidden={!color}>
                    <Box
                      width={10}
                      height={10}
                      className={classNames({
                        [classes.portfolio]:
                          d.type === PORTFOLIO_TYPES.portfolio,
                        [classes.benchmark]:
                          d.type === PORTFOLIO_TYPES.benchmark,
                        [classes.optimized]:
                          d.type === PORTFOLIO_TYPES.optimized,
                        [classes.compared]: d.type === PORTFOLIO_TYPES.compared,
                      })}
                    ></Box>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body2'} component={'span'}>
                      {d.label}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant={'body2'} align={'right'}>
                  {d.value}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </div>
  );
}

ChartTooltip.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  color: PropTypes.bool,
};

ChartTooltip.defaultProps = {
  titleColor: 'inherit',
  color: false,
};

export default React.memo(ChartTooltip);
