import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useStore } from '../../store';
import GoogleSignInButton from '../../components/GoogleSignInButton';

import LogoWordmark from '../../components/LogoWordmark';
import SigninCopy from '../../components/SigninCopy';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  intro: {
    maxWidth: 600,
  },
}));

const REDIRECT = false;

export function Login() {
  const classes = useStyles();
  const store = useStore();
  const { user } = store;

  const handleLogin = async () => {
    store.setBackdrop(true);
    await user.login({ redirect: true, anon: false });
  };

  const handleAnonymousLogin = async () => {
    store.setBackdrop(true);
    await user.login({ redirect: false, anon: true });
    store.setBackdrop(false);
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" className={classes.intro} spacing={4}>
        <LogoWordmark />
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Sign in to continue
          </Typography>
          <SigninCopy />
        </Grid>
        <Grid item xs>
          <Grid container direction="row" justify="space-between" spacing={4}>
            <Grid item xs>
              <GoogleSignInButton
                fullWidth
                size="large"
                onClick={handleLogin}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                size="large"
                onClick={handleAnonymousLogin}
                color="primary"
              >
                Skip for now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default observer(Login);
