import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import MUIDrawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Logo from '../Logo';

import DrawerPortfolioList from './DrawerPortfolioList';
import DrawerMenu from './DrawerMenu';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.sizes.sideMenuWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '100%',
    background: theme.palette.background.drawer,
  },
  drawerOpen: {
    width: 580,
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 64,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  logoContainer: {
    height: 64,
    width: '100%',
  },
  logoText: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
  },
  logoTextBeta: {
    fontSize: 8,
    fontFamily: 'Comfortaa',
    position: 'absolute',
    right: -20,
    top: 0,
  },
}));

function Drawer({
  expanded,
  benchmark,
  portfolios,
  selected,
  onPortfolioSelect,
  onPortfolioCreate,
  onPortfolioRemove,
  onSettingsOpen,
  onGuidesOpen,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(expanded);

  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  // const toggleDrawer = () => setOpen(!open);

  const mouseEnter = useCallback(
    debounce(() => {
      setOpen(true);
    }, 400),
    [],
  );
  const mouseLeave = () => {
    mouseEnter.cancel();
    setOpen(false);
  };

  return (
    <MUIDrawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: open ? classes.drawerOpen : classes.drawerClose,
      }}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Grid
        container
        direction={'column'}
        style={{ height: '100vh' }}
        spacing={0}
      >
        <Grid item style={{ flex: 1, overflow: 'scroll' }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.logoContainer}
              >
                <Box px={1 / 2}>
                  <Logo />
                </Box>
                <Box position="relative">
                  <Typography
                    className={classes.logoText}
                    color={'textPrimary'}
                  >
                    investblaze
                  </Typography>
                  <Typography
                    className={classes.logoTextBeta}
                    color={'textSecondary'}
                  >
                    beta
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>
          <DrawerPortfolioList
            benchmark={benchmark}
            portfolios={portfolios}
            selected={selected}
            onPortfolioSelect={onPortfolioSelect}
            onPortfolioCreate={onPortfolioCreate}
            onPortfolioRemove={onPortfolioRemove}
          />
        </Grid>
        <Grid item>
          <DrawerMenu
            onSettingsOpen={onSettingsOpen}
            onGuidesOpen={onGuidesOpen}
          />
        </Grid>
      </Grid>

      {/* <DrawerContent toggleDrawer={toggleDrawer} /> */}
    </MUIDrawer>
  );
}

Drawer.propTypes = {
  expanded: PropTypes.bool,
  portfolios: PropTypes.array,
  benchmark: PropTypes.object,
  selected: PropTypes.object,
  onPortfolioSelect: PropTypes.func,
  onPortfolioCreate: PropTypes.func,
  onPortfolioRemove: PropTypes.func,
  onSettingsOpen: PropTypes.func,
  onGuidesOpen: PropTypes.func,
};

Drawer.defaultProps = {
  expanded: false,
};

export default React.memo(Drawer);
