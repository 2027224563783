import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { Box } from '@material-ui/core';
import Caption from '../Caption';

function Indicator({
  val1,
  val2,
  caption,
  highlight,
  tint,
  large,
  showCaptions,
  onClick,
}) {
  const theme = useTheme();
  const borderOpacity = 0.6;

  const INDICATOR_COLORS = {
    default: theme.palette.text.primary,
    green: theme.palette.success.main,
    yellow:
      theme.palette.type === 'dark'
        ? theme.palette.warning.main
        : theme.palette.warning.dark,
    red: theme.palette.error.main,
  };
  const isDefault = tint === 'default';

  return large ? (
    <Box display={'flex'} flexDirection="column" width={1} height={1} mx="auto">
      <Box py={1}>
        <Caption align="center" fontSize={10}>
          {caption}
        </Caption>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        fontSize={24}
        lineHeight={1.75}
        flex={1}
        color={
          isDefault ? fade(INDICATOR_COLORS[tint], 0.6) : INDICATOR_COLORS[tint]
        }
        bgcolor={fade(
          INDICATOR_COLORS[tint],
          highlight ? 0.15 : isDefault ? 0.02 : 0.1,
        )}
        border={highlight ? 1 : 0}
        borderRadius={8}
        borderColor={
          isDefault
            ? fade(INDICATOR_COLORS[tint], 0.4)
            : fade(INDICATOR_COLORS[tint], borderOpacity)
        }
      >
        <span>{val1}</span>
        {val2 && (
          <Box
            height={'2px'}
            width={60}
            bgcolor={fade(INDICATOR_COLORS[tint], isDefault ? 0.15 : 0.15)}
          />
        )}
        {val2 && <span>{val2}</span>}
      </Box>
    </Box>
  ) : (
    <Box position="relative">
      {showCaptions && (
        <Box position="absolute" top={-16} right={0} left={0}>
          <Caption align="center">{caption}</Caption>
        </Box>
      )}
      <Box
        display="flex"
        position="relative"
        color={
          isDefault ? fade(INDICATOR_COLORS[tint], 0.6) : INDICATOR_COLORS[tint]
        }
        bgcolor={fade(
          INDICATOR_COLORS[tint],
          // highlight ? 0.15 : isDefault ? 0.02 : 0.1,
          highlight ? 0.15 : isDefault ? 0.02 : 0.1,
        )}
        border={highlight ? 1 : 0}
        borderRadius={4}
        borderColor={
          isDefault
            ? fade(INDICATOR_COLORS[tint], 0.4)
            : fade(INDICATOR_COLORS[tint], borderOpacity)
        }
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={48}
        height={48}
        overflow="hidden"
        fontSize={13}
        lineHeight={1.75}
        mx="auto"
      >
        <span>{val1}</span>
        {val2 && (
          <Box
            height={'1px'}
            width={32}
            bgcolor={fade(INDICATOR_COLORS[tint], isDefault ? 0.15 : 0.15)}
          />
        )}
        {val2 && <span>{val2}</span>}
      </Box>
    </Box>
  );
}

Indicator.propTypes = {
  val1: PropTypes.string,
  val2: PropTypes.string,
  caption: PropTypes.string,
  highlight: PropTypes.bool,
  large: PropTypes.bool,
  showCaptions: PropTypes.bool,
  tint: PropTypes.oneOf(['default', 'green', 'yellow', 'red']),
  onClick: PropTypes.func,
};

Indicator.defaultProps = {
  val1: 12.41,
  val2: null,
  highlight: false,
  large: false,
  showCaptions: false,
  caption: '',
  tint: 'default',
};

export default Indicator;
