import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { withParentSize } from '@visx/responsive';
import { GridRows, GridColumns } from '@visx/grid';

import { BarGroup, Bar, Line } from '@visx/shape';
import { localPoint } from '@visx/event';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';

import { numRound, formatAmount } from '../../utils/helpers';

import { getXMax, getYMax, getKeys } from '../../../common/utils/chart';

import ChartXAxis from '../ChartXAxis';
import ChartYAxis from '../ChartYAxis';
import ChartLegend from '../ChartLegend';
import ChartTooltip from '../ChartTooltip';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

function ChartRevenueEarnings({ data, parentWidth }) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const [highlightCol, setHighlightCol] = useState(null);

  const margin = {
    top: 20,
    bottom: 40,
    left: 60,
    right: 20,
  };
  const parentHeight = theme.sizes.chartHeight;
  const barRadius = 0;

  const gridColor = theme.palette.divider;
  const highlightColColor = fade(theme.palette.primary.light, 0.05);

  const tickFormat = (v) => formatAmount(v);
  const dateTickFormat = (v) => v.format('YYYY');

  const xAccessor = (d) => d.date;

  const xMax = getXMax(parentWidth, margin);
  const yMax = getYMax(parentHeight, margin);

  const keys = getKeys(data);

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        domain: [...data.map(xAccessor)],
        padding: 0,
        nice: true,
      }),
    [data, xMax],
  );

  const keysScale = useMemo(
    () =>
      scaleBand({
        range: [0, xScale.bandwidth()],
        domain: keys,
        paddingInner: 0.25,
        paddingOuter: 0.5,
      }),
    [keys, xScale],
  );

  const keysAmountsArray = (keys, d) =>
    keys.map((key) => {
      return Number(d[key]);
    });
  const valuesArray = (f) => data.map(f);

  const amountsDomainMin = Math.min(
    ...valuesArray((d) => Math.min(...keysAmountsArray(keys, d))),
    0,
  );

  const amountsDomainMax = Math.max(
    ...valuesArray((d) => Math.max(...keysAmountsArray(keys, d))),
    0,
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [amountsDomainMin, amountsDomainMax * 1.2],
        range: [yMax, 0],
        nice: true,
      }),
    [amountsDomainMin, amountsDomainMax, yMax],
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: [
          theme.palette.primary.dark,
          theme.palette.primary.light,
          theme.palette.secondary.main,
        ],
      }),
    [keys],
  );

  const handleTooltip = useCallback(
    (event, d, i) => {
      if (highlightCol !== i) {
        setHighlightCol(i);
      }

      const lp = localPoint(event);
      const top = lp.y;
      const left = lp.x;

      const data = {
        revenue: d.revenue,
        earnings: d.earnings,
        freeCashFlow: d.freeCashFlow,
      };
      showTooltip({
        tooltipData: data,
        tooltipTop: top,
        tooltipLeft: left,
      });
    },
    [showTooltip, xScale],
  );

  return (
    <div className={classes.root}>
      <svg width={parentWidth} height={parentHeight}>
        <Group left={margin.left} top={margin.top}>
          <Group>
            <Group>
              <ChartXAxis
                top={yMax}
                scale={xScale}
                tickFormat={dateTickFormat}
              />
              <GridColumns scale={xScale} stroke={gridColor} height={yMax} />
            </Group>
            <Group>
              <ChartYAxis scale={yScale} tickFormat={tickFormat} />
              <GridRows scale={yScale} stroke={gridColor} width={xMax} />
            </Group>
            <Line
              from={{ x: 0, y: yScale(0) }}
              to={{ x: xMax, y: yScale(0) }}
              stroke={fade(theme.palette.text.secondary, 0.2)}
              pointerEvents="none"
            />
            <BarGroup
              data={data}
              keys={keys}
              height={yMax}
              x0={xAccessor}
              x0Scale={xScale}
              x1Scale={keysScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barGroups) => {
                return barGroups.map((barGroup) => {
                  return (
                    <Group
                      key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                      left={barGroup.x0}
                    >
                      {barGroup.bars.map((bar) => {
                        const height =
                          bar.value > 0
                            ? Math.abs(
                                yScale(bar.value) -
                                  yScale(Math.max(0, amountsDomainMin)),
                              )
                            : Math.abs(yScale(bar.value) - yScale(0));

                        const y = bar.value > 0 ? yScale(bar.value) : yScale(0);

                        return (
                          <Bar
                            key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                            x={bar.x}
                            y={y}
                            width={bar.width}
                            height={height}
                            fill={
                              barGroup.index === highlightCol
                                ? bar.color
                                : fade(bar.color, 0.1)
                            }
                            stroke={bar.color}
                            rx={barRadius}
                          />
                        );
                      })}
                    </Group>
                  );
                });
              }}
            </BarGroup>
            {data.map((d, i) => {
              const label = xAccessor(d);
              const barWidth = xScale.bandwidth();
              const barHeight = yMax;
              const barX = xScale(label);
              return (
                <Bar
                  key={`bar-${label}`}
                  x={barX}
                  y={0}
                  fill={highlightCol === i ? highlightColColor : 'transparent'}
                  width={barWidth}
                  height={barHeight}
                  onMouseLeave={() => {
                    setHighlightCol(null);
                    hideTooltip();
                  }}
                  onMouseMove={(event) => handleTooltip(event, d, i)}
                />
              );
            })}
          </Group>
        </Group>
      </svg>
      <ChartLegend scale={colorScale} shape={'rect'} size={16} padding={0} />
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          key={`${tooltipTop}-${tooltipLeft}`}
          top={tooltipTop}
          left={tooltipLeft}
          style={{ position: 'absolute' }}
        >
          <ChartTooltip
            title={`E/R: ${Math.round(
              (tooltipData.earnings / tooltipData.revenue) * 100,
            )} %`}
            data={keys.map((k) => {
              return {
                label: startCase(k),
                value: tickFormat(tooltipData[k]),
              };
            })}
            titleColor={
              numRound(tooltipData.earnings / tooltipData.revenue) * 100 > 0
                ? 'success.main'
                : 'error.main'
            }
          />
        </TooltipWithBounds>
      )}
    </div>
  );
}

ChartRevenueEarnings.propTypes = {
  parentWidth: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      revenue: PropTypes.number,
      earnings: PropTypes.number,
      freeCashFlow: PropTypes.number,
      date: PropTypes.any,
    }),
  ).isRequired,
};

ChartRevenueEarnings.defaultProps = {};

export default React.memo(withParentSize(ChartRevenueEarnings));
