import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  primaryStroke: {
    stroke: theme.palette.primary.main,
  },
  stroke: {
    stroke: theme.palette.text.secondary,
  },
}));

export function NewIllustration() {
  const classes = useStyles();

  return (
    <svg
      width="96px"
      height="112px"
      viewBox="0 0 96 112"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Create New Portfolio</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1280-Menu-Copy-11"
          transform="translate(-432.000000, -296.000000)"
        >
          <g id="Group-13" transform="translate(320.000000, 232.000000)">
            <g id="Group-7" transform="translate(112.000000, 64.000000)">
              <rect
                id="Rectangle"
                fillOpacity="0"
                fillRule="nonzero"
                x="0"
                y="0"
                width="96"
                height="112"
                rx="4"
              ></rect>
              <g
                id="Group-4"
                transform="translate(13.000000, 22.000000)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path
                  d="M5.85432826,0.638186567 L0.179568265,10.0113512 C-0.0585159702,10.4192891 -0.0599374701,10.9215869 0.175834126,11.3308375 C0.411605722,11.7400882 0.849157798,11.9948227 1.32524,12 L12.67476,12 C13.1508422,11.9948227 13.5883943,11.7400882 13.8241659,11.3308375 C14.0599375,10.9215869 14.058516,10.4192891 13.8204317,10.0113512 L8.14567174,0.638186567 C7.90275873,0.241969057 7.46837618,0 7,0 C6.53162382,0 6.09724127,0.241969057 5.85432826,0.638186567 Z"
                  id="Path"
                  stroke="#4381FF"
                ></path>
                <g
                  id="Group-3"
                  transform="translate(42.000000, 5.000000)"
                  fillRule="nonzero"
                  className={classes.stroke}
                >
                  <line
                    x1="0"
                    y1="1.477168"
                    x2="29.0000008"
                    y2="1.52042863"
                    id="Line"
                  ></line>
                </g>
                <g
                  id="Group-5"
                  transform="translate(22.000000, 0.000000)"
                  className={classes.stroke}
                >
                  <g id="percent-(1)">
                    <line
                      x1="11.25"
                      y1="0.75"
                      x2="0.75"
                      y2="11.25"
                      id="Path"
                    ></line>
                    <circle id="Oval" cx="1.875" cy="1.875" r="1.875"></circle>
                    <circle
                      id="Oval"
                      cx="10.125"
                      cy="10.125"
                      r="1.875"
                    ></circle>
                  </g>
                </g>
                <path
                  d="M5.85432826,28.6381866 L0.179568265,38.0113512 C-0.0585159702,38.4192891 -0.0599374701,38.9215869 0.175834126,39.3308375 C0.411605722,39.7400882 0.849157798,39.9948227 1.32524,40 L12.67476,40 C13.1508422,39.9948227 13.5883943,39.7400882 13.8241659,39.3308375 C14.0599375,38.9215869 14.058516,38.4192891 13.8204317,38.0113512 L8.14567174,28.6381866 C7.90275873,28.2419691 7.46837618,28 7,28 C6.53162382,28 6.09724127,28.2419691 5.85432826,28.6381866 Z"
                  id="Path"
                  stroke="#4381FF"
                ></path>
                <g
                  id="Group-3"
                  transform="translate(42.000000, 33.000000)"
                  fillRule="nonzero"
                  className={classes.stroke}
                >
                  <line
                    x1="0"
                    y1="1.477168"
                    x2="7.99999422"
                    y2="1.48678147"
                    id="Line"
                  ></line>
                </g>
                <g
                  id="Group-5"
                  transform="translate(22.000000, 28.000000)"
                  className={classes.stroke}
                >
                  <g id="percent-(1)">
                    <line
                      x1="11.25"
                      y1="0.75"
                      x2="0.75"
                      y2="11.25"
                      id="Path"
                    ></line>
                    <circle id="Oval" cx="1.875" cy="1.875" r="1.875"></circle>
                    <circle
                      id="Oval"
                      cx="10.125"
                      cy="10.125"
                      r="1.875"
                    ></circle>
                  </g>
                </g>
                <path
                  d="M5.85432826,56.6381866 L0.179568265,66.0113512 C-0.0585159702,66.4192891 -0.0599374701,66.9215869 0.175834126,67.3308375 C0.411605722,67.7400882 0.849157798,67.9948227 1.32524,68 L12.67476,68 C13.1508422,67.9948227 13.5883943,67.7400882 13.8241659,67.3308375 C14.0599375,66.9215869 14.058516,66.4192891 13.8204317,66.0113512 L8.14567174,56.6381866 C7.90275873,56.2419691 7.46837618,56 7,56 C6.53162382,56 6.09724127,56.2419691 5.85432826,56.6381866 Z"
                  id="Path"
                  stroke="#4381FF"
                ></path>
                <line
                  x1="42"
                  y1="62.477168"
                  x2="59.999987"
                  y2="62.4987983"
                  id="Line"
                  className={classes.stroke}
                  fillRule="nonzero"
                ></line>
                <g
                  id="Group-5"
                  transform="translate(22.000000, 56.000000)"
                  className={classes.stroke}
                >
                  <g id="percent-(1)">
                    <line
                      x1="11.25"
                      y1="0.75"
                      x2="0.75"
                      y2="11.25"
                      id="Path"
                    ></line>
                    <circle id="Oval" cx="1.875" cy="1.875" r="1.875"></circle>
                    <circle
                      id="Oval"
                      cx="10.125"
                      cy="10.125"
                      r="1.875"
                    ></circle>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(NewIllustration);
