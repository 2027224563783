import React from 'react';
import PropTypes from 'prop-types';
import { Glyph as CustomGlyph, GlyphCircle } from '@visx/glyph';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Spring } from 'react-spring/renderprops.cjs';

const useStyles = makeStyles((theme) => ({
  portfolio: {
    fill: 'transparent',
    stroke: theme.palette.primary.main,
    strokeWidth: 1,
  },
  portfolioDisabled: {
    fill: 'transparent',
    stroke: theme.palette.text.secondary,
    cursor: 'pointer',
    opacity: 0.8,
  },
}));

function PortfolioTicker({
  animate,
  tag,
  selected,
  top,
  left,
  onMouseOver,
  onMouseOut,
  onClick,
}) {
  const classes = useStyles();
  const theme = useTheme();
  if (!top || !left) return true;

  const renderGlyph = (top, left) => {
    return (
      <CustomGlyph
        top={top}
        left={left}
        className={selected ? classes.portfolio : classes.portfolioDisabled}
      >
        <GlyphCircle size={800} fill={'inherit'} />
        <text
          fontSize={16}
          fontWeight={500}
          textAnchor="middle"
          dy={5}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          fill={
            selected ? theme.palette.primary.main : theme.palette.text.secondary
          }
          stroke="none"
        >
          {tag}
        </text>
      </CustomGlyph>
    );
  };

  return (
    <svg onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {animate ? (
        <Spring to={{ top: top, left: left }}>
          {(props) => renderGlyph(props.top, props.left)}
        </Spring>
      ) : (
        renderGlyph(top, left)
      )}
    </svg>
  );
}

PortfolioTicker.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  animate: PropTypes.bool,
  tag: PropTypes.string,
  selected: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClick: PropTypes.func,
};

PortfolioTicker.defaultProps = {
  animate: false,
};

export default React.memo(PortfolioTicker);
