import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { observer } from 'mobx-react';
import { useStore } from '../../store';
import { truncate } from '../../utils/helpers';

export const SearchIcon = (props) => {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <path
        d="M11,2.5 C15.6944204,2.5 19.5,6.30557963 19.5,11 C19.5,13.1675829 18.6886499,15.1456648 17.3530798,16.6471157 L21.3535534,20.6464466 C21.5488155,20.8417088 21.5488155,21.1582912 21.3535534,21.3535534 C21.179987,21.5271197 20.9105626,21.5464049 20.7156945,21.4114088 L20.6464466,21.3535534 L16.6471157,17.3530798 C15.1456648,18.6886499 13.1675829,19.5 11,19.5 C6.30557963,19.5 2.5,15.6944204 2.5,11 C2.5,6.30557963 6.30557963,2.5 11,2.5 Z M11,3.5 C6.85786438,3.5 3.5,6.85786438 3.5,11 C3.5,15.1421356 6.85786438,18.5 11,18.5 C13.0380729,18.5 14.8862754,17.6870685 16.2380402,16.3677731 C16.2544523,16.3425577 16.2741799,16.3187133 16.2964466,16.2964466 C16.3187133,16.2741799 16.3425577,16.2544523 16.36762,16.237264 C17.6870685,14.8862754 18.5,13.0380729 18.5,11 C18.5,6.85786438 15.1421356,3.5 11,3.5 Z"
        id="Combined-Shape"
      ></path>
    </SvgIcon>
  );
};

function TickerSearch({ onSelect }) {
  const [term, setTerm] = useState('');
  const store = useStore();
  const search = store.symbolsSearch;

  const handleSelect = (e, v) => {
    if (v) {
      onSelect(v);
      search.clear();
    } else if (v === null) {
      search.clear();
    }
    setTerm('');
  };

  const handleTextChange = (event) => {
    const { value } = event.target;
    search.setTerm(value);
    setTerm(value);
  };

  const handleInputChange = (e, v) => {
    if (!v) {
      search.clear();
      setTerm('');
    }
  };

  return (
    <Autocomplete
      autoHighlight
      clearOnEscape
      freeSolo
      options={search.symbols}
      loading={search.isLoading}
      getOptionLabel={(option) => option.symbol || ''}
      renderOption={(option) => (
        <Grid container spacing={3}>
          <Grid item xs={3} md={2}>
            <Box color="primary.light" clone>
              <Typography style={{ fontWeight: 500 }}>
                {option.symbol}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9} md={10}>
            {truncate(option.name, 70)}
          </Grid>
        </Grid>
      )}
      filterOptions={(options) => options}
      noOptionsText="No results"
      inputValue={term}
      onChange={handleSelect}
      onInputChange={handleInputChange}
      renderInput={(params) => {
        const enhancedParams = merge(params, {
          inputProps: { 'data-hj-allow': '' },
        });
        return (
          <TextField
            {...enhancedParams}
            onChange={handleTextChange}
            InputProps={{
              ...enhancedParams.InputProps,
              startAdornment: (
                <InputAdornment position="start" style={{ paddingLeft: 4 }}>
                  <SearchIcon fontSize={'default'} />
                </InputAdornment>
              ),
            }}
            label="Add Asset to Portfolio"
            variant="outlined"
            autoFocus
          />
        );
      }}
    />
  );
}

TickerSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

TickerSearch.defaultProps = {};

export default observer(TickerSearch);
