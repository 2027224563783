import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Box from '@material-ui/core/Box';

function Message({ children, warning, error }) {
  const theme = useTheme();
  let bgcolor = fade(theme.palette.text.primary, 0.02);
  let color = fade(theme.palette.text.primary, 0.6);

  if (warning) {
    bgcolor = fade(theme.palette.warning.main, 0.1);
    color = fade(theme.palette.warning.main, 1);
  }

  if (error) {
    bgcolor = fade(theme.palette.error.main, 0.1);
    color = fade(theme.palette.error.main, 1);
  }

  return (
    <Box
      px={1}
      height={48}
      width={272}
      borderRadius={4}
      bgcolor={bgcolor}
      color={color}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {children}
    </Box>
  );
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
  warning: PropTypes.bool,
  error: PropTypes.bool,
};

Message.defaultProps = {
  warning: false,
  error: false,
};

export default Message;
