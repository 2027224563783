import { createMuiTheme } from '@material-ui/core/styles';
import { amber, deepPurple, common } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const dark = {
  palette: {
    type: 'dark',
    text: {
      secondary: fade('#C3C9D8', 0.7),
    },
    primary: {
      main: '#4381ff',
    },
    success: {
      light: '#62D0A0',
      main: '#299E71',
      dark: '#293F3B',
    },
    warning: {
      main: '#BA9408',
    },
    error: {
      main: '#D65959',
      dark: '#613641',
    },
    background: {
      default: '#131317',
      paper: '#1B1B22',
      drawer: '#15161A',
    },
    divider: fade('#838398', 0.12),
    secondary: amber,
    scope: {
      axis: '#445371',
      benchmark: '#8E929D',
      trend: {
        positive: '#7DF9FF',
        negative: '#f73378',
      },
    },
    chart: {
      compare: deepPurple[500],
    },
    tooltip: {
      background: 'rgba(14, 27, 52, 0.45)',
      full: 'rgba(14, 27, 52, 1)',
    },
  },
  typography: {
    fontFamily: 'Rubik, Roboto, Arial, sans-serif',
    useNextVariants: true,
  },
  sizes: {
    appMenuWidth: 72,
    appBarHeight: 64,
    dataAreaMaxWidth: 680,
    chartHeight: 380,
  },
  blur: 'blur(8px)',
  shadow: `0 0 2px rgba(255, 255, 255, 0.06)`,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },
        '*::-webkit-scrollbar-thumb': {
          boxShadow: `0 0 9px 0 rgba(0,0,0,.3)`,
          background: `rgba(0,0,0,.3)`,
          borderRadius: `4px`,
        },
        '*::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(255,255,255, 0.04)',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

export const light = {
  palette: {
    type: 'light',
    text: {
      secondary: fade('#1E2231', 0.7),
    },
    primary: {
      main: '#4381ff',
    },
    success: {
      main: '#34a853',
    },
    warning: {
      main: '#fbbc04',
    },
    error: {
      main: '#ea4335',
    },
    background: {
      default: '#fafafa', //'#F0F2F5',
      paper: common.white,
      drawer: '#F0F2F5',
    },
    divider: fade('#000', 0.05),
    secondary: amber,
    scope: {
      axis: '#4381ff',
      benchmark: '#4381ff',
      trend: {
        positive: '#4381ff',
        negative: '#b2102f',
      },
    },
    chart: {
      compare: deepPurple[500],
    },
    tooltip: {
      background: 'rgba(255,255,255,0.47)',
      full: '#DAE2F0',
    },
  },
  typography: {
    fontFamily: 'Rubik, Roboto, Arial, sans-serif',
    useNextVariants: true,
  },
  sizes: {
    appMenuWidth: 72,
    appBarHeight: 64,
    dataAreaMaxWidth: 680,
    chartHeight: 380,
  },
  blur: 'blur(8px)',
  shadow: `0 0 2px rgba(0, 0, 0, 0.12)`,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },
        '*::-webkit-scrollbar-thumb': {
          boxShadow: `0 0 9px 0 rgba(0,0,0,.3)`,
          background: `rgba(0,0,0,.3)`,
          borderRadius: `4px`,
        },
        '*::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      },
    },
    MuiTableCell: {
      root: {
        // borderBottom: '1px solid rgba(255,255,255, 0.04)',
        borderBottom: '1px solid rgba(0,0,0, 0.04)',
      },
    },
    MuiListItem: {
      divider: {
        // borderBottom: '1px solid rgba(255,255,255, 0.04)',
        borderBottom: '1px solid rgba(0,0,0, 0.06)',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

export default createMuiTheme(dark);
