import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
// import Container from '@material-ui/core/Container';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AutoIcon from '@material-ui/icons/BrightnessAutoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { MoonIcon, SunIcon } from '../../components/AppIcons';
import GoogleSignInButton from '../../components/GoogleSignInButton';

import { useStore } from '../../store';

const useStyles = makeStyles((theme) => ({
  groupButton: {
    color: theme.palette.text.secondary,
  },
  root: { paddingTop: theme.spacing(3) },
  selected: {
    color: theme.palette.text.primary + ' !important',
    background: fade(theme.palette.primary.main, 0.17) + ' !important',
  },
}));

export function Account({ onClose }) {
  const classes = useStyles();
  const store = useStore();
  const { themeType, user, profile } = store;

  const { expertMode, isAutoTheme, assetTrend, crowdedScope, dynamicCash } =
    profile || {};

  const theme = () => (isAutoTheme ? 'auto' : themeType);

  const handleToggle = (e) => {
    profile.setExpertMode(e.target.checked);
  };

  const handleAssetTrendToggle = (e) => {
    profile.setAssetTrend(e.target.checked);
  };

  const handleCrowdedScopeToggle = (e) => {
    profile.setCrowdedScope(e.target.checked);
  };

  const handleLogout = () => {
    user.logout();
    store.setBackdrop(true);
    onClose();
  };

  const handleLogin = async () => {
    store.setBackdrop(true);
    await user.migrate();
    store.setBackdrop(false);
  };

  const handleThemeChange = (e, value) => {
    if (value === 'auto') {
      store.setAutoTheme(true);
    } else if (value) {
      store.setAutoTheme(false);
      store.setTheme(value);
    }
  };

  const handleDynamicCashToggle = (e) => {
    profile.setDynamicCash(e.target.checked);
  };

  const groupButtonClasses = {
    root: classes.groupButton,
    selected: classes.selected,
  };

  if (!profile) return false;

  return (
    // <Container maxWidth={'sm'} className={classes.root}>
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <List subheader={<ListSubheader>Account</ListSubheader>}>
          <ListItem>
            <ListItemText
              primary={user.isAnon ? 'Guest' : user.displayName}
              secondary={
                user.isAnon ? 'Login to enable all features' : user.email
              }
            ></ListItemText>
            <ListItemSecondaryAction>
              <Button color="primary" onClick={handleLogout}>
                Sign out
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          {user.isAnon && (
            <ListItem>
              <GoogleSignInButton fullWidth onClick={handleLogin} />
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item>
        <List subheader={<ListSubheader>Settings</ListSubheader>}>
          <ListItem disabled={user.isAnon}>
            <ListItemText
              primary="Theme"
              secondary={'Choose between dark, light or auto'}
            ></ListItemText>
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                value={theme()}
                exclusive
                onChange={handleThemeChange}
              >
                <ToggleButton
                  disabled={user.isAnon}
                  value="dark"
                  classes={groupButtonClasses}
                >
                  <MoonIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton
                  disabled={user.isAnon}
                  value="auto"
                  classes={groupButtonClasses}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title="Automatically switch(based on OS settings)"
                  >
                    <AutoIcon fontSize="small" />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  disabled={user.isAnon}
                  value="light"
                  classes={groupButtonClasses}
                >
                  <SunIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disabled={user.isAnon}>
            <ListItemText
              primary="Expert Mode"
              secondary={'Add assets with less than 5 years of data'}
            ></ListItemText>
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={handleToggle}
                checked={expertMode}
                inputProps={{ 'aria-labelledby': 'switch-expert-mode' }}
                disabled={user.isAnon}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disabled={user.isAnon}>
            <ListItemText
              primary="Asset Trend"
              secondary={'Indicate asset trend on the scope'}
            ></ListItemText>
            <ListItemSecondaryAction>
              <Switch
                disabled={user.isAnon}
                edge="end"
                onChange={handleAssetTrendToggle}
                checked={assetTrend}
                inputProps={{ 'aria-labelledby': 'switch-asset-trend' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disabled={user.isAnon}>
            <ListItemText
              disabled={user.isAnon}
              primary="Crowded Scope"
              secondary={'Show all portfolios on the scope at the same time'}
            ></ListItemText>
            <ListItemSecondaryAction>
              <Switch
                disabled={user.isAnon}
                edge="end"
                onChange={handleCrowdedScopeToggle}
                checked={crowdedScope}
                inputProps={{ 'aria-labelledby': 'switch-crowded-scope' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disabled={user.isAnon}>
            <ListItemText
              primary="Dynamic cash"
              secondary={'Reduce cash amount to keep 100% portfolio allocation'}
            ></ListItemText>
            <ListItemSecondaryAction>
              <Switch
                disabled={user.isAnon}
                edge="end"
                onChange={handleDynamicCashToggle}
                checked={dynamicCash}
                inputProps={{ 'aria-labelledby': 'switch-dynamic-cash' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </Grid>
    // </Container>
  );
}

Account.propTypes = {
  onClose: PropTypes.func,
};

Account.defaultProps = {
  onClose: () => {},
};

export default observer(Account);
