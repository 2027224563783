import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';

import FileCopyIcon from '@material-ui/icons/FileCopyTwoTone';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import PortfolioTag from '../PortfolioTag';
import BenchmarkTag from '../BenchmarkTag';
import { Spring, animated, config } from 'react-spring/renderprops.cjs';

import {
  CagrStdev,
  Alpha,
  Sharpe,
  Sortino,
  UpsideDownside,
} from '../Indicator';
import { Asset } from '../../store';

import { styled } from '@material-ui/core/styles';

const Ticker = styled(Typography)({
  textTransform: 'uppercase',
  letterSpacing: 1,
  lineHeight: 1.25,
  fontStyle: (props) => (props.imported === 'y' ? 'italic' : 'normal'),
});

const useStyles = makeStyles((theme) => ({
  input: {
    width: 75,
  },
  paper: {
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
    background: 'transparent',
  },
  selected: {
    background: theme.palette.background.paper,
    backdropFilter: theme.blur,
    color: 'black',
  },
  selectedIndicator: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: theme.spacing(0.5),
    background: theme.palette.primary.main,
  },
}));

function PortfolioTableRow({
  tag,
  imported,
  ticker,
  loading,
  metrics,
  benchmark,
  selected,
  shared,
  onSelect,
  onRename,
  metricsReady,
  onCopy,
  ...rest
}) {
  const classes = useStyles();

  const renderMetrics = () => {
    const {
      expectedReturn,
      stdDev,
      sharpe,
      sortino,
      upsideCapture,
      downsideCapture,
      alpha,
    } = metrics;

    return (
      <Grid container spacing={1}>
        <Grid item>
          <UpsideDownside
            upsideCapture={upsideCapture}
            downsideCapture={downsideCapture}
            neutral={benchmark}
            ready={metricsReady || benchmark}
          />
        </Grid>
        <Grid item>
          <CagrStdev
            cagr={expectedReturn}
            stdDev={stdDev}
            neutral={benchmark}
            ready={metricsReady || benchmark}
          />
        </Grid>
        <Grid item>
          <Alpha
            alpha={alpha}
            neutral={benchmark}
            ready={metricsReady || benchmark}
          />
        </Grid>
        <Grid item>
          <Sharpe
            sharpe={sharpe}
            neutral={benchmark}
            ready={metricsReady || benchmark}
          />
        </Grid>
        <Grid item>
          <Sortino
            sortino={sortino}
            neutral={benchmark}
            ready={metricsReady || benchmark}
          />
        </Grid>
      </Grid>
    );
  };

  const renderLoading = () => {
    return (
      <Box width={270}>
        <LinearProgress />
      </Box>
    );
  };

  return (
    <Paper
      className={classNames(classes.paper, {
        [classes.selected]: selected,
      })}
      elevation={selected ? 4 : 0}
    >
      <ListItem
        button={!!onSelect}
        onClick={onSelect ? onSelect : () => {}}
        onDoubleClick={onRename}
        {...rest}
      >
        <Spring
          from={{ transform: 'translateX(-4px)' }}
          to={{
            transform: selected ? 'translateX(0)' : 'translateX(-4px)',
          }}
          config={{ ...config.stiff }}
        >
          {(sp) => (
            <animated.div className={classes.selectedIndicator} style={sp} />
          )}
        </Spring>

        <Grid container direction={'row'} alignItems={'center'} spacing={2}>
          <Grid item>
            {tag ? (
              <PortfolioTag tag={tag} selected={selected} />
            ) : (
              <BenchmarkTag />
            )}
          </Grid>

          <Grid xs item>
            <Ticker
              imported={imported ? 'y' : 'n'}
              color={selected ? 'primary' : 'textSecondary'}
            >
              {ticker}
            </Ticker>
          </Grid>
          <Grid item>{loading ? renderLoading() : renderMetrics()}</Grid>
          <Grid item>
            <Box width={40} display={'flex'} justifyContent="center">
              {selected && !shared && (
                <Tooltip title={'Duplicate Portfolio'} placement="top" arrow>
                  <IconButton onClick={onCopy}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </Paper>
  );
}

PortfolioTableRow.propTypes = {
  tag: PropTypes.string,
  asset: PropTypes.instanceOf(Asset),
  imported: PropTypes.bool,
  ticker: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  benchmark: PropTypes.bool,
  selected: PropTypes.bool,
  shared: PropTypes.bool,
  onSelect: PropTypes.func,
  onRename: PropTypes.func,
  metricsReady: PropTypes.bool,
  metrics: PropTypes.shape({
    expectedReturn: PropTypes.number,
    stdDev: PropTypes.number,
    sharpe: PropTypes.number,
    sortino: PropTypes.number,
    upsideCapture: PropTypes.number,
    downsideCapture: PropTypes.number,
    alpha: PropTypes.number,
  }),
  onRemove: PropTypes.func,
  onCopy: PropTypes.func,
};

PortfolioTableRow.defaultProps = {
  imported: false,
  loading: false,
  benchmark: false,
  selected: false,
};

export default React.memo(PortfolioTableRow);
