import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';

import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import PortfolioMetrics from '../PortfolioMetrics';
import PortfolioAssetsPreview from '../PortfolioAssetsPreview';
import PortfolioSymbol from '../PortfolioSymbol';

import Report from './report.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: theme.sizes.dataAreaMaxWidth,
  },
  formControl: {
    width: '100%',
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightBorder: {
    borderRightWidth: 1,
    borderRightColor: theme.palette.divider,
    borderRightStyle: 'dashed',
  },
}));

function PortfolioCompare({ compare, onChange, portfolio, comparePortfolios }) {
  const classes = useStyles();

  const renderSelect = () => (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="portfolio-compare-label">Compare to</InputLabel>
      <Select
        labelId="portfolio-compare-label"
        id="portfolio-compare-select"
        value={compare ? compare : ''}
        onChange={onChange}
        label="Compare to"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {comparePortfolios.length > 0 &&
          comparePortfolios.map((cp) => (
            <MenuItem key={cp.id} value={cp}>
              {cp.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );

  return (
    <Grid container direction="column" spacing={3} className={classes.root}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs>
            {renderSelect()}
          </Grid>
          <Grid item>
            <Tooltip title={'Export Report'} arrow placement="top">
              <Box>
                <IconButton
                  color="primary"
                  aria-label="delete"
                  className={classes.margin}
                  disabled={!compare}
                  onClick={() => window.open(Report)}
                >
                  <SaveAltIcon fontSize="large" />
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={3}>
        <Grid className={classes.rightBorder} item sm={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item className={classes.name}>
              <PortfolioSymbol
                name={portfolio.name}
                tag={portfolio.tag}
                selected
              />
            </Grid>
            <Grid item>
              <PortfolioMetrics
                metricsReady={portfolio.metricsReady}
                expectedReturn={portfolio.metrics.expectedReturn}
                stdDev={portfolio.metrics.stdDev}
                alpha={portfolio.metrics.alpha}
                sharpe={portfolio.metrics.sharpe}
                sortino={portfolio.metrics.sortino}
                upsideCapture={portfolio.metrics.upsideCapture}
                downsideCapture={portfolio.metrics.downsideCapture}
              />
            </Grid>
            <Grid item>
              <PortfolioAssetsPreview assets={portfolio.previewAssets} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          {compare && (
            <Grid container direction="column" spacing={3}>
              <Grid item className={classes.name}>
                <PortfolioSymbol
                  name={compare.name}
                  tag={compare.tag}
                  compared
                />
              </Grid>
              <Grid item>
                <PortfolioMetrics
                  metricsReady={compare.metricsReady}
                  expectedReturn={compare.metrics.expectedReturn}
                  stdDev={compare.metrics.stdDev}
                  alpha={compare.metrics.alpha}
                  sharpe={compare.metrics.sharpe}
                  sortino={compare.metrics.sortino}
                  upsideCapture={compare.metrics.upsideCapture}
                  downsideCapture={compare.metrics.downsideCapture}
                />
              </Grid>
              <Grid item>
                <PortfolioAssetsPreview assets={compare.previewAssets} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

PortfolioCompare.propTypes = {
  compare: PropTypes.any,
  portfolio: PropTypes.object,
  comparePortfolios: PropTypes.array,
  onChange: PropTypes.func,
};

PortfolioCompare.defaultProps = {};

export default React.memo(PortfolioCompare);
