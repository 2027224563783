import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Caption from '../Caption';
import { Asset } from '../../store';
import { numRound } from '../../../common/utils/helpers';
import SectionHeading from '../SectionHeading';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    borderBottom: 'none',
  },
  highlighthed: {
    '&>*': {
      color: theme.palette.secondary.main,
    },
  },
}));

function EtfDetail({ asset, highlight }) {
  const classes = useStyles();
  const { description, fundTopHoldings } = asset;

  const totalTopHoldings = numRound(
    fundTopHoldings.reduce((acc, s) => {
      return acc + s.percent;
    }, 0) * 100,
  );

  return (
    <Grid container spacing={6} direction={'column'}>
      <Grid item hidden={fundTopHoldings.length < 1}>
        <SectionHeading>Top 10 Holdings</SectionHeading>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Caption align="left">symbol</Caption>
                </TableCell>
                <TableCell>
                  <Caption align="left">name</Caption>
                </TableCell>
                <TableCell>
                  <Caption align="left">% Assets</Caption>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fundTopHoldings.map((s) => (
                <TableRow
                  key={s.symbol}
                  className={classNames({
                    [classes.highlighthed]: highlight.includes(s.symbol),
                  })}
                >
                  <TableCell>{s.symbol}</TableCell>
                  <TableCell>{s.name}</TableCell>
                  <TableCell>{numRound(s.percent * 100)}%</TableCell>
                </TableRow>
              ))}
              <TableRow key={'total'}>
                <TableCell className={classes.noBorder}>TOTAL</TableCell>
                <TableCell className={classes.noBorder}></TableCell>
                <TableCell className={classes.noBorder}>
                  {totalTopHoldings}%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <SectionHeading>About Fund</SectionHeading>
        <Typography variant={'body2'} color={'textSecondary'}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}

EtfDetail.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
  highlight: PropTypes.array,
};

EtfDetail.defaultProps = {
  highlight: [],
};

export default React.memo(EtfDetail);
