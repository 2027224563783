import { computed, observable, action, flow } from 'mobx';
import { axis_op, toPlainArray } from '../empyrical-js/utils';
import { calculateMetrics, dataframeComparer } from './helpers';
import { numRoundF, truncate } from '../../common/utils/helpers';

import Portfolio from './Portfolio';

class Mixer {
  @observable _profile = null;
  @observable _merge = [];

  constructor(profile) {
    this._profile = profile;
  }

  /* Selectors
   * ---------------------------------------------------------------------------
   */
  @computed get portfoliosIds() {
    return this._merge.map((x) => x.id);
  }

  @computed.struct get weights() {
    const out = {};
    for (const x of this._merge) {
      out[x.id] = x.allocation;
    }
    return out;
  }

  @computed.struct get assets() {
    return this._merge.reduce((acc, x) => {
      const found = this._profile.portfolios.find((p) => p.id === x.id);
      if (found && !acc.find((y) => y.symbol === found.symbol)) {
        for (const a of found.assets) {
          const fa = acc.find((z) => z.symbol === a.symbol);
          if (fa) {
            fa.allocation += a.allocation * (x.allocation / 100);
          } else {
            acc.push({
              id: a.id,
              label: a.label,
              symbol: a.symbol,
              allocation: a.allocation * (x.allocation / 100),
              returns: a.returns,
            });
          }
        }
      }
      return acc;
    }, []);
  }

  @computed.struct get assetsSortedByAllocation() {
    return this.assets.sort((a, b) => b.allocation - a.allocation);
  }

  @computed({ equals: dataframeComparer }) get returns() {
    let returns = null;

    for (const a of this.assets) {
      const { allocation } = a;
      if (!a.returns || allocation === 0) continue;

      const index = a.returns.index;
      const mask = index.map((idx) =>
        idx.isBetween(this._profile.since, this._profile.till, undefined, '[]'),
      );
      const slice = a.returns.filter(mask);
      const reduced = axis_op(slice, (f) => f.mul(allocation / 100));

      if (!returns) {
        returns = reduced;
      } else {
        returns = axis_op(returns, (f) => f.add(reduced.get('price')));
      }
    }

    return returns;
  }

  @computed get metricsReady() {
    const cash = this.assets.find((a) => a.isCash === true);
    return !cash || cash.allocation !== 100;
  }

  @computed.struct get metrics() {
    return calculateMetrics(
      this.returns,
      this._profile.benchmark.returns,
      this._profile.since,
      this._profile.till,
    );
  }

  @computed get totalAllocation() {
    return this._merge.reduce((acc, x) => {
      if (!x.allocation) return acc;
      return acc + Number(x.allocation);
    }, 0);
  }

  @computed get allocationFull() {
    return numRoundF(this.totalAllocation) === numRoundF(100);
  }

  @computed get correctSelection() {
    return this.portfoliosIds.length > 1;
  }

  @computed get ready() {
    return this.correctSelection && this.allocationFull;
  }

  @computed get name() {
    let nameKey = 'tag';
    if (this.portfoliosIds.length < 3) {
      nameKey = 'name';
    }
    const name = this.portfoliosIds
      .map((x) => this._profile.portfolios.find((p) => p.id === x)[nameKey])
      .join(' + ');

    return truncate(name, 32);
  }

  /* Actions
   * ---------------------------------------------------------------------------
   */
  @action togglePortfolio(portfolio, allocation = 0) {
    if (!(portfolio instanceof Portfolio)) {
      throw new Error(`'portfolio' must be an instance of Portfolio`);
    }

    const found = this._merge.find((x) => x.id === portfolio.id);

    if (found) {
      this._merge = this._merge.filter((p) => p.id !== portfolio.id);
    } else {
      this._merge.push({
        id: portfolio.id,
        allocation,
      });
    }

    if (allocation === 0) {
      for (const x of this._merge) {
        x.allocation = 100 / this._merge.length;
      }
    }
  }

  @action setAllocation(portfolio, allocation) {
    const found = this._merge.find((x) => x.id === portfolio.id);
    if (!found && allocation > 0) {
      this.togglePortfolio(portfolio, allocation);
    } else if (allocation > 0) {
      found.allocation = allocation;
    } else {
      this.togglePortfolio(portfolio);
    }
  }

  @action clear() {
    this._merge = [];
  }
}

export default Mixer;
