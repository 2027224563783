import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SmallIconButton from '../SmallIconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    transform: 'translateY(-1px)',
  },
}));

function PortfolioDateRange({ dateRange, constrained, onDialogOpen }) {
  const classes = useStyles();

  return (
    <Box
      px={2}
      display="flex"
      alignItems="center"
      color={constrained ? 'secondary.main' : 'text.primary'}
      className={classes.root}
    >
      <Typography component={'span'}>{dateRange}</Typography>
      {onDialogOpen && (
        <Box
          ml={1 / 2}
          color={constrained ? 'secondary.main' : 'text.secondary'}
          className={classes.icon}
        >
          <Tooltip title={'About Date Range'} arrow placement="top">
            <SmallIconButton
              onClick={onDialogOpen}
              aria-label="view warning"
              color="inherit"
            >
              {constrained ? (
                <WarningIcon fontSize="small" />
              ) : (
                <InfoOutlinedIcon fontSize="small" />
              )}
            </SmallIconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}

PortfolioDateRange.propTypes = {
  dateRange: PropTypes.string.isRequired,
  constrained: PropTypes.bool,
  onDialogOpen: PropTypes.func,
};

PortfolioDateRange.defaultProps = {};

export default React.memo(PortfolioDateRange);
