import React from 'react';
import PropTypes from 'prop-types';

import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import ChartAnalystRatingPie from './ChartAnalystRatingPie';

import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  buy: {
    color: theme.palette.success.main,
    textTransform: 'uppercase',
  },
  hold: {
    color: theme.palette.warning.main,
    textTransform: 'uppercase',
  },
  sell: {
    color: theme.palette.error.main,
    textTransform: 'uppercase',
  },
}));

function ChartAnalystRating({ data, company }) {
  const classes = useStyles();
  const theme = useTheme();
  const { consensus, ratings } = data;

  const width = 240;
  const height = 240;
  const radius = width / 2;
  const centerY = height / 2;
  const centerX = width / 2;

  const getRatingColor = scaleOrdinal({
    domain: ratings.map((r) => r.label),
    range: [
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ],
  });

  const ratingValue = (r) => r.value;

  const chartData = ratings.filter((r) => r.value > 0);
  const consensusWords = consensus.split(' ');
  const consensusStrong = consensusWords.length > 1;

  const total = ratings.reduce((acc, s) => {
    return acc + s.value;
  }, 0);

  if (total === 0) {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: '100%' }}
      >
        <Grid item>
          <Alert severity="info">Data not available</Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} direction={'column'} className={classes.root}>
      <Grid item>
        <Grid
          container
          spacing={4}
          direction={'column'}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <svg width={width} height={height}>
              <Group top={centerY} left={centerX}>
                <Pie
                  data={chartData}
                  pieValue={ratingValue}
                  outerRadius={radius - 20}
                  innerRadius={radius - 30}
                  padAngle={0.05}
                >
                  {(pie) => {
                    return (
                      <ChartAnalystRatingPie
                        {...pie}
                        animate={false}
                        getKey={({ data: { label } }) => label}
                        getColor={({ data: { label } }) =>
                          getRatingColor(label)
                        }
                      />
                    );
                  }}
                </Pie>
                <Pie
                  data={chartData}
                  pieValue={ratingValue}
                  outerRadius={radius}
                  innerRadius={radius - 45}
                  padAngle={0.05}
                >
                  {(pie) => {
                    return (
                      <ChartAnalystRatingPie
                        {...pie}
                        animate={false}
                        getKey={({ data: { label } }) => label}
                        getColor={({ data: { label } }) =>
                          fade(getRatingColor(label), 0.1)
                        }
                      />
                    );
                  }}
                </Pie>
              </Group>
              {consensusStrong && (
                <text
                  x={centerX}
                  y={centerY}
                  fontSize={theme.typography.body1.fontSize}
                  dy={-8}
                  textAnchor="middle"
                  pointerEvents="none"
                  style={{ textTransform: 'uppercase' }}
                >
                  {consensusWords[0]}
                </text>
              )}
              <text
                x={centerX}
                y={centerY}
                fontSize={theme.typography.h4.fontSize}
                dy={consensusStrong ? 24 : 12}
                textAnchor="middle"
                pointerEvents="none"
                style={{ textTransform: 'uppercase', fontWeight: 'medium' }}
              >
                {consensusStrong ? consensusWords[1] : consensus}
              </text>
            </svg>
          </Grid>
          <Grid
            item
            xs
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Grid container spacing={4} direction={'row'} wrap="nowrap">
              {ratings.map((r) => (
                <Grid key={r.label} item>
                  <Typography
                    variant={'subtitle2'}
                    component={'div'}
                    className={classes[r.label]}
                  >
                    {r.label}
                  </Typography>
                  <Typography variant={'h5'} component={'span'}>
                    {r.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ChartAnalystRating.propTypes = {
  data: PropTypes.shape({
    ratings: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    consensus: PropTypes.string,
    date: PropTypes.any,
  }),
  company: PropTypes.string,
};

ChartAnalystRating.defaultProps = {};

export default React.memo(ChartAnalystRating);
