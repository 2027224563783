import React from 'react';
import Placeholder from '../../components/Placeholder';
import { Grid } from '@material-ui/core';

class NotFound extends React.Component {
  render() {
    return (
      <Grid container justify="center" alignItems="center">
        <Placeholder text={'Page Not Found'} />
      </Grid>
    );
  }
}

export default NotFound;
