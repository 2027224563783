import { pp } from './helpers';
import { PORTFOLIO_TYPES } from '../store/constants';

const compose = (scale, accessor) => (data, key = null) =>
  scale(accessor(data, key));

export function getXPoint(xScale, x) {
  return compose(xScale, x);
}

export function getYPoint(yScale, y) {
  return compose(yScale, y);
}

export function getXMax(
  width = 0,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
) {
  return width - margin.left - margin.right;
}

export function getYMax(
  height = 0,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
) {
  return height - margin.top - margin.bottom;
}

export function formatAmount(v) {
  return `$${parseInt(Number(v)).toLocaleString()}`;
}

export function formatPercent(v) {
  return `${pp(v)}%`;
}

export function getKeys(data) {
  if (data.length === 0) return [];
  return Object.keys(data[0]).filter((d) => d !== 'date');
}

export function getColor(key, theme) {
  if (key === PORTFOLIO_TYPES.portfolio) return theme.palette.primary.main;
  if (key === PORTFOLIO_TYPES.benchmark) return theme.palette.text.primary;
  if (key === PORTFOLIO_TYPES.optimized) return theme.palette.success.main;
  if (key === PORTFOLIO_TYPES.compared) return theme.palette.chart.compare;
}
