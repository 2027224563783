const Immutable = require('immutable');
const Series = require('pandas-js').Series;
const DataFrame = require('pandas-js').DataFrame;
const moment = require('moment');

export function print_frame(frame, label = '') {
  const index = frame.index.toArray();
  const rows = frame.values.toArray().map((x) => x.get(0));
  const col = frame.columns.get(0);
  const out = [];
  for (let i = 0; i < rows.length; i++) {
    const r = rows[i];
    const idx = index[i];
    out.push({ date: idx.format ? idx.format('YYYY MM DD') : idx, [col]: r });
  }
  /* eslint-disable no-console */
  console.log(label);
  console.table(out);
  /* eslint-enable no-console */
}

export function group_by(frame, grouper, reducer) {
  const col = frame.columns.get(0);
  const index = frame.index.map(grouper);
  const values = frame.values;
  const grouped = {};
  for (let i = 0; i < index.size; i++) {
    const idx = index.get(i);
    const row = values.get(i);
    if (!grouped[idx]) {
      grouped[idx] = [];
    }

    grouped[idx].push(row.get(0));
  }

  const newIndex = Object.keys(grouped).map(Number);
  const out = [];
  for (let i = 0; i < newIndex.length; i++) {
    const idx = newIndex[i];
    const group = frame.filter(index.map((i) => i === idx));
    out.push({
      [col]: reducer(group).get(col).values.get(0),
    });
  }

  return new DataFrame(out, { index: newIndex });
}

export function replace(frame, iterBool, value) {
  if (
    !Array.isArray(iterBool) &&
    !(iterBool instanceof Immutable.List) &&
    !(iterBool instanceof Series)
  )
    throw new Error('filter must be an Array, List, or Series');

  if (Array.isArray(iterBool) && iterBool.length !== frame.length)
    throw new Error('Array must be of equal length to DataFrame');
  else if (iterBool instanceof Immutable.List && iterBool.size !== frame.length)
    throw new Error('List must be of equal length to DataFrame');
  else if (iterBool instanceof Series && iterBool.length !== frame.length)
    throw new Error('Series must be of equal length to DataFrame');

  // noinspection Eslint
  return new DataFrame(
    Immutable.Map(
      frame._data.mapEntries(([k, v]) => {
        return [k, v.map((x, idx) => (iterBool.values.get(idx) ? x : value))];
      }),
    ),
  );
}

export function axis_op(frame, func, name = null) {
  if (!frame) return null;

  if (frame.length === 0) {
    return frame;
  }
  if (name === null) {
    name = frame.columns.get(0);
  }
  return frame.set(name, func(frame.get(name)));
}

export function up(returns, factor_returns, opts = {}) {
  if (!opts.function) {
    return NaN;
  }
  const col = returns.columns.get(0);
  const mask = factor_returns.get(col).gt(0);

  returns = replace(returns, mask, 0);
  factor_returns = replace(factor_returns, mask, 0);

  return opts.function(returns, factor_returns, opts);
}

export function down(returns, factor_returns, opts = {}) {
  if (!opts.function) {
    return NaN;
  }

  const col = returns.columns.get(0);
  const mask = factor_returns.get(col).lt(0);

  returns = replace(returns, mask, 0);
  factor_returns = replace(factor_returns, mask, 0);

  return opts.function(returns, factor_returns, opts);
}

export function toPlainArray(frame, column = 'price') {
  if (!frame) return frame;

  const items = frame.to_json()[column];
  const keys = Object.keys(items);
  const out = [];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const date = moment(key);
    const value = items[key];

    out.push({ date, value });
  }

  return out;
}
