import React from 'react';
import PropTypes from 'prop-types';
import { AxisBottom } from '@visx/axis';

import { useTheme } from '@material-ui/core/styles';

function ChartXAxis({ top, scale, ...rest }) {
  const theme = useTheme();

  const tickLabelProps = () => ({
    fill: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    textAnchor: 'middle',
  });

  return (
    <AxisBottom
      hideAxisLine
      hideTicks
      top={top}
      scale={scale}
      tickLabelProps={tickLabelProps}
      {...rest}
    />
  );
}

ChartXAxis.propTypes = {
  top: PropTypes.number.isRequired,
  scale: PropTypes.any.isRequired,
};

export default React.memo(ChartXAxis);
