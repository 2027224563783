import { computed, observable, action } from 'mobx';
import { computedFn } from 'mobx-utils';
import {
  countDecimals,
  decodeHtmlUnicodeSequences,
} from '../../common/utils/helpers';
import { parseDate } from './helpers';
import Security from './Security';
import { CASH_SYM } from './constants';
import UniversalDocument from './UniversalDocument';

class Asset extends UniversalDocument {
  @observable _allocation = null;
  @observable _amount = null;
  @observable _options = null;
  @observable _security = null;
  @observable _createdAt = null;

  constructor(source, options) {
    super(source, options);
    this._options = options;
  }

  get security() {
    if (this._security) return this._security;

    this._security = Security.instance(
      this.symbol.toLowerCase(),
      this._options,
    );

    return this._security;
  }

  ready() {
    return Promise.all([this.security.ready(), super.ready()]);
  }

  @computed get hasData() {
    return (
      super.hasData && this.security.hasData && !!this.security.data.prices
    );
  }

  @computed get hasError() {
    return this.security.hasError;
  }

  @computed get isLoading() {
    return super.isLoading || this.security.isLoading;
  }

  @computed get createdAt() {
    if (this._createdAt !== null) {
      return this._createdAt;
    }
    const { createdAt } = this.data;
    return new Date(createdAt ? createdAt.seconds * 1000 : 0);
  }

  @computed get since() {
    return this.security.since;
  }

  @computed get till() {
    return this.security.till;
  }

  @computed get symbol() {
    return super.hasData ? this.data.symbol : 'NANN';
  }

  @computed get isConstraining() {
    const { constrainer } = this._options;

    if (
      constrainer &&
      !constrainer.expertMode &&
      this.since.isAfter(constrainer.since)
    ) {
      return true;
    }

    return false;
  }

  @computed get allocation() {
    if (this.isConstraining) {
      return 0;
    }

    // prefer local copy if available
    if (this._allocation !== null) {
      return this._allocation;
    }

    return super.hasData ? this.data.allocation : 0;
  }

  @computed get optimizedAllocation() {
    return this.data.optAllocation || 0;
  }

  @computed get amount() {
    if (this.isConstraining) {
      return 0;
    }

    // prefer local copy if available
    if (this._amount !== null) {
      return this._amount;
    }

    return super.hasData ? this.data.amount : 0;
  }

  @computed get isCash() {
    return this.symbol === CASH_SYM;
  }

  @computed get label() {
    return this.isCash ? 'CASH' : this.symbol;
  }

  @computed get name() {
    return this.security.hasData ? this.security.name : 'N/A';
  }

  @computed get returns() {
    return this.security.returns;
  }

  @computed get metrics() {
    return this.security.metrics;
  }

  @computed.struct get metricsHistory() {
    const out = [];
    for (let i = 3; i >= 1; i--) {
      out.push(this.security._metrics(i));
    }

    return out;
  }

  _trend = computedFn(
    (key = 'expectedReturn') => {
      const a = this.metricsHistory[2][key];
      const b = this.metrics[key];
      const delta = Math.abs(a - b);
      if (delta < 0.01) {
        return 0;
      }

      return b - a;
    },
    { keepAlive: true },
  );

  @computed get stdTrend() {
    return this._trend('stdDev');
  }

  @computed get cagrTrend() {
    return this._trend('expectedReturn');
  }

  @computed get isLocked() {
    const { lock } = this.data;
    return typeof lock === 'boolean' ? lock : false;
  }

  @computed get cashFlow() {
    return this.security.data.cashFlow.map((c) => {
      return { amount: c.amount, date: parseDate(c.date) };
    });
  }

  @computed get revenueEarnings() {
    const { earnings } = this.security.data;
    if (!earnings || !earnings.yearly) {
      return false;
    }
    const { yearly } = earnings;

    return yearly.reduce((acc, x) => {
      const i = {
        revenue: x.revenue,
        earnings: x.earnings,
        date: parseDate(x.date),
      };
      const len = acc.length;
      if (len < 1 || acc[len - 1].date.year() !== i.date.year()) {
        acc.push(i);
      }
      return acc;
    }, []);
  }

  @computed get revenueEarningsChartData() {
    if (this.isEtf) return false;

    return (
      this.revenueEarnings &&
      this.revenueEarnings.map((re) => {
        const found = this.cashFlow.find((cf) =>
          re.date.isSame(cf.date, 'year'),
        );
        return {
          ...re,
          freeCashFlow: found ? found.amount : 0,
        };
      })
    );
  }

  @computed get estimateActualChartData() {
    if (this.isEtf) return false;

    return (
      this.security.data.earnings &&
      this.security.data.earnings.current &&
      this.security.data.earnings.perShare && [
        ...this.security.data.earnings.perShare.map((eps) => ({
          actual: eps.actual,
          estimate: eps.estimate,
          date: parseDate(eps.date),
        })),
        {
          current: true,
          estimate: this.security.data.earnings.current.estimate,
          date: parseDate(this.security.data.earnings.current.date),
        },
      ]
    );
  }

  @computed get analystRatingChartData() {
    if (this.isEtf) return false;

    const rec = this.security.data.recommendations;
    const reducer = (acc, i) => {
      if (!i) return acc;
      return acc + Number(i);
    };

    const buyCount = [rec.buy, rec.strongBuy].reduce(reducer, 0);
    const sellCount = [rec.sell, rec.strongSell].reduce(reducer, 0);
    const holdCount = [rec.hold].reduce(reducer, 0);

    const total = buyCount + sellCount + holdCount;

    const buyRatio = buyCount / total;
    const sellRatio = sellCount / total;

    const consensus =
      buyRatio >= 0.7 && sellRatio < 0.05
        ? 'strong buy'
        : buyRatio >= 0.6 && sellRatio < 0.1
        ? 'buy'
        : sellRatio >= 0.2
        ? 'sell'
        : 'hold';

    return (
      rec && {
        ratings: [
          {
            label: 'buy',
            value: buyCount,
          },
          {
            label: 'hold',
            value: holdCount,
          },
          {
            label: 'sell',
            value: sellCount,
          },
        ],
        consensus: consensus,
        date: parseDate(rec.consensusDate),
      }
    );
  }

  @computed get priceForecastChartData() {
    if (this.isEtf) return false;

    const { ttmMonthly } = this.security;
    const { estimates } = this.security.data.prices;

    return {
      prices: ttmMonthly.map((x) => ({
        ...x,
        date: parseDate(x.date),
      })),
      estimates: estimates,
    };
  }

  @computed get isEtf() {
    return this.security.isEtf;
  }

  @computed get isSupported() {
    return this.security._symbol.hasData;
  }

  @computed get description() {
    return decodeHtmlUnicodeSequences(this.security.data.description || '');
  }

  @computed get fundTopHoldings() {
    const { fundTopHoldings } = this.security.data;

    return fundTopHoldings || [];
  }

  @computed get hasTopHoldings() {
    const { fundTopHoldings } = this;
    if (fundTopHoldings.length === 0) {
      return false;
    }

    const n = fundTopHoldings.reduce((acc, x) => (x.symbol ? acc + 1 : acc), 0);
    return n > 0;
  }

  @computed get institutionalChartData() {
    if (this.isEtf) return false;

    const { institutionalHoldings } = this.security.data;
    return institutionalHoldings;
  }

  @action setAllocation(percentage) {
    if (percentage < 0 || percentage > 100) {
      throw new Error('Allocation should be in range 0-100');
    }

    this._allocation = percentage === '' ? percentage : Number(percentage);

    this._allocation =
      countDecimals(percentage) > 3
        ? Number(this._allocation.toFixed(3))
        : this._allocation;
    this.update({ allocation: this._allocation });
  }

  @action setAmount(amount) {
    this._amount = amount === '' ? amount : Number(amount);
    this.update({ amount: this._amount });
  }

  @action setLock(isLocked) {
    this.update({ lock: isLocked });
  }

  @action setCreatedAt(createdAt) {
    this._createdAt = createdAt;
    this.update({ createdAt });
  }
}

export default Asset;
