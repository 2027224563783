import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { onEnter } from '../../utils/helpers';

import DialogTransition from '../DialogTransition';

// const useStyles = makeStyles((theme) => ({}));

function RenamePortfolioDialog({ name, open, onClose, onRename }) {
  // const classes = useStyles();

  const [portfolioName, setPortfolioName] = useState(name);

  const handleNameChange = (e) => {
    setPortfolioName(e.target.value);
  };

  const handleRename = () => {
    onRename(portfolioName);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-portfolio-rename"
      fullWidth
      TransitionComponent={DialogTransition}
    >
      <DialogTitle>Rename Portfolio</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          value={portfolioName}
          inputProps={{ 'data-hj-allow': '' }}
          onChange={handleNameChange}
          onKeyPress={onEnter(handleRename)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleRename} color="primary">
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RenamePortfolioDialog.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
};

RenamePortfolioDialog.defaultProps = {
  name: '',
};

export default React.memo(RenamePortfolioDialog);
