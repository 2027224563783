import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import DialogTransition from '../DialogTransition';

import YoutubePlayer from '../YoutubePlayer';

const GUIDES = [
  {
    id: 1,
    title: 'Determine your Investor Profile and build your initial Portfolio.',
    videoId: 'TnQ7lk4wsuE',
  },
  {
    id: 2,
    title: `Evaluate the actual stock and analyze it's performance and financial metrics.`,
    videoId: 'gjLqc-gIXlY',
  },
  {
    id: 3,
    title: 'Learn about the Power of Cash and master Asset Allocation',
    videoId: 'TJegazPfAxA',
  },
  {
    id: 4,
    title: 'Evaluate ETFs, and learn the Direct Indexing approach.',
    videoId: 'cDk3RSYP8ho',
  },
  {
    id: 5,
    title:
      'Optimize your Portfolio for maximum returns with minimum volatility.',
    videoId: 'BBFvj9TT9wo',
  },
  {
    id: 6,
    title: 'Compare Portfolios and find one that works best for you.',
    videoId: 'gA29uRsfVVA',
  },
  {
    id: 7,
    title: 'Merge multiple Portfolios into one to get the balanced option.',
    videoId: 'g4QvAiJPm90',
  },
];

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
  },
  alert: {
    width: '100%',
    marginTop: 16,
  },
  li: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: 24,
    paddingTop: 32,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  card: {
    height: 468,
    width: 600,
    margin: '0 auto',
  },
}));

function GuidesDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={'lg'}
      onClose={onClose}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle>User Guides</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="row" spacing={4}>
          {GUIDES.map((g) => (
            <Grid item key={g.id} xs={12} lg={6}>
              <Card className={classes.card}>
                <YoutubePlayer videoId={g.videoId} />
                <CardContent>
                  <Typography variant="h6" component="p">
                    {g.id}. {g.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

GuidesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GuidesDialog.defaultProps = {};

export default React.memo(GuidesDialog);
