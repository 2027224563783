import moment from 'moment';
import {
  alpha,
  annual_return,
  annual_volatility,
  sortino_ratio,
  sharpe_ratio,
  up_capture,
  down_capture,
} from '../empyrical-js/stats';
import { RISK_FREE } from './constants';
import { isServer } from '../utils/env';

export function calculateMetrics(returns, benchmark_returns, since, till) {
  const opts = { period: 'monthly', risk_free: RISK_FREE };

  if (!returns) {
    return {
      expectedReturn: NaN,
      stdDev: NaN,
      sortino: NaN,
      sharpe: NaN,
      upsideCapture: NaN,
      downsideCapture: NaN,
      alpha: NaN,
    };
  }

  const index = returns.index;
  const mask = index.map((idx) => idx.isBetween(since, till, undefined, '[]'));

  returns = returns.filter(mask);

  if (!benchmark_returns) {
    benchmark_returns = returns;
  } else {
    const index = benchmark_returns.index;
    const mask = index.map((idx) =>
      idx.isBetween(since, till, undefined, '[]'),
    );

    benchmark_returns = benchmark_returns.filter(mask);
  }

  return {
    expectedReturn: annual_return(returns, opts),
    stdDev: annual_volatility(returns, opts),
    sortino: sortino_ratio(returns, opts),
    sharpe: sharpe_ratio(returns, opts),
    upsideCapture: up_capture(returns, benchmark_returns, opts),
    downsideCapture: down_capture(returns, benchmark_returns, opts),
    alpha: alpha(returns, benchmark_returns, { period: opts.period }),
  };
}

export function generateID() {
  // Alphanumeric characters
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  if (autoId.length !== 20) {
    throw new Error('Invalid auto ID: ' + autoId);
  }
  return autoId;
}

export function dataframeComparer(a, b) {
  if (isServer()) return false;

  if (!a && !b) return true;
  if (!a || !b) return false;
  if (!a.values && !b.values) return true;
  if (!a.values || !b.values) return false;
  if (!a.values.eq || !b.values.eq) return false;

  return a.values.eq(b.values);
}

export function momentComparer(a, b) {
  if (!a && !b) return true;
  if (!a || !b) return false;

  return a.isSame(b);
}

export function today() {
  return moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function parseDate(date) {
  return moment.unix(date.seconds || date._seconds).utc();
}

export function isValidSymbol(symbol) {
  return String(symbol).length > 1;
}
