import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Transition } from 'react-spring/renderprops.cjs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { withParentSize } from '@visx/responsive';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';

import { numRound } from '../../utils/helpers';
import { Portfolio, Security } from '../../store';
import ChartTooltip from '../ChartTooltip';

import AssetTicker from './AssetTicker';
import AssetTickerWithTrail from './AssetTickerWithTrail';
import BenchmarkTicker from './BenchmarkTicker';
import PortfolioTicker from './PortfolioTicker';
import OptimizedTicker from './OptimizedTicker';
import CompareTicker from './CompareTicker';

import AxisBase from './AxisBase';

const useStyles = makeStyles((theme) => ({
  root: {
    fill: theme.palette.primary.contrastText,
    position: 'relative',
    userSelect: 'none',
  },
  captions: {
    ...theme.typography.caption,
    textTransform: 'uppercase',
    fill: theme.palette.text.secondary,
  },
  hints: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  positiveTrendLabel: {
    color: theme.palette.scope.trend.positive,
  },
  negativeTrendLabel: {
    color: theme.palette.scope.trend.negative,
  },
  tooltipTitle: {
    fontWeight: 800,
  },
  axisSubtitle1: {
    fontSize: 12,
    textTransform: 'uppercase',
    textAnchor: 'end',
    fill: theme.palette.text.secondary,
  },
  axisSubtitle2: {
    fontSize: 12,
    textTransform: 'uppercase',
    fill: theme.palette.text.secondary,
  },
  watermark: {
    fontSize: 18,
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
    textTransform: 'uppercase',
    fill: fade(theme.palette.primary.main, 0.25),
    textAnchor: 'middle',
  },
  dangerWatermark: {
    fill: fade(theme.palette.error.main, 0.25),
  },
}));

function ChartScope({
  animate,
  parentWidth,
  parentHeight,
  benchmark,
  hasPortfolios,
  portfoliosList,
  selectedPortfolio,
  onSelect,
  assetTrend,
  compare,
}) {
  const classes = useStyles();
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const selected = selectedPortfolio || { id: null, assets: [] };

  const width = parentWidth;
  const height = parentHeight;

  const x = (a) =>
    isNaN(a.metrics.expectedReturn)
      ? 0
      : Math.abs(a.metrics.expectedReturn - benchmark.metrics.expectedReturn);

  const y = (a) =>
    isNaN(a.metrics.stdDev)
      ? 0
      : Math.abs(a.metrics.stdDev - benchmark.metrics.stdDev);

  const maxCagrDelta = Math.max(
    ...selectedPortfolio.assets.map(x),
    ...portfoliosList.map(x),
    compare ? x(compare) : 0,
    0,
  );

  const maxStDevDelta = Math.max(
    ...selectedPortfolio.assets.map(y),
    ...portfoliosList.map(y),
    compare ? x(compare) : 0,
    0,
  );

  const padding = Math.max(maxCagrDelta, maxStDevDelta) * 0.15;

  const domainEeMin = benchmark.metrics.expectedReturn + maxCagrDelta + padding;
  const domainEeMax = benchmark.metrics.expectedReturn - maxCagrDelta - padding;
  const domainStdevMin = benchmark.metrics.stdDev - maxStDevDelta - padding;
  const domainStdevMax = benchmark.metrics.stdDev + maxStDevDelta + padding;

  const eeScale = useCallback(
    scaleLinear({
      range: [0, height],
      domain: [domainEeMin, domainEeMax],
    }),
    [domainEeMin, domainEeMax, height],
  );

  const sdScale = useCallback(
    scaleLinear({
      range: [0, width],
      domain: [domainStdevMin, domainStdevMax],
    }),
    [domainStdevMin, domainStdevMax, width],
  );

  const trendScale = useCallback(
    scaleLinear({
      range: [-5, 5],
      domain: [-0.1, 0.1],
      clamp: true,
    }),
  );

  const yMid = eeScale(benchmark.metrics.expectedReturn);
  const xMid = sdScale(benchmark.metrics.stdDev);

  const animateFrom = {
    opacity: 0,
    transform: 'translate3d(0,-1rem,0)',
  };
  const animateEnter = {
    opacity: 1,
    transform: 'translate3d(0,0,0)',
  };
  const animateLeave = {
    opacity: 0,
    transform: 'translate3d(0,-1rem,0)',
  };
  const animationTrail = 100;

  const handleMouseOver = (event, datum, optimized = false) => {
    let title = datum.symbol ? datum.symbol : datum.name;
    let cagr = datum.metrics.expectedReturn;
    let std = datum.metrics.stdDev;

    if (optimized) {
      title = 'OPTIMIZED';
      cagr = datum.optimizedMetrics.expectedReturn;
      std = datum.optimizedMetrics.stdDev;
    }

    let tooltipData = {
      id: datum.id,
      title: title,
      cagr: numRound(cagr * 100),
      volatility: numRound(std * 100),
    };

    if (assetTrend) {
      if (datum.cagrTrend) {
        tooltipData.cagrTrend = numRound(datum.cagrTrend * 100);
      }
      if (datum.stdTrend) {
        tooltipData.stdTrend = numRound(datum.stdTrend * 100);
      }
    }

    showTooltip({
      tooltipLeft: sdScale(std),
      tooltipTop: eeScale(cagr) + 10,
      tooltipData: tooltipData,
    });
  };

  const handleSelectPortfolio = (portfolio) => {
    onSelect(portfolio);
  };

  const renderTrendLabel = (trend, inverted = false) => {
    return (
      <span
        className={classNames({
          [classes.positiveTrendLabel]: inverted || trend > 0,
          [classes.negativeTrendLabel]: inverted || trend < 0,
        })}
      >
        {trend > 0 ? '+' : ''}
        {trend}
      </span>
    );
  };

  const renderPortfolioTicker = (props, p, id, tag, expectedReturn, stdDev) =>
    p.metrics.expectedReturn &&
    stdDev && (
      <Group style={props} key={id}>
        <PortfolioTicker
          animate={animate}
          tag={tag}
          selected={selected.id === id}
          top={eeScale(expectedReturn)}
          left={sdScale(stdDev)}
          onMouseOver={(e) => handleMouseOver(e, p)}
          onMouseOut={hideTooltip}
          onClick={() => handleSelectPortfolio(p)}
        />
      </Group>
    );
  const renderCompareTicker = (props, p, stdDev) =>
    p.metrics.expectedReturn &&
    stdDev && (
      <Group style={props} key={p.id}>
        <CompareTicker
          animate={animate}
          tag={p.tag}
          top={eeScale(p.metrics.expectedReturn)}
          left={sdScale(stdDev)}
          onMouseOver={(e) => handleMouseOver(e, p)}
          onMouseOut={hideTooltip}
          onClick={() => handleSelectPortfolio(p)}
        />
      </Group>
    );

  const renderOptimizedTicker = (props, p) => (
    <Group style={props}>
      <OptimizedTicker
        name={p.tag}
        top={eeScale(p.optimizedMetrics.expectedReturn)}
        left={sdScale(p.optimizedMetrics.stdDev)}
        onMouseOver={(e) => handleMouseOver(e, p, true)}
        onMouseOut={hideTooltip}
      />
    </Group>
  );

  const renderZonesGroup = () => {
    return (
      <Group>
        <Group id="zones-labels">
          <text x={xMid / 2} y={yMid / 2} className={classes.watermark}>
            Moderate
          </text>

          <text x={xMid * 1.5} y={yMid / 2} className={classes.watermark}>
            Aggressive
          </text>
          <text x={xMid / 2} y={yMid * 1.5} className={classes.watermark}>
            Conservative
          </text>
          <text
            x={xMid * 1.5}
            y={yMid * 1.5}
            className={classNames(classes.watermark, classes.dangerWatermark)}
          >
            Danger Zone
          </text>
        </Group>
      </Group>
    );
  };

  return (
    <Box position={'relative'}>
      <svg className={classes.root} width={width} height={height}>
        {renderZonesGroup()}

        <AxisBase top={yMid} left={xMid} eeScale={eeScale} sdScale={sdScale} />
        <BenchmarkTicker
          benchmark={benchmark}
          top={yMid}
          left={xMid}
          onMouseOver={(e) => handleMouseOver(e, benchmark)}
          onMouseOut={hideTooltip}
        />
        {!compare &&
          hasPortfolios &&
          selectedPortfolio.assets &&
          selectedPortfolio.assets.map(
            (a) =>
              !a.isCash &&
              (assetTrend ? (
                <AssetTickerWithTrail
                  key={a.symbol}
                  animate={animate}
                  symbol={a.symbol}
                  top={eeScale(a.metrics.expectedReturn)}
                  left={sdScale(a.metrics.stdDev)}
                  onMouseOver={(e) => handleMouseOver(e, a)}
                  onMouseOut={hideTooltip}
                  trail={{
                    top: trendScale(a.cagrTrend),
                    left: trendScale(-a.stdTrend),
                  }}
                  // debugPoint={{
                  //   top: eeScale(a.metricsHistory[2].expectedReturn),
                  //   left: sdScale(a.metricsHistory[2].stdDev),
                  // }}
                />
              ) : (
                <AssetTicker
                  key={a.symbol}
                  animate={animate}
                  symbol={a.symbol}
                  top={eeScale(a.metrics.expectedReturn)}
                  left={sdScale(a.metrics.stdDev)}
                  onMouseOver={(e) => handleMouseOver(e, a)}
                  onMouseOut={hideTooltip}
                />
              )),
          )}

        {hasPortfolios &&
          (animate ? (
            <Transition
              items={portfoliosList}
              keys={(p) => p.id}
              from={animateFrom}
              enter={animateEnter}
              leave={animateLeave}
              trail={animationTrail}
            >
              {(p) => (props) =>
                renderPortfolioTicker(
                  props,
                  p,
                  p.id,
                  p.tag,
                  p.metrics.expectedReturn,
                  p.metrics.stdDev,
                )}
            </Transition>
          ) : (
            portfoliosList.map((p) =>
              renderPortfolioTicker(
                {},
                p,
                p.id,
                p.tag,
                p.metrics.expectedReturn,
                p.metrics.stdDev,
              ),
            )
          ))}

        {selectedPortfolio.optimizationIsReady &&
          !selectedPortfolio.isOptimized &&
          (animate ? (
            <Transition
              items={[selectedPortfolio]}
              keys={(p) => p.id}
              from={animateFrom}
              enter={animateEnter}
              leave={animateLeave}
            >
              {(p) => (props) => renderOptimizedTicker(props, p)}
            </Transition>
          ) : (
            renderOptimizedTicker({}, selectedPortfolio)
          ))}

        {compare &&
          (animate ? (
            <Transition
              items={[compare]}
              keys={(p) => p.id}
              from={animateFrom}
              enter={animateEnter}
              leave={animateLeave}
            >
              {(p) => (props) =>
                renderCompareTicker(props, p, p.metrics.stdDev)}
            </Transition>
          ) : (
            renderCompareTicker(
              {},
              selectedPortfolio,
              selectedPortfolio.metrics.stdDev,
            )
          ))}
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          key={tooltipData.id}
          top={tooltipTop}
          left={tooltipLeft}
          style={{ position: 'absolute', width: 180 }}
        >
          <ChartTooltip
            title={tooltipData.title}
            data={[
              {
                label: 'CAGR',
                value: (
                  <span>
                    {tooltipData.cagr}{' '}
                    {tooltipData.cagrTrend && (
                      <span>({renderTrendLabel(tooltipData.cagrTrend)})</span>
                    )}
                  </span>
                ),
              },
              {
                label: 'Volatility',
                value: (
                  <span>
                    {tooltipData.volatility}{' '}
                    {tooltipData.stdTrend && (
                      <span>
                        ({renderTrendLabel(tooltipData.stdTrend, true)})
                      </span>
                    )}
                  </span>
                ),
              },
            ]}
          />
        </TooltipWithBounds>
      )}
    </Box>
  );
}

ChartScope.propTypes = {
  parentWidth: PropTypes.number,
  parentHeight: PropTypes.number,
  benchmark: PropTypes.instanceOf(Security),
  selectedPortfolio: PropTypes.instanceOf(Portfolio),
  portfoliosList: PropTypes.arrayOf(PropTypes.instanceOf(Portfolio)),
  hasPortfolios: PropTypes.bool,
  animate: PropTypes.bool,
  onSelect: PropTypes.func,
  assetTrend: PropTypes.bool,
  compare: PropTypes.any,
};

ChartScope.defaultProps = {
  animate: false,
};

export default observer(withParentSize(ChartScope));
