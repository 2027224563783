import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import FundsExplorer from '../FundsExplorer';
import DialogTransition from '../DialogTransition';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
  },
}));

function AssetDetailsDialog({ open, asset, onImportHoldings, onClose }) {
  const classes = useStyles();

  if (!asset) return false;

  return (
    <Dialog
      fullWidth
      maxWidth={asset.isEtf ? 'sm' : 'lg'}
      open={open}
      onClose={onClose}
      TransitionComponent={DialogTransition}
      scroll={'paper'}
    >
      <DialogContent className={classes.content}>
        {<FundsExplorer asset={asset} />}
      </DialogContent>

      <DialogActions>
        {asset.hasTopHoldings && onImportHoldings && (
          <Button onClick={onImportHoldings} color="primary">
            Import holdings as new portfolio
          </Button>
        )}
        <Button autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssetDetailsDialog.propTypes = {
  open: PropTypes.bool,
  asset: PropTypes.object,
  onImportHoldings: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

AssetDetailsDialog.defaultProps = {};

export default React.memo(AssetDetailsDialog);
