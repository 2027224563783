import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CaptionLarge from '../CaptionLarge';

function ChartInstitutionalOwnership({ percentage }) {
  const resultColor = percentage > 50 ? 'success.main' : 'error.main';
  return (
    <Grid
      container
      direction={'column'}
      spacing={1}
      alignItems={'center'}
      justify={'center'}
    >
      <Grid item>
        <CaptionLarge>Institutional ownership</CaptionLarge>
      </Grid>
      <Grid item>
        <Box color={resultColor}>
          <Typography variant={'h3'}>{`${percentage}%`}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

ChartInstitutionalOwnership.propTypes = {
  percentage: PropTypes.number,
};

export default React.memo(ChartInstitutionalOwnership);
