import { observable, flow, action } from 'mobx';
import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APP, ALGOLIA_KEY, ALGOLIA_IDX } from './constants';

const aclient = algoliasearch(ALGOLIA_APP, ALGOLIA_KEY);
const aindex = aclient.initIndex(ALGOLIA_IDX);

class SymbolsSearch {
  @observable term = '';
  @observable isLoading = false;
  @observable hasDocs = false;
  @observable symbols = [];

  setTerm = flow(function* (searchTerm) {
    this.term = searchTerm;
    this.isLoading = true;

    try {
      const res = yield aindex.search(this.term);
      this.symbols = res.hits;
      this.hasDocs = true;
    } catch (err) {
      this.clear();
      console.log(err); // eslint-disable-line no-console
    }

    this.isLoading = false;
  });

  @action clear() {
    this.term = '';
    this.symbols = [];
    this.hasDocs = false;
  }
}

export default SymbolsSearch;
