import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    width: 32,
    height: 32,
    border: '1px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  selected: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  optimized: {
    borderColor: theme.palette.success.main,
    borderStyle: 'dashed',
  },
  compared: {
    borderColor: theme.palette.chart.compare,
    color: theme.palette.chart.compare,
  },
  tag: {
    fontWeight: 500,
  },
}));

function PortfolioTag({ selected, optimized, tag, compared }) {
  const classes = useStyles();

  return (
    <Box
      className={classNames(classes.root, {
        [classes.selected]: selected,
        [classes.optimized]: optimized,
        [classes.compared]: compared,
      })}
    >
      <Typography className={classes.tag}>{tag}</Typography>
    </Box>
  );
}

PortfolioTag.propTypes = {
  selected: PropTypes.bool,
  optimized: PropTypes.bool,
  compared: PropTypes.bool,
  tag: PropTypes.string,
};

PortfolioTag.defaultProps = {};

export default React.memo(PortfolioTag);
