import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { numRound } from '../../../common/utils/helpers';
//TODO:  numRound allocation in store after import

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}));

function PortfolioAssetsPreview({ assets }) {
  const classes = useStyles();

  const renderRow = (a) => (
    <Grid key={a.id} item container direction="row" justify="space-between">
      <Grid item>{a.label}</Grid>
      <Grid item>{numRound(a.allocation)}%</Grid>
    </Grid>
  );

  return (
    <Grid className={classes.root} container direction="column" spacing={1}>
      {assets.map((a) => renderRow(a))}
    </Grid>
  );
}

PortfolioAssetsPreview.propTypes = {
  assets: PropTypes.array,
};

PortfolioAssetsPreview.defaultProps = {};

export default React.memo(PortfolioAssetsPreview);
