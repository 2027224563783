import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';

import { Axis } from '@visx/axis';
import { Group } from '@visx/group';

function AxisBase({ top, left, eeScale, sdScale }) {
  const theme = useTheme();

  const tickLabelProps = () => ({
    display: 'none',
  });

  const labelProps = {
    x: 0,
    y: -10,
    fontSize: 14,
    letterSpacing: '1px',
    fontWeight: 500,
    textAnchor: 'start',
    fill: theme.palette.text.secondary,
  };

  return (
    <svg>
      <defs>
        <linearGradient
          id="axis-gradient-horizontal"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="0%"
          />
          <stop
            offset="10%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="50%"
          />
          <stop
            offset="90%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="50%"
          />
          <stop
            offset="100%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="0%"
          />
        </linearGradient>
        <linearGradient
          id="axis-gradient-vertical"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="0%"
          />
          <stop
            offset="10%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="50%"
          />
          <stop
            offset="90%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="50%"
          />
          <stop
            offset="100%"
            stopColor={theme.palette.scope.axis}
            stopOpacity="0%"
          />
        </linearGradient>
      </defs>
      <Group>
        <Group>
          <circle
            id="svg-circle"
            cx="50%"
            cy="50%"
            r="10%"
            fill="none"
            stroke={theme.palette.scope.axis}
            opacity={'20%'}
          />
          <circle
            id="svg-circle"
            cx="50%"
            cy="50%"
            r="20%"
            fill="none"
            stroke={theme.palette.scope.axis}
            opacity={'10%'}
          />
          <circle
            id="svg-circle"
            cx="50%"
            cy="50%"
            r="30%"
            fill="none"
            stroke={theme.palette.scope.axis}
            opacity={'5%'}
          />
        </Group>
        <Axis
          orientation="bottom"
          hideTicks
          top={top}
          stroke={'url(#axis-gradient-horizontal)'}
          scale={sdScale}
          tickLabelProps={tickLabelProps}
          label={`VOLATILITY`}
          labelProps={labelProps}
        />
        <Axis
          orientation="right"
          hideTicks
          left={left}
          stroke={'url(#axis-gradient-vertical)'}
          scale={eeScale}
          tickLabelProps={tickLabelProps}
          label={`CAGR`}
          labelProps={labelProps}
        />
      </Group>
    </svg>
  );
}

AxisBase.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  eeScale: PropTypes.func,
  sdScale: PropTypes.func,
};

AxisBase.defaultProps = {};

export default React.memo(AxisBase);
