import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Account from '../../containers/Account';
import DialogTransition from '../DialogTransition';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    overflow: 'hidden',
  },
  link: {
    paddingRight: theme.spacing(1),
  },
}));

function SettingsDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={'sm'}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle>
        <div>Settings</div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Account onClose={onClose} />
        <Box px={2} pb={2}>
          <Link
            href="https://www.investblaze.com/legal/privacy-policy"
            rel="noreferrer"
            target="_blank"
            className={classes.link}
          >
            Privacy Policy
          </Link>
          <Link
            href="https://www.investblaze.com/legal/terms"
            rel="noreferrer"
            target="_blank"
            className={classes.link}
          >
            Terms of Service
          </Link>
          <Link href="mailto:hello@investblaze.com" rel="noreferrer">
            Contact Us
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

SettingsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SettingsDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default React.memo(SettingsDialog);
