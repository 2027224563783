import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    borderRadius: 2,
    width: 16,
    height: 16,
    transform: 'rotate(45deg)',
    background: theme.palette.scope.benchmark,
  },
}));

function BenchmarkTag() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.tag}></Box>
    </Box>
  );
}

BenchmarkTag.propTypes = {};

BenchmarkTag.defaultProps = {};

export default React.memo(BenchmarkTag);
