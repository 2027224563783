import React from 'react';
import { Router } from 'react-router';
import { hydrate } from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import { initFirestorter } from 'firestorter';
import CssBaseline from '@material-ui/core/CssBaseline';
import { FB_CREDS } from '../common/store/constants';
import { StoreProvider, Store, Cache } from '../common/store';
import App from '../common/containers/App';
import { ThemeProvider } from '@material-ui/styles';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { Observer } from 'mobx-react';
import env from '../common/utils/env';

if (!firebase.apps.length) {
  firebase.initializeApp(FB_CREDS);

  if (env.isProduction) {
    firebase.analytics();
  }

  if (window.__EMU_HOST__) {
    const db = firebase.firestore();
    const [host, port] = String(window.__EMU_HOST__).split(':');
    db.useEmulator(host, port);
  }

  if (window.__AUTH_EMU_HOST__) {
    firebase.auth().useEmulator(`http://${window.__AUTH_EMU_HOST__}/`);
  }

  initFirestorter({ firebase: firebase });
}

function render(history, store) {
  hydrate(
    <Router history={history}>
      <StoreProvider store={store}>
        <Observer>
          {() => (
            <ThemeProvider theme={store.theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          )}
        </Observer>
      </StoreProvider>
    </Router>,

    // eslint-disable-next-line no-undef
    document.getElementById('root'),
    () => {
      // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
      // eslint-disable-next-line no-undef
      const jssStyles = document.getElementById('jss-ssr');
      if (jssStyles && jssStyles.parentNode)
        jssStyles.parentNode.removeChild(jssStyles);
    },
  );
}

const browserHistory = window.browserHistory || createBrowserHistory();
if (env.isDevelopment && !window.browserHistory) {
  window.browserHistory = browserHistory;
}
const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);
const cache = new Cache(window.__INITIAL_STATE__);
const store = new Store(cache, routingStore);
render(history, store);

if (module.hot) {
  module.hot.accept();
  module.hot.dispose(() => {
    store.dispose();
  });
}

// Clear cache after store was hydrated from firestore on client
store.ready().then(() => cache.clear());

if (env.isDevelopment) {
  window.store = store;
}
