import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function SigninCopy({}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1" gutterBottom>
        We care about your{' '}
        <Link
          href="https://www.investblaze.com/legal/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          privacy
        </Link>
        . Your email and personal information is not shared with any third
        parties, and we only use your email to link created portfolios to your
        account.
      </Typography>
      <Typography variant="body1" gutterBottom>
        By signing up you agree to our{' '}
        <Link
          href="https://www.investblaze.com/legal/terms"
          rel="noreferrer"
          target="_blank"
        >
          Terms of Service
        </Link>
        .
      </Typography>
    </div>
  );
}

SigninCopy.propTypes = {};

SigninCopy.defaultProps = {};

export default React.memo(SigninCopy);
