export function truncate(str, n, useWordBoundary = false) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '...'
  );
}

export function decodeHtmlUnicodeSequences(str) {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
}

export function numRound(num) {
  return Number(num).toFixed(2);
}

export function numRoundF(num, decimals = 2) {
  return parseFloat(Number(num).toFixed(decimals));
}

export function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  const segments = value.toString().split('.');
  return segments && segments[1] ? segments[1].length : 0;
}

export function onEnter(callback) {
  return (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      callback();
    }
  };
}

export function relDiff(a, b) {
  return numRound(((a - b) / b) * 100);
}

export function formatAmount(labelValue) {
  const sign = labelValue < 0 ? '-' : '';
  const value = Math.abs(Number(labelValue));
  const res =
    value >= 1.0e9
      ? `${sign}${numRound(value / 1.0e9)}B`
      : // Six Zeroes for Millions
      value >= 1.0e6
      ? `${sign}${numRound(value / 1.0e6)}M`
      : // Three Zeroes for Thousands
      value >= 1.0e3
      ? `${sign}${numRound(value / 1.0e3)}K`
      : numRound(value);

  return res;
}

export function pp(v) {
  return numRound(v * 100);
}
