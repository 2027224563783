import React, { useState, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GridRows, GridColumns } from '@visx/grid';
import { BarGroup, Bar, Line } from '@visx/shape';
import { localPoint } from '@visx/event';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';

import { fade } from '@material-ui/core/styles/colorManipulator';
import { pp } from '../../../common/utils/helpers';

import {
  getXMax,
  getYMax,
  formatPercent,
  getKeys,
  getColor,
} from '../../../common/utils/chart';

import ChartXAxis from '../ChartXAxis';
import ChartYAxis from '../ChartYAxis';
import ChartLegend from '../ChartLegend';
import ChartTooltip from '../ChartTooltip';

function ChartAnnualReturns({ data, meta, parentWidth, parentHeight }) {
  const theme = useTheme();
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const [highlightCol, setHighlightCol] = useState(null);

  const margin = {
    top: 20,
    bottom: 40,
    left: 60,
    right: 0,
  };
  const gridColor = theme.palette.divider;
  const highlightColColor = fade(theme.palette.primary.light, 0.03);
  const fadeBarRatio = 0.2;

  const tickFormat = (v) => `${pp(v, 0)}%`;

  const xAccessor = (d) => d.date;

  const chartHeight = parentHeight - 60;

  const xMax = getXMax(parentWidth, margin);
  const yMax = getYMax(chartHeight, margin);

  const keys = getKeys(data);

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        domain: [...data.map(xAccessor)],
        padding: 0,
        nice: true,
      }),
    [data, xMax],
  );

  const keysScale = useMemo(
    () =>
      scaleBand({
        range: [0, xScale.bandwidth()],
        domain: keys,
        paddingInner: 0.25,
        paddingOuter: 2,
      }),
    [keys, xScale],
  );

  const keysAmountsArray = (keys, d) =>
    keys.map((key) => {
      return Number(d[key]);
    });

  const valuesArray = (f) => data.map(f);

  const returnsDomainMin = Math.min(
    ...valuesArray((d) => Math.min(...keysAmountsArray(keys, d))),
    0,
  );
  const returnsDomainMax = Math.max(
    ...valuesArray((d) => Math.max(...keysAmountsArray(keys, d))),
    0,
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [returnsDomainMin, returnsDomainMax * 1.2],
        range: [yMax, 0],
        nice: true,
      }),
    [returnsDomainMin, returnsDomainMax, yMax],
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: keys.map((k) => getColor(k, theme)),
      }),
    [keys],
  );

  const handleTooltip = useCallback(
    (event) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };

      const x0 = x - margin.left;
      const eachBand = xScale.bandwidth();
      const index = Math.floor(x0 / eachBand);

      setHighlightCol(index);

      showTooltip({
        tooltipData: data[index],
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, yScale, xScale],
  );

  return (
    <Box position={'relative'}>
      <svg width={parentWidth} height={chartHeight}>
        <Group left={margin.left} top={margin.top}>
          <Group>
            <Group>
              <ChartXAxis top={yMax} scale={xScale} />
              <GridColumns
                key={`dates-gridcolumns`}
                scale={xScale}
                stroke={gridColor}
                height={yMax}
              />
            </Group>
            <Group>
              <ChartYAxis scale={yScale} tickFormat={tickFormat} />
              <GridRows
                key={`returns-gridrows`}
                scale={yScale}
                stroke={gridColor}
                width={xMax}
              />
            </Group>
            <Line
              from={{ x: 0, y: yScale(0) }}
              to={{ x: xMax, y: yScale(0) }}
              stroke={fade(theme.palette.text.secondary, 0.2)}
              pointerEvents="none"
            />
          </Group>
          <Group>
            <BarGroup
              data={data}
              keys={keys}
              height={yMax}
              x0={xAccessor}
              x0Scale={xScale}
              x1Scale={keysScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barGroups) => {
                return barGroups.map((barGroup) => {
                  return (
                    <Group
                      key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                      left={barGroup.x0}
                    >
                      {barGroup.bars.map((bar) => {
                        const height =
                          bar.value > 0
                            ? Math.abs(
                                yScale(bar.value) -
                                  yScale(Math.max(0, returnsDomainMin)),
                              )
                            : Math.abs(yScale(bar.value) - yScale(0));

                        const y = bar.value > 0 ? yScale(bar.value) : yScale(0);
                        return (
                          <Bar
                            key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                            x={bar.x}
                            y={y}
                            width={bar.width}
                            height={height}
                            fill={fade(bar.color, fadeBarRatio)}
                            stroke={bar.color}
                          />
                        );
                      })}
                    </Group>
                  );
                });
              }}
            </BarGroup>
            {data.map((d, i) => {
              const label = xAccessor(d);
              const barWidth = xScale.bandwidth();
              const barHeight = yMax;
              const barX = xScale(label);
              return (
                <Bar
                  key={`bar-${label}`}
                  x={barX}
                  y={0}
                  fill={highlightCol === i ? highlightColColor : 'transparent'}
                  width={barWidth}
                  height={barHeight}
                />
              );
            })}
          </Group>
          <Bar
            x={0}
            y={0}
            fill={'transparent'}
            width={xMax}
            height={yMax}
            onMouseLeave={() => {
              setHighlightCol(null);
              hideTooltip();
            }}
            onMouseMove={handleTooltip}
          />
        </Group>
      </svg>
      <ChartLegend scale={colorScale} meta={meta} shape={'rect'} />

      {tooltipOpen && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          key={Math.random()}
          offsetTop={-180}
          offsetLeft={-80}
          style={{ position: 'absolute', width: 190 }}
        >
          <ChartTooltip
            color
            title={`${xAccessor(tooltipData)}`}
            data={keys.map((k) => {
              return {
                label: meta[k],
                type: k,
                value: formatPercent(tooltipData[k]),
              };
            })}
          />
        </TooltipWithBounds>
      )}
    </Box>
  );
}

ChartAnnualReturns.propTypes = {
  parentWidth: PropTypes.number,
  parentHeight: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      portfolio: PropTypes.number,
      optimized: PropTypes.number,
      benchmark: PropTypes.number,
    }),
  ),
  meta: PropTypes.object.isRequired,
};

ChartAnnualReturns.defaultProps = {};

export default React.memo(withParentSize(ChartAnnualReturns));
