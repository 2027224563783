import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';

import { Asset } from '../../store';

import EtfDetail from '../EtfDetail';
import StockDetail from '../StockDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

function FundsExplorer({ asset }) {
  const classes = useStyles();
  const { security, symbol, isEtf } = asset;

  return (
    <Grid container spacing={3} direction={'column'} className={classes.root}>
      <Grid item>
        <Typography variant={'h2'}>{symbol}</Typography>
        <Typography variant="body2">{security.name}</Typography>
      </Grid>
      <Grid item>
        {isEtf ? <EtfDetail asset={asset} /> : <StockDetail asset={asset} />}
      </Grid>
    </Grid>
  );
}

FundsExplorer.propTypes = {
  asset: PropTypes.instanceOf(Asset).isRequired,
};

FundsExplorer.defaultProps = {};

export default observer(FundsExplorer);
