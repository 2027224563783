import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { numRound } from '../../utils/helpers';
import { Portfolio } from '../../store';

import { styled } from '@material-ui/core/styles';

import {
  CagrStdev,
  Alpha,
  Sharpe,
  Sortino,
  UpsideDownside,
  Message,
} from '../Indicator';
import { observer } from 'mobx-react';

const Ticker = styled(Typography)({
  textTransform: 'uppercase',
  letterSpacing: 1,
  lineHeight: 1.25,
});

const useStyles = makeStyles((theme) => ({
  root: {},
  warn: {
    color: `${theme.palette.secondary.main}!important`,
  },
  good: {
    color: `${theme.palette.success.main}!important`,
  },
  action: {
    width: 80,
  },
}));

function PortfolioTotalRow({ portfolio, onReset, onEqualize, ...rest }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuShow = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    onReset();
    setAnchorEl(null);
  };

  const handleEqualize = () => {
    onEqualize();
    setAnchorEl(null);
  };

  const { metrics, metricsReady, existing } = portfolio;
  const value = existing ? portfolio.totalAmount : portfolio.totalAllocation;
  const {
    expectedReturn,
    stdDev,
    sharpe,
    sortino,
    upsideCapture,
    downsideCapture,
    alpha,
  } = metrics;

  const allocationFull = existing || numRound(value) === numRound(100);

  return (
    <ListItem className={classes.root} {...rest}>
      <Grid container direction={'row'} alignItems={'center'} spacing={2}>
        <Grid xs item>
          <Ticker color={'textPrimary'}>Total</Ticker>
        </Grid>
        <Grid item>
          <Box
            width={120}
            pr={1}
            className={classNames({
              [classes.warn]: !allocationFull,
            })}
          >
            <Typography variant={'body1'} align={'right'}>
              {existing
                ? `$ \u00A0${value.toLocaleString()}`
                : `${numRound(value)} %`}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          {!allocationFull ? (
            <Message>
              <Typography variant={'caption'}>
                Allocations should add up to 100%
              </Typography>
            </Message>
          ) : (
            <Grid container spacing={1}>
              <Grid item>
                <UpsideDownside
                  upsideCapture={upsideCapture}
                  downsideCapture={downsideCapture}
                  ready={metricsReady}
                />
              </Grid>
              <Grid item>
                <CagrStdev
                  cagr={expectedReturn}
                  stdDev={stdDev}
                  ready={metricsReady}
                />
              </Grid>
              <Grid item>
                <Alpha alpha={alpha} ready={metricsReady} />
              </Grid>
              <Grid item>
                <Sharpe sharpe={sharpe} ready={metricsReady} />
              </Grid>
              <Grid item>
                <Sortino sortino={sortino} ready={metricsReady} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Box
            width={40}
            display={'flex'}
            justifyContent="center"
            color={'text.secondary'}
          >
            {onReset && !portfolio.existing && (
              <Tooltip title={'Allocation actions'} placement="top" arrow>
                <IconButton
                  aria-label="more"
                  aria-controls="allocation-menu"
                  aria-haspopup="true"
                  onClick={handleMenuShow}
                  color={'inherit'}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              id="allocation-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEqualize}>Equal Weight</MenuItem>
              <MenuItem onClick={handleReset}>Reset Allocation</MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

PortfolioTotalRow.propTypes = {
  portfolio: PropTypes.instanceOf(Portfolio),
  onReset: PropTypes.func,
  onEqualize: PropTypes.func,
};

PortfolioTotalRow.defaultProps = {};

export default observer(PortfolioTotalRow);
