import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import RiskProfileLabel from '../../components/RiskProfileLabel';
import CaptionLarge from '../../components/CaptionLarge';
import TargetUD from '../../components/TargetUD';
import TargetQuadrant from '../../components/TargetQuadrant';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  highlight: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  grid: {
    height: 48,
  },
}));

function RiskAssesment({ riskProfile, onStart }) {
  const classes = useStyles();

  return (
    <Paper
      // variant="outlined"
      // elevation={6}
      className={classNames(classes.content, {
        // [classes.highlight]: !riskProfile.isComplete,
      })}
    >
      <Grid
        container
        justify={'space-between'}
        alignItems={'center'}
        className={classes.grid}
      >
        <Grid item style={{ flex: 1 }}>
          {riskProfile.isComplete ? (
            <Box display="flex" flexDirection="row">
              <Box mr={2}>
                <Grid container direction="row" spacing={1}>
                  <Tooltip
                    title="Your resulting
                    Portfolio may fall into this quadrants of the
                    Radar screen."
                    placement="bottom"
                    arrow
                  >
                    <Grid item>
                      <TargetQuadrant resultLabel={riskProfile.resultLabel} />
                    </Grid>
                  </Tooltip>
                  <Tooltip
                    title="Targeted U/D ratio preferred by investors with your investor profile"
                    placement="bottom"
                    arrow
                  >
                    <Grid item>
                      <TargetUD resultLabel={riskProfile.resultLabel} />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Box>
              <Box pt={1 / 2}>
                <CaptionLarge color={'textSecondary'}>
                  Your Investor Profile is
                </CaptionLarge>
                <RiskProfileLabel
                  text={riskProfile.resultLabel}
                  score={riskProfile.result}
                />
              </Box>
            </Box>
          ) : (
            <Typography>
              Take Risk Assessment to determine your Investor Profile
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button onClick={onStart}>
            {riskProfile.isComplete ? 'Learn More' : 'Start'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

RiskAssesment.propTypes = {
  riskProfile: PropTypes.object.isRequired,
  onStart: PropTypes.func.isRequired,
};

RiskAssesment.defaultProps = {};

export default observer(RiskAssesment);
