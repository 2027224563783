import React from 'react';

import Typography from '@material-ui/core/Typography';

import { styled } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';

const CaptionLarge = styled(withTheme(Typography))((props) => ({
  textTransform: 'uppercase',
  letterSpacing: 1,
  lineHeight: 1.25,
  fontSize: 12,
}));

export default React.memo(CaptionLarge);
