import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import { PROFILE_TYPES } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  indicator: {
    fill: theme.palette.primary.main,
  },
  axis: {
    fill: theme.palette.text.secondary,
  },
}));

function TargetQuadrant({ resultLabel }) {
  const classes = useStyles();

  return (
    <Box
      borderRadius="borderRadius"
      border={1}
      borderColor={'text.secondary'}
      width={48}
      height={48}
      bgcolor="paper.main"
      position="relative"
      overflow="hidden"
    >
      <svg width={48} height={48}>
        {(resultLabel === PROFILE_TYPES.m ||
          resultLabel === PROFILE_TYPES.cm ||
          resultLabel === PROFILE_TYPES.ma) && (
          <circle
            id="q-moderate"
            cx="12"
            cy="12"
            r="6"
            className={classes.indicator}
          />
        )}

        {(resultLabel === PROFILE_TYPES.c ||
          resultLabel === PROFILE_TYPES.cm) && (
          <circle
            id="q-conservative"
            cx="12"
            cy="35"
            r="6"
            className={classes.indicator}
          />
        )}

        {(resultLabel === PROFILE_TYPES.a ||
          resultLabel === PROFILE_TYPES.ma) && (
          <circle
            id="q-aggressive"
            cx="35"
            cy="12"
            r="6"
            className={classes.indicator}
          />
        )}

        <rect
          x={0}
          y={23}
          width={48}
          height={1}
          className={classes.axis}
        ></rect>
        <rect
          x={23}
          y={0}
          width={1}
          height={48}
          className={classes.axis}
        ></rect>
      </svg>
    </Box>
  );
}

TargetQuadrant.propTypes = {
  resultLabel: PropTypes.string,
};

TargetQuadrant.defaultProps = {};

export default React.memo(TargetQuadrant);
