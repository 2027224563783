import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconInfo from '@material-ui/icons/HelpOutline';
import Box from '@material-ui/core/Box';

import Tooltip from '@material-ui/core/Tooltip';
import { Asset } from '../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
  },
  input: {
    width: 120,
  },
  action: {
    width: 80,
  },
  symbol: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
  symbolContainer: {
    width: 80,
  },
}));

function AssetControl({ asset, onChange, onRemove, action }) {
  const classes = useStyles();

  const setValue = (val) => onChange(val);

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (asset.allocation < 0) {
      setValue(0);
    } else if (asset.allocation > 100) {
      setValue(100);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" className={classes.root}>
      <Grid item className={classes.symbolContainer}>
        <Tooltip title={asset.name} arrow placement="top">
          <Box display="flex">
            <Box mr={1 / 2}>
              <Typography
                id="input-slider"
                gutterBottom
                className={classes.symbol}
              >
                {asset.label}
              </Typography>
            </Box>
            <Box alignSelf="center" mb={1 / 5}>
              {asset.isCash && (
                <IconInfo color="disabled" style={{ fontSize: 16 }} />
              )}
            </Box>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs>
        <Box display="flex" justifyContent="flex-end">
          {action && action}
        </Box>
      </Grid>
      <Grid item>
        <Input
          className={classes.input}
          value={asset.allocation}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          endAdornment={<InputAdornment position="start">%</InputAdornment>}
          inputProps={{
            step: 10,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
      </Grid>
      <Grid item className={classes.action}>
        <IconButton onClick={onRemove}>
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

AssetControl.propTypes = {
  asset: PropTypes.instanceOf(Asset),
  action: PropTypes.node,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

AssetControl.defaultProps = {};

export default observer(AssetControl);
