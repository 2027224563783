import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Glyph as CustomGlyph, GlyphTriangle } from '@visx/glyph';
import { useTheme } from '@material-ui/core/styles';
import { config } from 'react-spring/renderprops.cjs';

import EnhancedSpring from '../EnhancedSpring';

function AssetTicker({ animate, symbol, top, left, onMouseOver, onMouseOut }) {
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  const handleMouseOver = (e) => {
    setHover(true);
    onMouseOver(e);
  };

  const handleMouseOut = (e) => {
    setHover(false);
    onMouseOut(e);
  };

  return (
    <svg style={{ pointerEvents: 'all' }}>
      <EnhancedSpring animate={animate} to={{ top: top, left: left }}>
        {(props) => (
          <CustomGlyph top={props.top} left={props.left}>
            <EnhancedSpring
              animate={animate}
              from={{
                opacity: 0,
                transform: 'translate3d(0,50px,0)',
              }}
              to={{
                opacity: 1,
                transform: 'translate3d(0,0,0)',
                size: hover ? 400 : 150,
              }}
              config={{ ...config.gentle }}
            >
              {(sp) => (
                <g style={sp}>
                  <GlyphTriangle
                    size={sp.size}
                    fill={'none'}
                    stroke={theme.palette.text.primary}
                    strokeWidth={1}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  />
                </g>
              )}
            </EnhancedSpring>

            <EnhancedSpring
              animate={animate}
              from={{ opacity: 0, transform: 'translate3d(0,1rem,0)' }}
              to={{ opacity: hover ? 0 : 1, transform: 'translate3d(0,0,0)' }}
              config={{ ...config.gentle, delay: hover ? 0 : 100 }}
            >
              {(sp) => (
                <text
                  fontSize={12}
                  fontWeight={500}
                  textAnchor="middle"
                  dy="1.4rem"
                  style={sp}
                  cursor="default"
                  fill={theme.palette.text.primary}
                >
                  {symbol}
                </text>
              )}
            </EnhancedSpring>
          </CustomGlyph>
        )}
      </EnhancedSpring>
    </svg>
  );
}

AssetTicker.propTypes = {
  symbol: PropTypes.string,
  animate: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

AssetTicker.defaultProps = {};

export default React.memo(AssetTicker);
