import React from 'react';
import PropTypes from 'prop-types';
import { Glyph as CustomGlyph, GlyphCircle } from '@visx/glyph';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Spring } from 'react-spring/renderprops.cjs';

const useStyles = makeStyles((theme) => ({
  portfolio: {
    fill: 'transparent',
    stroke: theme.palette.chart.compare,
    strokeWidth: 1,
  },
}));

function CompareTicker({ animate, tag, top, left, onMouseOver, onMouseOut }) {
  const classes = useStyles();
  const theme = useTheme();
  if (!top || !left) return true;

  const renderGlyph = (top, left) => {
    return (
      <CustomGlyph top={top} left={left} className={classes.portfolio}>
        <GlyphCircle size={800} fill={'inherit'} />
        <text
          fontSize={16}
          fontWeight={500}
          textAnchor="middle"
          dy={5}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          fill={theme.palette.chart.compare}
          stroke="none"
        >
          {tag}
        </text>
      </CustomGlyph>
    );
  };

  return (
    <svg onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {animate ? (
        <Spring to={{ top: top, left: left }}>
          {(props) => renderGlyph(props.top, props.left)}
        </Spring>
      ) : (
        renderGlyph(top, left)
      )}
    </svg>
  );
}

CompareTicker.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  animate: PropTypes.bool,
  tag: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

CompareTicker.defaultProps = {
  animate: false,
};

export default React.memo(CompareTicker);
