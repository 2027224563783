import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import { Portfolio } from '../../store';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

import ShareIcon from '@material-ui/icons/ShareTwoTone';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import MergeIcon from '@material-ui/icons/MergeTypeTwoTone';
import FileCopyIcon from '@material-ui/icons/FileCopyTwoTone';

import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';

import CaptionLarge from '../CaptionLarge';
import Alert from '@material-ui/lab/Alert';

import Emoji from 'a11y-react-emoji';

const NameTextField = withStyles((theme) => ({
  root: {
    '& .MuiInput-input': {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      letterSpacing: 1,
      lineHeight: 1.25,
    },
    '& .Mui-focused > .MuiInput-input': {
      color: theme.palette.text.primary,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.divider,
    },
  },
}))(TextField);

import PortfolioTag from '../PortfolioTag';
import {
  Alpha,
  UpsideDownside,
  CagrStdev,
  Sharpe,
  Sortino,
} from '../Indicator';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  indicator: {
    height: 148,
    flex: 1,
    cursor: 'help',
  },
  tooltip: {
    background: theme.palette.tooltip.full,
    backdropFilter: theme.blur,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${fade(theme.palette.text.primary, 0.17)}`,
    width: 300,
    color: theme.palette.text.primary,
  },
  popper: {
    background: theme.palette.background.paper,
    backdropFilter: theme.blur,
  },
  highlightText: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  alert: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
  },
  ul: {
    color: theme.palette.text.secondary,
  },
  ol: {
    paddingInlineStart: '20px',
  },
}));

function PortfolioHead({
  portfolio,
  onPortfolioShare,
  onPortfolioRemove,
  onPortfolioMerge,
  onPortfolioCopy,
  onPortfolioRename,
}) {
  const classes = useStyles();
  const [id, setId] = useState(portfolio.id);
  const [name, setName] = useState(portfolio.name);

  const { metrics, metricsReady, tag, existing } = portfolio;
  const {
    upsideCapture,
    downsideCapture,
    expectedReturn,
    stdDev,
    alpha,
    sharpe,
    sortino,
  } = metrics;

  const debouncedRenameRequest = useCallback(
    debounce((portfolio, value) => {
      onPortfolioRename(portfolio, value);
    }, 500),
    [],
  );

  const handleRename = (e) => {
    const newName = String(e.target.value).toUpperCase();
    setName(newName);
    debouncedRenameRequest(portfolio, newName);
  };

  const handleFocus = (e) => {
    const { target } = e;

    // select all if name is default
    if (String(target.value).match(/^PORTFOLIO [A-Z]$/)) {
      e.preventDefault();
      target.focus();
      target.setSelectionRange(0, target.value.length);
    }
  };

  // update internal copy
  useEffect(() => {
    autorun(() => {
      if (id !== portfolio.id) {
        setName(portfolio.name);
        setId(portfolio.id);
      }
    });
  }, [portfolio]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justify="space-between"
        spacing={2}
      >
        <Grid item xs>
          <Grid container direction={'row'} spacing={2}>
            <Grid item>
              <PortfolioTag tag={tag} selected />
            </Grid>
            <Grid item xs>
              <NameTextField
                value={name}
                onChange={handleRename}
                onFocus={handleFocus}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={'row'} spacing={0}>
            <Grid item>
              <Tooltip title="Duplicate portfolio" placement="top" arrow>
                <IconButton onClick={onPortfolioCopy}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title="Merge multiple portfolios into one"
                placement="top"
                arrow
              >
                <IconButton onClick={onPortfolioMerge}>
                  <MergeIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Share portfolio" placement="top" arrow>
                <IconButton onClick={onPortfolioShare}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Remove portfolio" placement="top" arrow>
                <IconButton onClick={onPortfolioRemove}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={'row'}
        spacing={1}
        style={{ position: 'relative' }}
      >
        <Grid item className={classes.indicator}>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              // popper: classes.popper,
            }}
            title={
              <Box p={2}>
                <CaptionLarge>
                  UPSIDE CAPTURE / <br /> DOWNSIDE CAPTURE
                </CaptionLarge>
                <Box mt={1}>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                  >
                    The upside/downside capture is the measure of a stock’s or
                    fund’s overall performance relative to the index. The
                    UPSIDE/DOWNSIDE CAPTURE varies based upon the individual
                    investor’s INVESTMENT POLICY. For example, many investors
                    prefer a targeted ratio of 80/60, which indicates a
                    portfolio that will likely capture 80% of the index UPSIDE
                    and only 60% of its DOWNSIDE.
                  </Typography>
                  <ul className={classes.ul}>
                    <li>CONSERVATIVE INVESTOR may target 15/10</li>
                    <li>MODERATE-CONSERVATIVE - 60/40</li>
                    <li>MODERATE - 80/60</li>
                    <li>MODERATELY AGGRESSIVE - 100/80</li>
                    <li>AGGRESSIVE - 125/100</li>
                  </ul>
                  <Typography
                    variant={'caption'}
                    component={'p'}
                    color={'textSecondary'}
                    className={classes.highlightText}
                  >
                    A ratio of 1.00 is acceptable, A ratio of 1.25+ is
                    desirable.
                  </Typography>
                </Box>
              </Box>
            }
            placement="right"
            arrow
          >
            <Box height={1}>
              <UpsideDownside
                upsideCapture={upsideCapture}
                downsideCapture={downsideCapture}
                large
                ready={metricsReady}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item className={classes.indicator}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Box p={2}>
                <CaptionLarge>
                  Compound Annual Growth Rate / Standard Deviation
                </CaptionLarge>
                <Box mt={1}>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                    gutterBottom
                  >
                    People who want to earn higher returns (CAGR) need to be
                    willing to accept more volatility (Standard Deviation.
                  </Typography>
                  <Typography
                    variant={'caption'}
                    component={'p'}
                    color={'textSecondary'}
                    className={classes.highlightText}
                  >
                    A ratio of 1.00 is acceptable, A ratio of 1.25+ is desirable
                  </Typography>
                </Box>
              </Box>
            }
            placement="right"
            arrow
          >
            <Box height={1}>
              <CagrStdev
                cagr={expectedReturn}
                stdDev={stdDev}
                large
                ready={metricsReady}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item className={classes.indicator}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Box p={2}>
                <CaptionLarge>Alpha Ratio</CaptionLarge>
                <Box mt={1}>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                    gutterBottom
                  >
                    Alpha is used in finance as a measure of performance. It
                    typically refers to a percentage measuring how the portfolio
                    or fund performed compared to the benchmark index.
                  </Typography>
                  <Typography
                    variant={'caption'}
                    component={'p'}
                    color={'textSecondary'}
                    className={classes.highlightText}
                  >
                    An Alpha greater than 1% is acceptable, an Alpha of 5%+ is
                    desirable (for growth stocks/funds).{' '}
                  </Typography>
                </Box>
              </Box>
            }
            placement="right"
            arrow
          >
            <Box height={1}>
              <Alpha alpha={alpha} large ready={metricsReady} />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item className={classes.indicator}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Box p={2}>
                <CaptionLarge>Sharpe Ratio</CaptionLarge>
                <Box mt={1}>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                    gutterBottom
                  >
                    Initially termed the reward-to-variability ratio, the Sharpe
                    Ratio calculates the return per unit of risk, using standard
                    deviation as the risk measure. A higher Sharpe Ratio is
                    generally more attractive because it indicates a higher
                    risk-adjusted return.
                  </Typography>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                    gutterBottom
                  >
                    For example, portfolio A generates a return of 15%, and
                    portfolio B generates a return of 12%. It appears that
                    manager A is a better performer. However, manager A may have
                    taken larger risks than manager B.{' '}
                  </Typography>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                    gutterBottom
                  >
                    Let’s say that manager A's portfolio has a standard
                    deviation of 8% while manager B's portfolio has a standard
                    deviation of 5%. The Sharpe ratio for manager A would be
                    1.25, while manager B's ratio would be 1.4. Based on these
                    calculations, manager B was able to generate a higher return
                    on a risk-adjusted basis.
                  </Typography>
                  <Typography
                    variant={'caption'}
                    component={'p'}
                    color={'textSecondary'}
                    className={classes.highlightText}
                  >
                    A ratio of 1+ is desirable.
                  </Typography>
                </Box>
              </Box>
            }
            placement="right"
            arrow
          >
            <Box height={1}>
              <Sharpe sharpe={sharpe} large ready={metricsReady} />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item className={classes.indicator}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Box p={2}>
                <CaptionLarge>Sortino Ratio</CaptionLarge>
                <Box mt={1}>
                  <Typography
                    component={'p'}
                    variant={'caption'}
                    color={'textSecondary'}
                    gutterBottom
                  >
                    The Sortino Ratio takes the Sharpe Ratio and only considers
                    the standard deviation of the downside risk, rather than
                    that of the entire (upside + downside) risk. Because the
                    Sortino Ratio focuses only on the negative deviation of a
                    portfolio's returns from the mean, it is thought to give a
                    better view of a portfolio's risk-adjusted performance since
                    positive volatility is a benefit.
                  </Typography>
                  <Typography
                    variant={'caption'}
                    component={'p'}
                    color={'textSecondary'}
                    className={classes.highlightText}
                  >
                    A ratio of 1.50 is acceptable, and a ratio of 2.0+ is
                    desirable.
                  </Typography>
                </Box>
              </Box>
            }
            placement="right"
            arrow
          >
            <Box height={1}>
              <Sortino sortino={sortino} large ready={metricsReady} />
            </Box>
          </Tooltip>
          {!metricsReady && (
            <Alert
              icon={<Emoji symbol="👇" label="point down" />}
              className={classes.alert}
              severity="info"
            >
              <ol className={classes.ol}>
                <li>
                  To view the top 5 performance metrics, add assets and set up
                  allocation{!existing ? ' to add up to 100%' : ''}.
                </li>
                <li>
                  To anlyze the financial metrics, analysts recommendations and
                  institutional holdings click on the asset name.
                </li>
                <li>
                  When ready, duplicate Portfolio and tweak allocation to
                  improve your metrics.
                </li>
              </ol>
            </Alert>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

PortfolioHead.propTypes = {
  portfolio: PropTypes.instanceOf(Portfolio).isRequired,
  onPortfolioShare: PropTypes.func,
  onPortfolioRemove: PropTypes.func,
  onPortfolioMerge: PropTypes.func,
  onPortfolioCopy: PropTypes.func,
  onPortfolioRename: PropTypes.func,
};

PortfolioHead.defaultProps = {};

export default observer(PortfolioHead);
