import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import YouTube from 'react-youtube';

const useStyles = makeStyles((theme) => ({}));

function YoutubePlayer({ videoId, setYoutube, skipVideo }) {
  const classes = useStyles();

  // youtube player options
  const opts = {
    width: '600',
    playerVars: {
      autoplay: 0,
      controls: 1,
      cc_load_policy: 0,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  };

  return (
    <YouTube
      id="player"
      className={classes.player}
      videoId={videoId}
      opts={opts}
      onReady={setYoutube}
      onEnd={skipVideo}
    />
  );
}

YoutubePlayer.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.bool,
  fillStyle: PropTypes.string,
  setYoutube: PropTypes.func,
  skipVideo: PropTypes.func,
  videoId: PropTypes.string,
};

YoutubePlayer.defaultProps = {
  setYoutube: () => null,
  skipVideo: () => null,
};

export default YoutubePlayer;
