import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Logo from '../Logo';

const useStyles = makeStyles(() => ({
  logoContainer: {
    height: 64,
    width: '100%',
  },
  logoText: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
  },
  logoTextBeta: {
    fontSize: 8,
    fontFamily: 'Comfortaa',
    position: 'absolute',
    right: -20,
    top: 0,
  },
}));

function LogoWordmark() {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.logoContainer}>
      <Box pl={1}>
        <Logo />
      </Box>
      <Box position="relative">
        <Typography className={classes.logoText} color={'textPrimary'}>
          investblaze
        </Typography>
        <Typography className={classes.logoTextBeta} color={'textSecondary'}>
          beta
        </Typography>
      </Box>
    </Box>
  );
}

LogoWordmark.propTypes = {};

LogoWordmark.defaultProps = {};

export default React.memo(LogoWordmark);
