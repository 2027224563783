import { observable, action, computed, flow, reaction } from 'mobx';
import moment from 'moment';
import UniversalDocument from './UniversalDocument';
import UniversalCollection from './UniversalCollection';
import Security from './Security';
import Portfolio from './Portfolio';
import SharedPortfolio from './SharedPortfolio';
import Mixer from './Mixer';
import { BENCH_SYM, CASH_SYM } from './constants';
import { isServer } from '../utils/env';
import { momentComparer, today, isValidSymbol } from './helpers';

class Profile extends UniversalDocument {
  _sinceReaction = null;
  _tillReaction = null;
  @observable _portfoliosCollection = null;
  @observable _selectedPortfolio = null;
  @observable _sharedPortfoliosCollection = null;
  @observable _options = null;
  @observable _comparedPortfolio = null;
  @observable merge = [];
  @observable benchmark = null;
  @observable mixer = null;

  constructor(source, options) {
    super(source, options);

    this._options = { ...options, constrainer: this };
    const benchSym = String(BENCH_SYM).toLowerCase();
    this.benchmark = new Security(`securities/${benchSym}`, this._options);
    this.mixer = new Mixer(this);
    this._options.benchmark = this.benchmark;
  }

  get _portfolios() {
    if (this._portfoliosCollection) {
      return this._portfoliosCollection;
    }

    this.createPortfoliosCollection();
    return this._portfoliosCollection;
  }

  get _sharedPortfolios() {
    if (this._sharedPortfoliosCollection)
      return this._sharedPortfoliosCollection;

    this._sharedPortfoliosCollection = new UniversalCollection(
      'sharedPortfolios',
      {
        ...this._options,
        mode: 'off',
        createDocument: (s, o) =>
          new SharedPortfolio(s, { ...this._options, ...o }),
      },
    );

    return this._sharedPortfoliosCollection;
  }

  ready() {
    return Promise.all([
      super.ready(),
      this.benchmark.ready(),
      this._portfolios.ready(),
      ...this.portfolios.map((p) => p.ready()),
    ]);
  }

  delete() {
    this._sinceReaction();
    this._tillReaction();
    return Promise.all([
      ...this.portfolios.map((a) => a.delete()),
      super.delete(),
    ]);
  }

  @computed get portfolios() {
    return this._portfolios.docs;
  }

  @computed get sortedPortfolios() {
    return this.portfolios.slice().sort((a, b) => a.createdAt - b.createdAt);
  }

  @computed get sortedPortfoliosWithoutSelected() {
    return this.sortedPortfolios.filter(
      (p) => p.id !== this.selectedPortfolio.id,
    );
  }

  @computed get visibleSortedPortfolios() {
    return this.crowdedScope ? this.sortedPortfolios : [this.selectedPortfolio];
  }

  @computed get hasPortfolios() {
    return this._portfolios.hasDocs;
  }

  get selectedPortfolio() {
    if (this._selectedPortfolio) return this._selectedPortfolio;

    if (this.hasData && this.data.selectedPortfolio) {
      return this.portfolios.find((p) => p.id === this.data.selectedPortfolio);
    }
    const len = this.portfolios.length;
    if (len > 0) {
      return this.sortedPortfolios[len - 1];
    }

    return null;
  }

  @computed get comparedPortfolio() {
    if (this._comparedPortfolio) return this._comparedPortfolio;

    if (this.hasData && this.data.comparedPortfolio) {
      return this.portfolios.find((p) => p.id === this.data.comparedPortfolio);
    }

    return null;
  }

  @computed({ equals: momentComparer }) get since() {
    const { portfolios } = this;
    if (portfolios.length === 0) {
      return moment.unix(0);
    }

    if (!this.expertMode) {
      return this.benchmark.since;
    }

    return [...portfolios, this.benchmark].reduce(
      (acc, p) => (p.since.isAfter(acc) ? p.since : acc),
      moment.unix(0),
    );
  }

  @computed({ equals: momentComparer }) get till() {
    const { portfolios } = this;
    if (portfolios.length === 0) {
      return today();
    }

    if (!this.expertMode) {
      return this.benchmark.till;
    }

    return [...portfolios, this.benchmark].reduce(
      (acc, p) => (p.till.isBefore(acc) ? p.till : acc),
      moment(),
    );
  }

  @computed get constrainingPortfolios() {
    const { portfolios } = this;
    if (portfolios.length === 0) {
      return [];
    }

    return portfolios.filter((p) => p.constrainingAssets.length > 0);
  }

  @computed get isConstrained() {
    if (!this.expertMode) {
      return false;
    }
    return this.constrainingPortfolios.length > 0;
  }

  @computed get equalize() {
    const { equalize } = this.data;
    return typeof equalize === 'boolean' ? equalize : false;
  }

  @computed get dynamicCash() {
    const { dynamicCash } = this.data;
    return typeof dynamicCash === 'boolean' ? dynamicCash : true;
  }

  @computed get expertMode() {
    const { expertMode } = this.data;
    return typeof expertMode === 'boolean' ? expertMode : false;
  }

  @computed get assetTrend() {
    const { assetTrend } = this.data;
    return typeof assetTrend === 'boolean' ? assetTrend : false;
  }

  @computed get crowdedScope() {
    const { crowdedScope } = this.data;
    return typeof crowdedScope === 'boolean' ? crowdedScope : false;
  }

  @computed get theme() {
    const { theme } = this.data;
    return typeof theme === 'string' ? theme : null;
  }

  @computed get isAutoTheme() {
    const { autoTheme } = this.data;
    return typeof autoTheme === 'boolean' ? autoTheme : false;
  }

  @computed get vacantTag() {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let index = 0;
    for (const p of this.portfolios) {
      const li = alphabet.indexOf(p.tag);
      if (li >= index) {
        index = li + 1;
      }
    }
    if (!alphabet[index]) {
      const len = this.portfolios.length;
      const li = alphabet.indexOf(this.sortedPortfolios[len - 1].tag);
      return alphabet[(li + 1) % 26];
    }
    return alphabet[index];
  }

  @computed get dateRange() {
    const dateFormat = this.expertMode ? 'DD MMM YYYY' : 'MMM YYYY';
    return `${this.since.format(dateFormat)} - ${this.till.format(dateFormat)}`;
  }

  @computed get mergePortfoliosIds() {
    return this.merge.map((x) => x.id);
  }

  @computed get mergeWeights() {
    const out = {};
    for (const x of this.merge) {
      out[x.id] = x.allocation;
    }
    return out;
    // return this.merge.map((x) => ({ [x.id]: x.allocation }));
  }

  @computed get mergeAssets() {
    // return this.merge.reduce(
    //   (acc, x) =>
    //     acc.push(...this.portfolios.find((p) => p.id === x.id).assets) && acc,
    //   [],
    // );
    return this.merge.reduce((acc, x) => {
      const found = this.portfolios.find((p) => p.id === x.id);
      if (found && !acc.find((y) => y.symbol === found.symbol)) {
        for (const a of found.assets) {
          const fa = acc.find((z) => z.symbol === a.symbol);
          if (fa) {
            fa.allocation += a.allocation * (x.allocation / 100);
          } else {
            acc.push({
              id: a.id,
              label: a.label,
              symbol: a.symbol,
              allocation: a.allocation * (x.allocation / 100),
            });
          }
        }
      }
      return acc.sort((a, b) => b.allocation - a.allocation);
    }, []);
  }

  addPortfolio = flow(function* (options = {}) {
    options = {
      ...{
        source: null,
        existing: false,
        tag: null,
        name: null,
        autoselect: true,
        addCache: true,
        imported: false,
      },
      ...options,
    };

    if (!options.tag) {
      options.tag = this.vacantTag;
    }

    if (!options.name) {
      options.name = `PORTFOLIO ${options.tag}`;
    }

    const p = yield this._portfolios.add({
      tag: options.tag,
      name: options.name,
      profile: this.id,
      createdAt: new Date(),
      existing: options.existing,
      imported: options.imported,
    });

    if (options.autoselect) {
      this._selectedPortfolio = p;
      this.set({ selectedPortfolio: p.id }, { merge: true });
    }
    if (options.addCache && !options.source) {
      p.addAsset(CASH_SYM, 100);
    }

    const assetsPromises = [];
    if (options.source instanceof Portfolio) {
      for (const a of options.source.assets) {
        const allocation = typeof a.allocation === 'number' ? a.allocation : 0;
        const amount = typeof a.amount === 'number' ? a.amount : 0;
        // don't yield here to allow addAsset flows run concurrently
        assetsPromises.push(
          p.addAsset(a.symbol, allocation, amount, a.createdAt),
        );
      }
    }

    return Promise.all(assetsPromises);
  });

  importPotfolioFromFund = flow(function* (fund) {
    let fundTopHoldings = [];
    let name = '';
    let cashAllocation = 0;
    if (Array.isArray(fund)) {
      fundTopHoldings = fund.reduce((acc, x) => {
        for (const f of x.security.data.fundTopHoldings) {
          const reducedPercent = f.percent * (1 / fund.length);
          const found = acc.find((y) => y.symbol === f.symbol);
          if (found) {
            found.percent += reducedPercent;
          } else {
            acc.push({ symbol: f.symbol, percent: reducedPercent });
          }
        }
        return acc;
      }, []);
      name = fund.map((f) => f.label).join(' + ');
    } else {
      if (!fund.isEtf) {
        throw new Error(`'fund' must be an ETF`);
      }
      fundTopHoldings = fund.security.data.fundTopHoldings;
      if (!fundTopHoldings || fundTopHoldings.length === 0) {
        throw new Error(`'fund' must contain top holdings`);
      }
      name = fund.symbol;
    }

    const tag = this.vacantTag;
    const p = yield this._portfolios.add({
      tag,
      name,
      profile: this.id,
      createdAt: new Date(),
      existing: false,
      imported: true,
    });

    const totalPercentage = fundTopHoldings.reduce(
      (acc, x) => x.percent + acc,
      0,
    );
    const createdAt = new Date();
    const assetsPromises = [];
    for (const a of fundTopHoldings) {
      const { symbol, percent } = a;
      const allocation = (percent / totalPercentage) * 100;
      createdAt.setSeconds(createdAt.getSeconds() - 1);

      if (!isValidSymbol(symbol)) {
        cashAllocation += allocation;
        continue;
      }

      // don't yield here to allow addAsset flows run concurrently
      assetsPromises.push(p.addAsset(symbol, allocation, 0, createdAt));
    }

    assetsPromises.unshift(p.addAsset(CASH_SYM, cashAllocation));

    this._selectedPortfolio = p;
    this.set({ selectedPortfolio: p.id }, { merge: true });

    return Promise.all(assetsPromises);
  });

  importFromMixer = flow(function* (options = {}) {
    const { mixer } = this;
    const tag = this.vacantTag;
    const p = yield this._portfolios.add({
      tag,
      name: mixer.name,
      profile: this.id,
      createdAt: new Date(),
      existing: false,
    });

    const createdAt = new Date();
    const assetsPromises = [];
    for (const a of mixer.assetsSortedByAllocation) {
      createdAt.setSeconds(createdAt.getSeconds() + 1);

      // don't yield here to allow addAsset flows run concurrently
      assetsPromises.push(p.addAsset(a.symbol, a.allocation, 0, createdAt));
    }

    this._selectedPortfolio = p;
    this.set({ selectedPortfolio: p.id }, { merge: true });

    if (options.removeSource) {
      for (const pid of mixer.portfoliosIds) {
        const found = this.portfolios.find((x) => x.id === pid);
        if (found) {
          found.delete();
        }
      }
    }
    mixer.clear();

    return Promise.all(assetsPromises);
  });

  @action removePortfolio(portfolio) {
    if (!portfolio || !portfolio.id) {
      throw new Error(`'eportfolio.id' is required`);
    }
    if (!(portfolio instanceof Portfolio)) {
      portfolio = this.portfolios.find((p) => p.id === portfolio.id);
    }
    this.update({ selectedPortfolio: null, comparedPortfolio: null });
    this._selectedPortfolio = null;
    this._comparedPortfolio = null;
    portfolio.delete();
    this.mixer.clear();
  }

  @action selectPortfolio(portfolio) {
    if (!portfolio || !portfolio.id) {
      throw new Error(`'portfolio.id' is required`);
    }
    this._selectedPortfolio = portfolio;
    this._comparedPortfolio = null;

    this.update({ selectedPortfolio: portfolio.id, comparedPortfolio: null });
  }

  @action comparePortfolio(portfolio) {
    if (!portfolio || !portfolio.id) {
      this._comparedPortfolio = null;
      this.update({ comparedPortfolio: null });
    } else {
      this._comparedPortfolio = portfolio;
      this.update({ comparedPortfolio: portfolio.id });
    }
  }

  sharePortfolio = flow(function* (source, shareAmount = false) {
    if (!(source instanceof Portfolio)) {
      throw new Error(
        `'source' must be an instance of Portfolio, got ${typeof source}`,
      );
    }
    if (
      source.sharedId &&
      (!source.existing || source.shareAmount === shareAmount)
    ) {
      // eslint-disable-next-line no-console
      console.log(`Already has shared copy with id ${source.sharedId}`);

      return new SharedPortfolio(() => `sharedPortfolios/${source.sharedId}`, {
        cache: this._options.cache,
      });
    }
    const p = yield this._sharedPortfolios.add({
      name: source.name,
      tag: 'A',
      profile: this.id,
      createdAt: new Date(),
      existing: shareAmount && source.existing,
    });

    const assetsPromises = [];
    for (const a of source.assets) {
      const allocation = typeof a.allocation === 'number' ? a.allocation : 0;
      const amount = typeof a.amount === 'number' && shareAmount ? a.amount : 0;
      assetsPromises.push(
        p.addAsset(a.symbol, allocation, amount, a.createdAt),
      );
    }
    yield Promise.all(assetsPromises);
    yield p.update({ previewUrl: 'request' });
    yield p.previewReady();

    const update = { sharedId: p.id };
    if (source.existing) {
      update.shareAmount = shareAmount;
    }

    yield source.update(update);

    return p;
  });

  @action setEqualize(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ equalize: enabled });
  }

  @action setDynamicCash(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ dynamicCash: enabled });
  }

  @action setExpertMode(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ expertMode: enabled });
  }

  @action setAssetTrend(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ assetTrend: enabled });
  }

  @action setCrowdedScope(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ crowdedScope: enabled });
  }

  @action setTheme(type) {
    if (type !== 'dark' && type !== 'light') {
      throw new Error(`'type' should be 'dark' or 'light', got ${type}`);
    }
    this.update({ theme: type });
  }

  @action setAutoTheme(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ autoTheme: enabled });
  }

  @action toggleMergePortfolio(portfolio, allocation = 0) {
    if (!(portfolio instanceof Portfolio)) {
      throw new Error(`'portfolio' must be an instance of Portfolio`);
    }

    const found = this.merge.find((x) => x.id === portfolio.id);

    if (found) {
      this.merge = this.merge.filter((p) => p.id !== portfolio.id);
    } else {
      this.merge.push({
        id: portfolio.id,
        allocation,
      });
    }

    if (allocation === 0) {
      for (const x of this.merge) {
        x.allocation = 100 / this.merge.length;
      }
    }
  }

  @action setMergeAllocation(portfolio, allocation) {
    const found = this.merge.find((x) => x.id === portfolio.id);
    if (!found && allocation > 0) {
      this.toggleMergePortfolio(portfolio, allocation);
    } else if (allocation > 0) {
      found.allocation = allocation;
    } else {
      this.toggleMergePortfolio(portfolio);
    }
  }

  @action createPortfoliosCollection() {
    this._portfoliosCollection = new UniversalCollection(
      `${this.path}/portfolios`,
      {
        ...this._options,
        createDocument: (s, o) =>
          new Portfolio(s, {
            ...this._options,
            ...o,
          }),
      },
    );

    if (!isServer()) {
      const reactionOptions = {
        delay: 500,
        equals: momentComparer,
        fireImmediately: true,
      };
      this._sinceReaction = reaction(
        () => this.since,
        (since) => this.update({ since: since.toDate() }),
        reactionOptions,
      );
      this._tillReaction = reaction(
        () => this.till,
        (till) => this.update({ till: till.toDate() }),
        reactionOptions,
      );
    }
  }

  invalidateCache = flow(function* () {
    this._sinceReaction();
    this._tillReaction();
    this._portfoliosCollection = null;
    this._selectedPortfolio = null;

    this.createPortfoliosCollection();

    this._portfolios.mode = 'off';
    yield this._portfolios.fetch();
    this._portfolios.mode = 'auto';
  });
}

export default Profile;
