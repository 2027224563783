import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';

import AnimatedPie from './AnimatedPie';

const useStyles = makeStyles((theme) => ({}));

function ChartPortfolioComposition({ assets, animate, optimized, color }) {
  const classes = useStyles();
  const theme = useTheme();

  if (!color) {
    color = theme.palette.success.main;
  }

  const width = 180;
  const height = 180;
  const radius = width / 2;
  const centerY = height / 2;
  const centerX = width / 2;

  const allocation = (a) => (optimized ? a.optimizedAllocation : a.allocation);

  const getAssetColor = scaleOrdinal({
    domain: assets.map((a) => a.label),
    range: [
      fade(color, 1),
      fade(color, 0.8),
      fade(color, 0.6),
      fade(color, 0.4),
      fade(color, 0.2),
      fade(color, 0.1),
    ],
  });

  return (
    <svg width={width} height={height}>
      <Group top={centerY} left={centerX}>
        <Pie data={assets} pieValue={allocation} outerRadius={radius}>
          {(pie) => (
            <AnimatedPie
              {...pie}
              animate={animate}
              getKey={({ data: { label } }) => label}
              getColor={({ data: { label } }) => getAssetColor(label)}
            />
          )}
        </Pie>
      </Group>
    </svg>
  );
}

ChartPortfolioComposition.propTypes = {
  animate: PropTypes.bool,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  optimized: PropTypes.bool,
  color: PropTypes.string,
};

ChartPortfolioComposition.defaultProps = {
  animate: true,
  optimized: true,
  color: null,
};

export default React.memo(ChartPortfolioComposition);
