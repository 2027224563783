import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { Bar, Line, LinePath } from '@visx/shape';
import { localPoint } from '@visx/event';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';

import { extent, bisector } from 'd3-array';

import { fade } from '@material-ui/core/styles/colorManipulator';

import ChartXAxis from '../ChartXAxis';
import ChartYAxis from '../ChartYAxis';
import ChartLegend from '../ChartLegend';
import ChartTooltip from '../ChartTooltip';

import {
  getXPoint,
  getYPoint,
  getXMax,
  getYMax,
  formatAmount,
  getKeys,
  getColor,
} from '../../../common/utils/chart';

function ChartPortfolioGrowth({ data, meta, parentWidth, parentHeight }) {
  const theme = useTheme();

  const {
    tooltipData,
    tooltipLeft,
    // tooltipTop,
    showTooltip,
    hideTooltip,
    tooltipOpen,
  } = useTooltip();

  const margin = {
    top: 20,
    bottom: 40,
    left: 80,
    right: 0,
  };
  const gridColor = theme.palette.divider;

  const chartHeight = parentHeight - 60;

  const xMax = getXMax(parentWidth, margin);
  const yMax = getYMax(chartHeight, margin);

  const xAccessor = (d) => d.date;
  const yAccessor = (d, key) => d[key];

  const bisectDate = bisector(xAccessor).left;

  const keys = getKeys(data);

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: extent(data, xAccessor),
      }),
    [data, xMax],
  );

  const keysAmountsArray = (keys, d) =>
    keys.map((key) => {
      return Number(d[key]);
    });

  const valuesArray = (f) => data.map(f);

  const returnsDomainMin = Math.min(
    ...valuesArray((d) => Math.min(...keysAmountsArray(keys, d))),
    0,
  );
  const returnsDomainMax = Math.max(
    ...valuesArray((d) => Math.max(...keysAmountsArray(keys, d))),
    0,
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [returnsDomainMin, returnsDomainMax * 1.2],
        range: [yMax, 0],
        nice: true,
      }),
    [returnsDomainMin, returnsDomainMax, yMax],
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: keys.map((k) => getColor(k, theme)),
      }),
    [keys],
  );

  const xPoint = getXPoint(xScale, xAccessor);
  const yPoint = getYPoint(yScale, yAccessor);

  const handleTooltip = useCallback(
    (event) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const x0 = xScale.invert(x - margin.left);

      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;

      if (d1 && xAccessor(d1)) {
        d =
          x0.valueOf() - xAccessor(d0).valueOf() >
          xAccessor(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }

      showTooltip({
        tooltipData: d,
        tooltipLeft: xPoint(d),
        tooltipTop: y,
      });
    },
    [showTooltip, yScale, xScale],
  );

  return (
    <Box position={'relative'}>
      <svg width={parentWidth} height={chartHeight}>
        <Group left={margin.left} top={margin.top}>
          <Group>
            <ChartXAxis top={yMax} scale={xScale} />
            <Group>
              <ChartYAxis scale={yScale} tickFormat={formatAmount} />
              <GridRows scale={yScale} stroke={gridColor} width={xMax} />
            </Group>
          </Group>
          {tooltipOpen && (
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax }}
              stroke={theme.palette.text.secondary}
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="4,2"
            />
          )}
          <Group>
            {keys.map((key) => (
              <LinePath
                key={key}
                data={data}
                x={(d) => xPoint(d)}
                y={(d) => yPoint(d, key)}
                strokeWidth={2}
                stroke={colorScale(key)}
              />
            ))}
          </Group>
          <Bar
            x={0}
            y={0}
            width={parentWidth}
            height={yMax}
            fill="transparent"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          {tooltipOpen &&
            keys.map((key) => (
              <g key={key}>
                <circle
                  cx={tooltipLeft}
                  cy={yPoint(tooltipData, key)}
                  r={12}
                  fill={fade(colorScale(key), 0.2)}
                  stroke={colorScale(key)}
                  strokeWidth={0}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={yPoint(tooltipData, key)}
                  r={4}
                  fill={theme.palette.background.default}
                  stroke={colorScale(key)}
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            ))}
        </Group>
      </svg>
      <ChartLegend scale={colorScale} meta={meta} />
      {tooltipOpen && (
        <TooltipWithBounds
          top={35}
          left={tooltipLeft - 120}
          key={Math.random()}
          style={{ position: 'absolute' }}
        >
          <ChartTooltip
            color
            title={xAccessor(tooltipData).format('MMM YYYY')}
            data={keys.map((k) => {
              return {
                label: meta[k],
                type: k,
                value: formatAmount(yAccessor(tooltipData, k)),
              };
            })}
          />
        </TooltipWithBounds>
      )}
    </Box>
  );
}

ChartPortfolioGrowth.propTypes = {
  parentWidth: PropTypes.number,
  parentHeight: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.any,
      portfolio: PropTypes.number,
      optimized: PropTypes.number,
      benchmark: PropTypes.number,
    }),
  ),
  meta: PropTypes.object.isRequired,
};

ChartPortfolioGrowth.defaultProps = {};

export default React.memo(withParentSize(ChartPortfolioGrowth));
