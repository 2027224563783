import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useStore } from '../../store';

import PortfolioPreview from '../../components/PortfolioPreview';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.5),
  },
}));

export function PreviewPage({}) {
  const classes = useStyles();
  const store = useStore();
  const portfolio = store.sharedPortfolio;

  return (
    <div className={classes.root}>
      <PortfolioPreview
        portfolio={portfolio}
        useAmount={portfolio.existing}
        scaled={true}
        showLogo={true}
      />
      {portfolio.metricsReady && <div id="mready"></div>}
    </div>
  );
}

PreviewPage.propTypes = {};

export default observer(PreviewPage);
