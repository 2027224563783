import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import ExistingIllustration from './ExistingIllustration';
import NewIllustration from './NewIllustration';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  createLabel: {
    textTransform: 'uppercase',
  },
}));

function CreatePortfolioCard({ existing, onClick }) {
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent className={classes.cardContent}>
          {existing ? <ExistingIllustration /> : <NewIllustration />}
          <Typography variant="h6" className={classes.createLabel}>
            Create Portfolio
          </Typography>
          <Typography color="textSecondary">allocation in</Typography>
          <Typography variant="h6">{existing ? '$' : '%'}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CreatePortfolioCard.propTypes = {
  existing: PropTypes.bool,
  onClick: PropTypes.func,
};

CreatePortfolioCard.defaultProps = {
  existing: false,
};

export default React.memo(CreatePortfolioCard);
