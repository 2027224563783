class Cache {
  _data = {};

  constructor(data = null) {
    if (!data) return;

    if (typeof data === 'string') {
      try {
        this._data = JSON.parse(data);
      } catch (err) {
        // consider as empty
      }
    }

    if (typeof data === 'object') {
      this._data = data;
    }
  }

  get(path) {
    return this._data[path];
  }

  set(path, value) {
    this._data[path] = value;
  }

  has(path) {
    return !!this._data[path] && Object.entries(this._data[path]).length > 0;
  }

  clear() {
    this._data = {};
  }

  toJSON() {
    return JSON.stringify(this._data)
      .replace(/"_seconds":/g, '"seconds":') // Make timestamps uniform across
      .replace(/"_nanoseconds":/g, '"nanoseconds":'); // client and admin SDK
  }
}

export default Cache;
