export const APPROX_BDAYS_PER_MONTH = 21;
export const APPROX_BDAYS_PER_YEAR = 252;

export const MONTHS_PER_YEAR = 12;
export const WEEKS_PER_YEAR = 52;
export const QTRS_PER_YEAR = 4;

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const YEARLY = 'yearly';

export const ANNUALIZATION_FACTORS = {
  [DAILY]: APPROX_BDAYS_PER_YEAR,
  [WEEKLY]: WEEKS_PER_YEAR,
  [MONTHLY]: MONTHS_PER_YEAR,
  [QUARTERLY]: QTRS_PER_YEAR,
  [YEARLY]: 1,
};
