import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  text: {
    textTransform: 'uppercase',
  },
}));

function RiskProfileLabel({ text, score }) {
  const classes = useStyles();

  const getLabelColor = () => {
    if (score < 40) return 'success.main';
    if (score < 80) return 'warning.main';
    return 'error.main';
  };

  return (
    <Typography variant="h6" className={classes.text}>
      {text}
    </Typography>
  );
}

RiskProfileLabel.propTypes = {
  text: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

RiskProfileLabel.defaultProps = {};

export default React.memo(RiskProfileLabel);
