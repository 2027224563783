import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    lineHeight: 1.25,
    color: theme.palette.text.secondary,
  },
  imported: {
    fontStyle: 'italic',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  compared: {
    color: theme.palette.chart.compare,
  },
}));

function PortfolioName({ imported, selected, compared, children }) {
  const classes = useStyles();

  return (
    <Typography
      className={classNames(classes.root, {
        [classes.imported]: imported,
        [classes.selected]: selected,
        [classes.compared]: compared,
      })}
    >
      {children}
    </Typography>
  );
}

PortfolioName.propTypes = {
  imported: PropTypes.bool,
  selected: PropTypes.bool,
  compared: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PortfolioName.defaultProps = {};

export default React.memo(PortfolioName);
