/**
 *
 * AppMenu
 *
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { dark } from '../../../common/theme';
import { useStore } from '../../store';

import Logo from '../Logo';
import {
  PortfolioIcon,
  SettingsIcon,
  UserIcon,
  MoonIcon,
  SunIcon,
} from '../AppIcons';

const useStyles = makeStyles((theme) => ({
  navRail: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: theme.sizes.appMenuWidth,
    },
    background: theme.palette.background.drawer,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow: theme.shadows[6],
    overflowX: 'hidden',
  },
  logoContainer: {
    transform: 'scale(1.25)',
  },
  badge: {
    backgroundColor: fade(theme.palette.text.primary, 0.1),
    transform: 'scale(0.64) translate(-36%, 44%)',
    color: 'white',
    transition: 'none',
    minWidth: 22,
  },
}));

function AppMenu() {
  const classes = useStyles();
  const location = useLocation();
  const store = useStore();

  const currentLocation = location.pathname;

  const isSelected = (url) => {
    return url === '/'
      ? currentLocation === url
      : currentLocation.startsWith(url);
  };

  const handleThemeSelect = (type) => {
    store.setTheme(type);
  };

  const { themeType, isAutoTheme } = store;

  return (
    <Drawer
      variant="permanent"
      elevation={16}
      anchor="left"
      classes={{ paper: classes.navRail }}
    >
      <ThemeProvider theme={createMuiTheme(dark)}>
        <Grid
          container
          direction={'column'}
          justify={'space-between'}
          style={{ height: '100%' }}
        >
          <Grid item>
            <Grid
              container
              direction={'column'}
              justify={'center'}
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Link to="/">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.logoContainer}
                  >
                    <Logo />
                  </Box>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/profile">
                  <Tooltip title={'Investor Profile'} arrow placement="right">
                    <IconButton
                      color={isSelected('/profile') ? 'primary' : 'default'}
                      aria-label="Investor Profile"
                    >
                      <UserIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/">
                  <Tooltip
                    title={'Portfolio Construction'}
                    arrow
                    placement="right"
                  >
                    <IconButton
                      color={isSelected('/') ? 'primary' : 'default'}
                      aria-label="portfolio construction"
                    >
                      <PortfolioIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/account">
                  <Tooltip title={'Settings'} arrow placement="right">
                    <IconButton
                      color={isSelected('/account') ? 'primary' : 'default'}
                      aria-label="settings"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction={'column'}
              justify={'center'}
              alignItems="center"
              spacing={0}
            >
              <Grid item>
                <Tooltip title={'Dark Mode'} arrow placement="right">
                  <IconButton
                    color={themeType === 'dark' ? 'primary' : 'default'}
                    onClick={() => handleThemeSelect('dark')}
                    aria-label="dark mode"
                  >
                    <Badge
                      badgeContent="A"
                      invisible={!isAutoTheme || themeType !== 'dark'}
                      classes={{
                        badge: classes.badge,
                      }}
                    >
                      <MoonIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={'Light Mode'} arrow placement="right">
                  <IconButton
                    color={themeType === 'light' ? 'secondary' : 'default'}
                    onClick={() => handleThemeSelect('light')}
                    aria-label="light mode"
                  >
                    <Badge
                      badgeContent="A"
                      invisible={!isAutoTheme || themeType !== 'light'}
                      classes={{ badge: classes.badge }}
                    >
                      <SunIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Drawer>
  );
}

AppMenu.propTypes = {};

AppMenu.defaultProps = {};

export default observer(AppMenu);
