import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';

import Caption from '../Caption';

const useStyles = makeStyles(() => ({
  item: {
    width: 56,
  },
}));

function PortfolioListCaptions({ allocation }) {
  const classes = useStyles();

  return (
    <ListItem>
      <Grid container direction={'row'} alignItems={'center'} spacing={2}>
        <Grid xs item>
          <Box display={'flex'} justifyContent="center"></Box>
        </Grid>
        <Grid item>
          <Box width={80} display={'flex'}>
            {allocation && <Caption>Allocation</Caption>}
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item className={classes.item}>
              <Caption align="center">U/D</Caption>
            </Grid>
            <Grid item className={classes.item}>
              <Caption align="center">CAGR/SD</Caption>
            </Grid>
            <Grid item className={classes.item}>
              <Caption align="center">Alpha</Caption>
            </Grid>
            <Grid item className={classes.item}>
              <Caption align="center">Sharpe</Caption>
            </Grid>
            <Grid item className={classes.item}>
              <Caption align="center">Sortino</Caption>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box width={40} display={'flex'} justifyContent="center"></Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

PortfolioListCaptions.propTypes = {
  allocation: PropTypes.bool,
};

PortfolioListCaptions.defaultProps = {
  allocation: false,
};

export default React.memo(PortfolioListCaptions);
