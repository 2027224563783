import { toJS } from 'mobx';
import { Collection } from 'firestorter';
import { isServer } from '../utils/env';

class UniversalCollection extends Collection {
  _cache = null;
  _options = null;

  constructor(source, options = {}) {
    if (!isServer()) {
      super(source, options);

      this._options = options;
      if (options.cache) {
        this._cache = options.cache;
      }
      return;
    }

    options.mode = 'off';
    super(source, options);
    this._options = options;
    if (options.cache) {
      this._cache = options.cache;
    }

    this.fetch();
  }

  ready() {
    return new Promise((resolve) => {
      super.ready().then((v) => {
        // console.log('COLLECTION->CACHE:', this.path);
        if (isServer() && this._cache) {
          this._cache.set(
            this.path,
            this.docs.map((d) => d.id),
          );
        }
        Promise.all(this.docs.map((d) => d.ready())).then(() => {
          resolve(v);
        });
      });
    });
  }

  get hasCache() {
    // console.log('CACHE->COLLECTION', this.path);
    return this._cache !== null && this._cache.has(this.path);
  }

  get hasDocs() {
    return (
      super.hasDocs ||
      (!super.isLoaded &&
        this.hasCache &&
        this._cache.get(this.path).length > 0)
    );
  }

  get isLoading() {
    return !this.hasCache && super.isLoading;
  }

  get docs() {
    if ((!super.isLoaded || super.isLoading) && this.hasCache) {
      // console.log('COLLECTION CACHE', this.path, this._cache.get(this.path));
      const path = typeof this.path === 'function' ? this.path() : this.path;
      return this._cache
        .get(this.path)
        .map((d) =>
          this._options.createDocument(`${path}/${d}`, this._options),
        );
    }

    return super.docs;
  }
}

export default UniversalCollection;
