import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { onEnter } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  helperText: {
    minHeight: 19,
  },
}));

function OptimizationForm({
  optimization,
  targetVolatility,
  minVolatility,
  optimizationIsLoading,
  optimizationError,
  valueError,
  onChange,
  onVolChange,
  onOptimize,
}) {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            Optimization Goal
          </InputLabel>
          <Select value={optimization} onChange={onChange}>
            <MenuItem value="efficient_risk">Maximize Return</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item hidden={optimization !== 'efficient_risk'}>
        <FormControl
          className={classes.formControl}
          error={Boolean(optimizationError || valueError)}
        >
          <InputLabel>Target Volatility</InputLabel>
          <Input
            className={classes.input}
            value={targetVolatility}
            margin="dense"
            onChange={onVolChange}
            endAdornment={<InputAdornment position="start">%</InputAdornment>}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: 'number',
              'data-hj-allow': '',
            }}
            onKeyPress={onEnter(onOptimize)}
          />
          <FormHelperText className={classes.helperText}>
            {optimizationError && !valueError
              ? ''
              : valueError
              ? `${minVolatility}% is minimal value`
              : false}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          onClick={onOptimize}
          disabled={optimizationIsLoading || valueError}
          variant="outlined"
          fullWidth
        >
          Optimize
        </Button>
      </Grid>
    </Grid>
  );
}

OptimizationForm.propTypes = {
  optimization: PropTypes.any,
  targetVolatility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minVolatility: PropTypes.number,
  optimizationIsLoading: PropTypes.bool,
  optimizationError: PropTypes.any,
  valueError: PropTypes.any,
  onChange: PropTypes.func,
  onVolChange: PropTypes.func,
  onOptimize: PropTypes.func,
};

OptimizationForm.defaultProps = {};

export default React.memo(OptimizationForm);
