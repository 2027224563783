import React from 'react';
import Typography from '@material-ui/core/Typography';

import { styled } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';

const Caption = styled(withTheme(Typography))((props) => ({
  textTransform: 'uppercase',
  letterSpacing: 1,
  lineHeight: 1.25,
  fontSize: props.fontSize ? props.fontSize : 8,
  color: props.theme.palette.text.secondary,
}));

export default React.memo(Caption);
