/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import IconButton from '@material-ui/core/IconButton';

const SmallIconButton = forwardRef((props, ref) => {
  return (
    <IconButton ref={ref} size="small" {...props} style={{ padding: 9 }}>
      {props.children}
    </IconButton>
  );
});

export default SmallIconButton;
