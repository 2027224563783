import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';

import { useStore } from '../../store';

import PortfolioTableRow from '../../components/PortfolioTableRow';
import GoogleSignInButton from '../../components/GoogleSignInButton';
import PortfolioListCaptions from '../../components/PortfolioListCaptions';

import AssetDetailsDialog from '../../components/AssetDetailsDialog';
import ChartAnnualReturns from '../../components/ChartAnnualReturns';
import ChartPortfolioGrowth from '../../components/ChartPortfolioGrowth';
import ChartScope from '../../components/ChartScope';
import LayoutPortfolioConstruction from '../../components/LayoutPortfolioConstruction';
import Placeholder from '../../components/Placeholder';
import PortfolioDateRange from '../../components/PortfolioDateRange';
import PortfolioMetricsTable from '../../components/PortfolioMetricsTable';
import SigninCopy from '../../components/SigninCopy';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    background: theme.palette.background.default,
    minHeight: '100%',
  },
}));

export function SharedPortfolio() {
  const classes = useStyles();
  const store = useStore();

  const [loginDialog, setLoginDialog] = useState(false);
  const [viewAsset, setViewAsset] = useState(null);
  const [assetDialog, setAssetDialog] = useState(false);

  const portfolio = store.sharedPortfolio;
  if (!portfolio) return false;
  const benchmark = portfolio.benchmark;

  const handleViewAssetDetails = (e, asset) => {
    setViewAsset(asset);
    setAssetDialog(true);
  };
  const handleCloseAssetDialog = () => setAssetDialog(false);

  const handleImport = async () => {
    if (!store.user.signedIn) {
      setLoginDialog(true);
      return;
    }

    store.setBackdrop(true);
    await store.profile.addPortfolio({ source: portfolio, imported: true });
    store.router.history.push('/');
    store.setBackdrop(false);
  };

  const handleSignIn = async () => {
    const { user } = store;
    setLoginDialog(false);
    store.setBackdrop(true);
    await user.login({ redirect: true, anon: false });
    await store.profile.ready();
    await store.profile.addPortfolio({ source: portfolio, imported: true });
    window.location.assign('/');
  };

  if (!portfolio.hasData)
    return (
      <Grid container justify="center" alignItems="center">
        <Placeholder text={'Portfolio Not Found'} />
      </Grid>
    );

  const renderActions = (
    <Box px={2}>
      <Grid
        container
        justify={'flex-start'}
        alignItems="center"
        direction="row"
        spacing={3}
      >
        <Grid item xs>
          <Tooltip
            title="Copy of the portfolio will be imported to your profile."
            placement="top"
            arrow
          >
            <Button
              fullWidth
              startIcon={<EditTwoToneIcon />}
              onClick={handleImport}
            >
              Edit portfolio
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPorttolioList = (
    <List disablePadding component="nav" aria-label="portfolio list">
      <PortfolioListCaptions />
      <PortfolioTableRow
        key="INDEX"
        ticker={'SPY'}
        metrics={benchmark.metrics}
        benchmark
      />
      <PortfolioTableRow
        shared
        key={portfolio.id}
        tag={portfolio.tag}
        selected={true}
        ticker={portfolio.name}
        metrics={portfolio.metrics}
        metricsReady={portfolio.metricsReady}
      />
    </List>
  );

  const {
    dateRange,
    annualReturnsChartData,
    portfolioGrowthChartData,
    chartsMetaData,
  } = portfolio;

  return (
    <Container maxWidth={false} className={classes.root}>
      <LayoutPortfolioConstruction
        portfolioList={renderPorttolioList}
        chartScope={
          <ChartScope
            animate={true}
            benchmark={portfolio.benchmark}
            hasPortfolios={true}
            portfoliosList={[portfolio]}
            selectedPortfolio={portfolio}
            onSelect={() => {}}
            assetTrend={false}
          />
        }
        chartPortfolioGrowth={
          portfolioGrowthChartData && (
            <ChartPortfolioGrowth
              data={portfolioGrowthChartData}
              meta={chartsMetaData}
            />
          )
        }
        chartPortfolioReturns={
          annualReturnsChartData && (
            <ChartAnnualReturns
              data={annualReturnsChartData}
              meta={chartsMetaData}
            />
          )
        }
        portfolioAssets={
          <PortfolioMetricsTable
            onAssetDetailOpen={handleViewAssetDetails}
            portfolio={portfolio}
          />
        }
        dateRange={
          <PortfolioDateRange dateRange={dateRange} constrained={false} />
        }
        portfolioActions={renderActions}
      >
        <Dialog open={loginDialog} onClose={() => setLoginDialog(false)}>
          <DialogTitle>Sign in required to edit portfolio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <SigninCopy />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLoginDialog(false)} color="primary">
              Cancel
            </Button>
            <GoogleSignInButton
              onClick={handleSignIn}
              variant="text"
              color="default"
              autoFocus
            />
          </DialogActions>
        </Dialog>
        <AssetDetailsDialog
          open={assetDialog}
          asset={viewAsset}
          onClose={handleCloseAssetDialog}
        />
      </LayoutPortfolioConstruction>
    </Container>
  );
}

export default observer(SharedPortfolio);
