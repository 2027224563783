import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Box from '@material-ui/core/Box';

import List from '@material-ui/core/List';

import DrawerPortfolioItem from './DrawerPortfolioItem';
import DrawerMenuItem from './DrawerMenuItem';
import { PlusIcon } from '../AppIcons';

function DrawerPortfolioList({
  benchmark,
  portfolios,
  selected,
  onPortfolioSelect,
  onPortfolioCreate,
  onPortfolioRemove,
}) {
  const handleExistingPortfolioCreate = () => {
    onPortfolioCreate(true);
  };
  const handlePortfolioCreate = () => {
    onPortfolioCreate();
  };

  return (
    <List disablePadding component="nav" aria-label="portfolio list">
      <DrawerPortfolioItem
        key={'INDEX'}
        ticker={'SPY'}
        metrics={benchmark.metrics}
        benchmark
      />
      {portfolios.map((p) => (
        <DrawerPortfolioItem
          key={p.id}
          selected={selected.id === p.id}
          tag={p.tag}
          ticker={p.name}
          metrics={p.metrics}
          metricsReady={p.metricsReady}
          imported={p.imported}
          onSelect={() => onPortfolioSelect(p)}
          onRemove={() => onPortfolioRemove(p)}
        />
      ))}
      <Box display="flex" flexDirection="row">
        <DrawerMenuItem
          icon={<PlusIcon />}
          text="New % Portfolio"
          onClick={handlePortfolioCreate}
        />
        <DrawerMenuItem
          icon={<PlusIcon />}
          text="New $ Portfolio"
          onClick={handleExistingPortfolioCreate}
        />
      </Box>
    </List>
  );
}

DrawerPortfolioList.propTypes = {
  benchmark: PropTypes.object,
  portfolios: PropTypes.array,
  selected: PropTypes.object,
  onPortfolioSelect: PropTypes.func,
  onPortfolioCreate: PropTypes.func,
  onPortfolioRemove: PropTypes.func,
};

DrawerPortfolioList.defaultProps = {};

export default observer(DrawerPortfolioList);
